import { Component, OnInit, Input, OnChanges, ViewEncapsulation, HostListener } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from '../../../ft-constants/constant';
import { LeavingSiteModalComponent } from '../leaving-site-modal/leaving-site-modal.component';
@Component({
  selector: 'ft-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RichTextComponent implements OnInit, OnChanges {
  @Input() component!: BrComponent;
  @Input() page: Page;
  isCanadaSite: any;
  constructor(public modalService: NgbModal) {}

  ngOnInit(): void {
    this.isCanadaSite = Constant.isCanadaSite;
  }
  ngOnChanges(): void {}
  get document() {
    const { document } = this.component?.getModels() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get item() {
    return this.document?.getData();
  }
  get title() {
    return this.page?.getTitle();
  }

  /**
   * Rich text links need handled globally to change them
   * to route correctly.
   *
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const target: EventTarget = this.checkLink(event);
    const linkUrl: string = target instanceof HTMLAnchorElement ? target.href : '';

    if (target instanceof HTMLAnchorElement || target instanceof HTMLButtonElement) {
      if (linkUrl.startsWith('https://www.linked') || linkUrl.startsWith('https://twitter')) {
        this.openPopup(linkUrl, event);
      }
    }
  }

  /**
   * Returns clicked Anchor element
   * @param event - click event
   */
  checkLink(event: MouseEvent): EventTarget {
    if (event.target instanceof HTMLAnchorElement || event.target instanceof HTMLButtonElement) {
      return event.target;
    }
    // Try to find anchor element in event patch
    const anchorEl = event.composedPath().find((el) => el instanceof HTMLAnchorElement);
    if (anchorEl) {
      return anchorEl;
    }
    // Try to find button element in event patch
    const buttonEl = event.composedPath().find((el) => el instanceof HTMLButtonElement);
    if (buttonEl) {
      return buttonEl;
    }
  }

  openPopup(link: any, ev: any) {
    ev.preventDefault();
    const modalRef = this.modalService.open(LeavingSiteModalComponent);
    modalRef.componentInstance.url = link;
  }
}
