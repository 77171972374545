<div class="container position-relative bg-lightgray topBanner">
  <div class="section-inner-txt bg-lightgray">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>

      <div class="container bg-lightgray">
        <div class="billboard row">
          <div class="col-sm-10 col-sm-offset-2" style="padding-right: 0; padding-left: 0">
            <div class="jumbotron billboard-large" [style.background-image]="'url(' + bannerimage + ')'">
              <div class="row">
                <div class="col-xs-12 col-sm-pull-2 col-sm-4 col-md-4 billboard-overlay color-black" [ngClass]="{ 'en-ca': isCanadaSite }">
                  <ng-container [ngTemplateOutlet]="firstArticleInList ? dynamicBanner : staticBanner"></ng-container>
                </div>
              </div>

              <div class="hidden-xs banner-list" *ngIf="insideBlockBoolean">
                <div class="cta-header-link-panel" role="navigation">
                  <div class="fluid-container">
                    <div class="inner">
                      <h2 class="short">{{ insideBlockTitle }}</h2>
                      <ul>
                        <span *ngFor="let content of insideBlockMenuContent">
                          <li *ngIf="content?.linkCollection && content?.linkCollection[0]?.title" [ngClass]="[content?.linkCollection[0]?.title, 'true']">
                            <a [routerLink]="getPageUrl(content?.linkCollection[0]?.document)"
                              ><span>{{ content?.linkCollection[0]?.displayText }}</span> <span class="link-arrow"></span
                            ></a>
                          </li>
                        </span>
                      </ul>
                      <a *ngIf="buttonName" title="" class="button" [routerLink]="getPageUrl(insideCardBlock?.buttonBlock?.linkCompound?.linkCollection[0]?.document)" target="">{{ buttonName }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Banner loaded from article listing --> 
<ng-template #dynamicBanner>
  <ng-container *ngIf="bannerContent?.title; else bannerTextBlock">
    <h4 *ngIf="bannerContent?.preTitle">{{ bannerContent?.preTitle }}</h4>
    <h1 *ngIf="bannerContent?.title">{{ bannerContent?.title }}</h1>
    <div class="banner-text" *ngIf="bannerText?.html?.value !== ''" [innerHTML]="bannerText?.html?.value"></div>
    <p class="authorDate">
      <span
        >{{ bannerContent?.date | date: "MM.dd.yyyy":"UTC +5:30" }}<span *ngIf="bannerContent?.authors">&nbsp;-&nbsp;{{ bannerContent?.authors }}</span></span
      >
    </p>
  </ng-container>

  <a [routerLink]="dynamicLink"
    ><strong>{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</strong></a
  >
</ng-template>

<ng-template #bannerTextBlock> <div class="banner-text" *ngIf="bannerText?.html?.value !== ''" [innerHtml]="bannerText?.html?.value"></div></ng-template>

<!-- Static Banner entered via CMS form -->
<ng-template #staticBanner>
  <h4 *ngIf="preTitle">{{ preTitle }}</h4>
  <h1 *ngIf="bannerTitle">{{ bannerTitle }}</h1>
  <div *ngIf="bannerText?.html?.value !== ''" [innerHtml]="bannerText?.html?.value"></div>
  <p *ngIf="bannerLinks && bannerLinks.length != 0" class="bannerLinks">
    <ng-container *ngFor="let link of bannerLinks">
      <a [routerLink]="getPageUrl(link.linkCollection[0].document)" *ngIf="link.linkCollection && link.linkCollection[0].document"
        ><strong>{{ link.linkCollection[0].displayText }}</strong></a
      >
    </ng-container>
  </p>
  <a *ngIf="readMoreLink" [routerLink]="getPageUrl(readMoreLink)"
    ><strong>{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</strong></a
  >
  <p *ngIf="bannerText?.telephone" class="telephone">
    <a href="tel:{{ bannerText?.telephone }}">{{ bannerText?.telephone }}</a>
  </p>
  <p *ngIf="bannerText?.email" class="email">
    <a href="mailto:{{ bannerText?.email }}">{{ bannerText?.email }}</a>
  </p>
  <div *ngIf="bannerText?.additionalContent" [innerHtml]="bannerText?.additionalContent"></div>
  <p *ngIf="bannerButtons && bannerButtons.length != 0" class="bannerButtons">
    <ng-container *ngFor="let link of bannerButtons">
      <a class="btn-red" [routerLink]="getPageUrl(link.linkCollection[0].document)" *ngIf="link.linkCollection && link.linkCollection[0].document"
        ><strong>{{ link.linkCollection[0].displayText }}</strong></a
      >
    </ng-container>
  </p>
</ng-template>
