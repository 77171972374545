import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constant } from 'src/app/ft-constants/constant';
import { AppStateService } from "@services/app-state.service";

export interface FtSearchResults {
  originalQueryText: string;
  updatedQueryText: string;
  parametrics: {
    results: {
      aggregations: [];
    };
  };
  results: FtSearchResponse[];
  fundInstant: [{}];
  instantDisplays: {
    total: number;
    hits: [
      {
        entityType: string;
      }
    ];
  };
}
export interface FtSearchResponse {
  name: string;
  response: {
    hits: {
      hits: {}[];
    };
  };
}
@Injectable({
  providedIn: 'root',
})
export class DataSearchService {
  channel: string;
  ftSearchUrl: string;
  dataSource: string;
  constructor(private http: HttpClient,
    private appStateService: AppStateService) {}
  performSearch(query, dataSource): Observable<FtSearchResults> {
    this.channel = this.appStateService.getChannel();
    this.ftSearchUrl = this.appStateService?.getFTElasticSearchApiUrl(dataSource)
    let ftSearchStatusUrl = dataSource === 'prod' ? Constant.ftSearchUrlStatusProd : Constant.ftSearchUrlStatusDev;
    this.dataSource = (dataSource === Constant.betaHost) ? Constant.prodEnv : dataSource;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    });

    const body = new HttpParams()
      .set('audience', 'investor')
      .set('collection', 'pages')
      .set('sort', 'relevance')
      .set('doNotSpellCheck', 'false')
      .set('hideTabs', 'true')
      .set('locale', this.channel) /*en-usa to be replaced with Constant.channelName once fiduciary search starts returning response*/
      .set('loggedIn', 'n')
      .set('query', query)
      .set('start', '0')
      .set('number', '100')
      .set('env', this.dataSource);

    //URL preceded by https
    return this.http.post<FtSearchResults>(this.ftSearchUrl, body.toString(), { headers: headers });
  }
}
