<div class="container position-relative cardGrid bg-lightgray">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div *ngIf="content">
        <div class="grid section-wrp">
          <div class="map-contact-card">
            <div class="row">
              <div class="single-column col-md-6 col-lg-4">
                <div class="grid-item single col-xs-12">
                  <p class="subtitle">{{content.preTitle}}</p>
                  <h3>{{content.title}}</h3>
                  <p>{{content.description}}</p>
                  <p class="phone"><a href="tel:1-{{content.phone}}">{{content.phone}}</a></p>
                  <p class="email"><a href="mailto:{{content.email}}">{{content.email}}</a></p>
                </div>
              </div>
              <div class="double-column col-md-12 col-lg-8">
                <div class="map-container">
                  <agm-map
                    [mapTypeId]="map.mapType"
                    [latitude]="toNumber(map.lat)"
                    [longitude]="toNumber(map.lng)"
                    [zoom]="toNumber(map.zoom)"
                    [styles]="Styles"
                    [disableDefaultUI]="true"
                    [scrollwheel]="null"
                    [gestureHandling]="'coopeative'"
                  >
                    <agm-zoom-control></agm-zoom-control>
                    <agm-marker
                      *ngFor="let m of map.markers; let i = index"
                      [latitude]="toNumber(m.position.lat)"
                      [longitude]="toNumber(m.position.lng)"
                      [iconUrl]="iconUrl"
                      [markerDraggable]="false"
                    >
                      <agm-info-window>
                        <div class="contents">
                          <p><strong>{{m.title}}</strong></p>
                          <p>{{m.contactDetails.addressLine1}}</p>
                          <p>{{m.contactDetails.addressLine2}}</p>
                          <p *ngIf="m.contactDetails.telephone"><a href="tel:{{m.contactDetails.telephone}}" tabindex="0">{{m.contactDetails.telephone}}</a></p>
                          <p><span *ngIf="m.contactDetails.emailName">{{m.contactDetails.emailName}},&nbsp;</span><span class="bold" *ngIf="m.contactDetails.email"><strong><a href="mailto:">{{m.contactDetails.email}}</a></strong></span></p>
                        </div>
                      </agm-info-window>
                    </agm-marker>
                  </agm-map>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>