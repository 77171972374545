import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Renderer2,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ViewEncapsulation,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { FtiProfilesComponent } from '@components/fti-profiles/fti-profiles.component';
import { Constant } from 'src/app/ft-constants/constant';
@Component({
  selector: 'ft-image-grid-overlay',
  templateUrl: './image-grid-overlay.component.html',
  styleUrls: ['./image-grid-overlay.component.scss'],
  animations: [
    trigger('animate', [
      state('closeAll', style({ height: 0, opacity: 0 })),
      state('open', style({ height: '*', opacity: 1 })),
      transition('close=>open', [style({ height: 0, opacity: 0 }), animate('400ms', style({ height: '*', opacity: 1 }))]),
      transition('open=>close', [animate('400ms', keyframes([style({ height: '*' }), style({ height: 0 }), style({ height: '*' })]))]),
      transition('open=>closeAll', [style({ height: '*', opacity: 1 }), animate('400ms', style({ height: 0, opacity: 0 }))]),
      transition('closeAll=>open', [style({ height: 0, opacity: 0 }), animate('400ms', style({ height: '*', opacity: 1 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ImageGridOverlayComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  @Input() numberOfProfiles;
  @Input() isGridOverlay;
  @ViewChild('imageGridWrap', { static: false }) imageGridWrap: ElementRef;
  @ViewChild('gridOverlay', { static: false }) gridOverlay: ElementRef;
  @ViewChild('gridContent', { static: false }) gridContent: ElementRef;
  @ViewChild('gridContentArrow', { static: false }) gridContentArrow: ElementRef;
  @ViewChildren('imageWrapRef') imageWrapRef: QueryList<any>;
  data: any;
  profileArray = [];
  imageWithContentList: any = [];
  selected = false;
  show = 'close';
  arrowPosition;
  componentData: any;
  constructor(private renderer: Renderer2, private el: ElementRef, private componentFactoryResolver: ComponentFactoryResolver) {}

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    return this.document?.getData();
  }
  getItemData(ind: any) {
    this.data = this.page
      ?.getComponent('profile-area-' + ind)
      ?.getChildren()[0]
      ?.getModels()?.document;
    if (this.data) {
      this.componentData = this.page?.getContent(this.data)?.getData() || {};
      let imageUrl;
      if (this.componentData.image) {
        imageUrl = this.page?.getContent(this.componentData.image)?.getData()?.original?.links?.site?.href;
      } else if (this.componentData.imageCompound.contentType === 'ftcore:ImageCompound' && this.componentData.imageCompound.widenAsset) {
        imageUrl = JSON.parse(this.componentData.imageCompound.widenAsset).url ? JSON.parse(this.componentData.imageCompound.widenAsset).url : '';
      }
      this.componentData.imgUrl = imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') + Constant.optimisedProfileImg : '';
      return this.componentData;
    }
    return;
  }
  get title() {
    return this.page?.getTitle();
  }
  getArrowPosition(id: any) {
    let pos = 0;
    let lastColumnOfGrid;
    let rowCount;
    if (window.innerWidth > 990) {
      lastColumnOfGrid = 3;
      rowCount = 3;
    } else if (window.innerWidth > 767 && window.innerWidth <= 990) {
      lastColumnOfGrid = 2;
      rowCount = 4;
    } else {
      lastColumnOfGrid = 1;
      rowCount = 9;
    }
    topLoop: for (let j = 1; j <= lastColumnOfGrid; j++) {
      for (let i = 0; i < rowCount; i++) {
        if (id === lastColumnOfGrid * i + j) {
          pos = j;
          break topLoop;
        }
      }
    }
    return pos;
  }
  addEmailOrTelephone(data: any, classname: any) {
    if (data) {
      const para = this?.renderer?.createElement('p');
      const anchor = this?.renderer?.createElement('a');
      const hrefVal = classname === 'email' ? 'mailto:' : 'tel:';
      this?.renderer?.setAttribute(anchor, 'href', hrefVal + data);
      this?.renderer?.addClass(para, classname);
      anchor.innerText = data;
      this?.renderer?.appendChild(para, anchor);
      this?.renderer?.appendChild(this?.gridContent?.nativeElement, para);
    }
  }
  repositionImageDescriptionBlock(id: any) {
    const elem = this.gridOverlay?.nativeElement;
    const data = this.getItemData(id);
    if(data?.content?.value === undefined) {
      this?.renderer?.addClass(this?.gridOverlay?.nativeElement, 'hide');
    }
    this.gridContent.nativeElement.innerHTML = data?.content?.value || '';
    this.addEmailOrTelephone(data?.telephone, 'telephone');
    this.addEmailOrTelephone(data?.email, 'email');
    let refChild;
    const profileImagesArray = this.imageWrapRef.toArray();
    if (window.innerWidth > 990) {
      if (id <= 3) {
        refChild = profileImagesArray[3]?.nativeElement;
      } else if (id <= 6) {
        if(profileImagesArray.length == 9 || profileImagesArray.length == 7) {
          refChild = profileImagesArray[6]?.nativeElement;
        } else {
          refChild = profileImagesArray[id-1]?.nativeElement;
        }
      } else {
        refChild = profileImagesArray[id-1]?.nativeElement;
      }
    } else if (window.innerWidth > 767 && window.innerWidth <= 990) {
      if (id <= 2) {
        refChild = profileImagesArray[2]?.nativeElement;
      } else if (id <= 4) {
        if(profileImagesArray.length == 4) {
          refChild = profileImagesArray[id-1]?.nativeElement;
        } else {
          refChild = profileImagesArray[4]?.nativeElement;
        }

      } else if (id <= 6) {
        if(profileImagesArray.length == 9 || profileImagesArray.length == 7) {
          refChild = profileImagesArray[6]?.nativeElement;
        } else {
          refChild = profileImagesArray[id-1]?.nativeElement;
        }
      } else if (id <= 8) {
        if(profileImagesArray.length == 9) {
          refChild = profileImagesArray[8]?.nativeElement;
        } else {
          refChild = profileImagesArray[id-1]?.nativeElement;
        }
      } else {
        refChild = profileImagesArray[id-1]?.nativeElement;
      }
    } else {
      if (id === profileImagesArray.length) {
        refChild = profileImagesArray[id-1]?.nativeElement;
      } else {
        refChild = profileImagesArray[id]?.nativeElement;
      }
    }
    if (window.innerWidth > 990) {
      if (id <= 3) {
        this?.renderer?.insertBefore(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement, refChild);
      } else if (id <= 6) {
        if(profileImagesArray.length == 9 || profileImagesArray.length == 7) {
          this?.renderer?.insertBefore(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement, refChild);
        } else {
          this?.renderer?.appendChild(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement);
        }
      } else {
        this?.renderer?.appendChild(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement);
      }
    } else if (window.innerWidth > 767 && window.innerWidth <= 990) {
      if (id <= 2) {
        this?.renderer?.insertBefore(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement, refChild);
      } else if (id <= 4) {
        if(profileImagesArray.length == 5 || profileImagesArray.length == 9 || profileImagesArray.length == 7) {
          this?.renderer?.insertBefore(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement, refChild);
        } else {
          this?.renderer?.appendChild(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement);
        }
      } else if (id <= 6) {
        if(profileImagesArray.length == 9 || profileImagesArray.length == 7) {
          this?.renderer?.insertBefore(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement, refChild);
        } else {
          this?.renderer?.appendChild(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement);
        }
      } else if (id <= 8) {
        if(profileImagesArray.length == 9) {
          this?.renderer?.insertBefore(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement, refChild);
        } else {
          this?.renderer?.appendChild(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement);
        }
      } else {
        this?.renderer?.appendChild(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement);
      }
    } else {
      if (id === profileImagesArray.length) {
        this?.renderer?.appendChild(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement);
      } else {
        this?.renderer?.insertBefore(this?.renderer?.parentNode(refChild), this.gridOverlay?.nativeElement, refChild);
      }
    }

    this.arrowPosition = this.getArrowPosition(id);
  }
  toggle(e: any) {
    if (this.gridOverlay?.nativeElement?.classList.contains('hide')) {
      this.renderer?.removeClass(this.gridOverlay?.nativeElement, 'hide');
      this.show = 'open';
    } else {
      this.show = this.show === 'open' ? 'close' : 'open';
    }
    this.selected = !this.selected;
    const id = parseInt(e.currentTarget.id.split('-')[1]) || undefined;
    if (id) {
      this.repositionImageDescriptionBlock(id);
    }
  }
  hideOverlay() {
    this.show = 'closeAll';
    setTimeout(() => {
      this?.renderer?.addClass(this?.gridOverlay?.nativeElement, 'hide');
    }, 350);
  }

  ngOnInit(): void {
    /* const comps = this.page?.getComponent()?.["model"]?.components;
    let ctr=0;
    if(comps){
      for(let i=0;i<comps.length;i++){
        if(comps[i].name.indexOf("profile-area")!=-1){
          ctr++;
        }
      }
    }else{
      ctr = this.numberOfProfiles;
    } */
    this.profileArray = Array(this.numberOfProfiles).fill(this.numberOfProfiles);
  }
}
