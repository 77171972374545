import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';

@Component({
  selector: 'ft-display-grid',
  templateUrl: './display-grid.component.html',
  styleUrls: ['./display-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DisplayGridComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  constructor() {}

  ngOnInit(): void {}
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    return this.document?.getData()?.displayGrid;
  }
}
