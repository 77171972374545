import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppStateService } from "@services/app-state.service";
import { Logger } from "@utils/logger";
import { Observable } from "rxjs";

const logger = Logger.getLogger("resource-api-interceptor");

@Injectable({
  providedIn: "root",
})
export class ResourceApiHeadersInterceptor implements HttpInterceptor {
  firstLoad = true;
  baseUrlCheck: string;

  constructor(private appStateService: AppStateService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.indexOf("resourceapi") !== -1) {
      let newUrl = this.checkChannelInUrl(req.url);

      let params = req.params;
      let headers = req.headers;

      // check if first time loading and add url param
      if (this.firstLoad) {
        this.firstLoad = false; // only want to load once
        params = params.append("first-load", "true");
      }

      req = req.clone({
        withCredentials: true,
        params,
        headers,
        url: newUrl,
      });
    }
    return next.handle(req);
  }

  // pagemodel 1.0 is inconsistent in adding channel to urls so need to remove
  private checkChannelInUrl(url: string): string {
    if (this.firstLoad) {
      const baseUrl = this.appStateService.getSpaBaseUrl();
      if (baseUrl) {
        this.baseUrlCheck = `resourceapi${baseUrl}`;
      }
    }

    return this.baseUrlCheck
      ? url.replace(this.baseUrlCheck, "resourceapi")
      : url;
  }
}
