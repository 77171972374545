<div class="container position-relative two-row-image-content bg-lightgray">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="section-wrp">
        <div class="meet-people-section" #meetPeople>
          <div class="row" #imageGridWrap>
            <div *ngFor="let item of imageWithContent; index as i" class="single-column col-sm-6 col-md-3" id="{{ 'imgBlock-' + i }}">
              <div *ngIf="item.imgUrl && item.imgUrl !==''" class="single grid-item white col-xs-12 col-sm-12 col-md-12" style="background: url('{{ item.imgUrl }}');background-size: cover;">
                <a aria-label="image" *ngIf="item.linkCompound?.linkCollection[0].contentType === 'ftcore:LinkURL'" class="grid-item-link" href="{{ item.linkCompound?.linkCollection[0].url }}"></a>
                <a
                  *ngIf="item.linkCompound?.linkCollection[0].contentType === 'ftcore:LinkInternalPage'"
                  class="grid-item-link"
                  aria-label="image"
                  [routerLink]="getInternalLink(item.linkCompound?.linkCollection[0].document)"
                ></a>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 content">
                <h3>{{ item.titleBlock?.title }}</h3>
                <div [innerHTML]="item.html?.value"></div>
                <a *ngIf="item.linkCompound?.linkCollection[0].contentType === 'ftcore:LinkURL'" class="cta" href="{{ item.linkCompound?.linkCollection[0].url }}">{{
                  item.linkCompound?.linkCollection[0]?.displayText
                }}</a>
                <a class="cta" *ngIf="item.linkCompound?.linkCollection[0].contentType === 'ftcore:LinkInternalPage'" [routerLink]="getInternalLink(item.linkCompound?.linkCollection[0].document)">
                  {{ item.linkCompound?.linkCollection[0]?.displayText }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
