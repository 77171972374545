<div class="container position-relative">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div *ngIf="document" class="secton-wrp">
        <div class="growth-content col-md-8" *ngFor="let content of contentBlock">
          <div *ngFor="let item of content?.items">
            <div [innerHTML]="item.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
