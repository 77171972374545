<div class="container position-relative bg-lightgray marketoFormWrapper">
  <div class="section-inner-txt bg-lightgray">
    <div class="section-wrp bg-lightgray">
      <div class="row">
        <div class="mb-5" role="title" data-fti-component="ftci-grid" data-fti-module="ftci-contact-grid-overlay">
          <div class="grid">
            <div class="grid-locked">
              <h2 id="frmTitle" *ngIf="formTitle">{{ formTitle }}</h2>
              <div id="frmContent" *ngIf="formText" class="frmText">
                <span>
                  {{ formText }}
                </span>
              </div>
              <form id="mktoForm_{{ id }}"></form>
              <div id="confirmform" style="display: none">
                <p></p>
                <br />
                <div class="prominent">
                  <h5>{{ successTitle }}</h5>
                  <p [innerHTML]="successMsg"></p>
                  <b>
                    <ng-container *ngIf="telephone">
                      <span class="glyphicon glyphicon-earphone"></span>&nbsp; <a href="tel:{{ telephone }}">{{ telephone }}</a
                      >&nbsp;
                    </ng-container>
                    <ng-container *ngIf="email">
                      <span class="glyphicon glyphicon-envelope"></span>&nbsp;
                      <a href="mailto:{{ email }}">{{ email }}</a>
                    </ng-container>
                  </b>
                </div>
              </div>
              <div id="confirmform1" style="display: none">
                <p></p>
                <br />
                <!-- <h5>2021 Guide to Potential Tax Law Changes</h5> -->
                <h5>{{ successTitle }}</h5>
                <iframe
                  width="300"
                  height="150"
                  style="width: 700px; height: 400px"
                  src="https://online.fliphtml5.com/cnumm/epqt/"
                  seamless="seamless"
                  scrolling="no"
                  frameborder="0"
                  allowtransparency="true"
                  allowfullscreen="allowfullscreen"
                ></iframe>
                <br />
                <div class="prominent">
                  <p [innerHTML]="successMsg"></p>
                  <b>
                    <ng-container *ngIf="telephone">
                      <span class="glyphicon glyphicon-earphone"></span>&nbsp; <a href="tel:{{ telephone }}">{{ telephone }}</a
                      >&nbsp;
                    </ng-container>
                    <ng-container *ngIf="email">
                      <span class="glyphicon glyphicon-envelope"></span>&nbsp;
                      <a href="mailto:{{ email }}">{{ email }}</a>
                    </ng-container>
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
