import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnvConfigService } from '@services/env-config.service';
import { APP_BASE_HREF } from '@angular/common';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { TopMenuComponent } from '@shared/top-menu/top-menu.component';
import { ContentComponent } from '@components/content/content.component';
import { PageContainerComponent } from '@pages/page-container/page-container.component';
import { MainMenuComponent } from '@shared/main-menu/main-menu.component';
import { FooterComponent } from './ft-components/shared/footer/footer.component';
import { RichTextComponent } from '@shared/rich-text/rich-text.component';
import { PageTitleComponent } from '@shared/page-title/page-title.component';
import { TwoColumnContentTabComponent } from './ft-components/two-column-content-tab/two-column-content-tab.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ImageGridOverlayComponent } from './ft-components/image-grid-overlay/image-grid-overlay.component';

import { BillboardComponent } from './ft-components/shared/billboard/billboard.component';

import { TwoColumnContentComponent } from './ft-components/two-column-content/two-column-content.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { InsightsDisclaimerComponent } from './ft-components/insights-disclaimer/insights-disclaimer.component';
import { TwoRowImageContentComponent } from './ft-components/two-row-image-content/two-row-image-content.component';
import { CardGridComponent } from './ft-components/card-grid/card-grid.component';
import { SocialMediaIconsComponent } from './ft-components/social-media-icons/social-media-icons.component';
import { ContactUsComponent } from './ft-components/contact-us/contact-us.component';

import { MarketoFormComponent } from './ft-components/marketo-form/marketo-form.component';
import { FtiProfilesComponent } from './ft-components/fti-profiles/fti-profiles.component';
import { DynamicCardComponent } from './ft-components/shared/dynamic-card/dynamic-card.component';
import { DisplayGridComponent } from './ft-components/display-grid/display-grid.component';
import { ArticleListComponent } from '@shared/article-list/article-list.component';
import { ArticleDetailComponent } from './ft-components/article-detail/article-detail.component';
import { ListMenuComponent } from '@shared/list-menu/list-menu.component';
import { CareerbottombillboardComponent } from './ft-components/careerbottombillboard/careerbottombillboard.component';
import { TabContentComponent } from './ft-components/tab-content/tab-content.component';
import { ArticleComponent } from './ft-components/shared/article/article.component';
import { LeavingSiteModalComponent } from './ft-components/shared/leaving-site-modal/leaving-site-modal.component';
import { AwardsListComponent } from './ft-components/shared/awards-list/awards-list.component';
import { SearchComponent } from './ft-components/shared/search/search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RelatedPostsComponent } from './ft-components/related-posts/related-posts.component';
import { SimpleGridComponent } from '@components/simple-grid/simple-grid.component';
import { ActiveViewComponent } from './ft-components/active-view/active-view.component';
import { ArticleGridViewComponent } from './ft-components/article-grid-view/article-grid-view.component';
import { InlineImagesComponent } from './ft-components/inline-images/inline-images.component';
import { JumpLinksComponent } from './ft-components/jump-links/jump-links.component';
import { JumpLinksContentComponent } from './ft-components/jump-links-content/jump-links-content.component';
import { VideoCardsComponent } from './ft-components/video-cards/video-cards.component';
import { SafeContentPipe } from './safe-content.pipe';
import { AgmCoreModule } from '@agm/core';
import { Constant } from './ft-constants/constant';
import { GoogleMapComponent } from './ft-components/shared/google-map/google-map.component';
import { ProfileCountComponent } from './ft-components/shared/profile-count/profile-count.component';
import { NgxSocialShareModule } from 'ngx-social-share';
import { DebugModule } from '@components/shared/debug-page-model/debug-page-model.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResourceApiHeadersInterceptor } from '@utils/resource-api-headers-interceptor';
//import { GoogleTagManagerModule } from 'angular-google-tag-manager';

const initConfig = (envConfig: EnvConfigService) => {
  return async () => {
    return await envConfig.loadEnvConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    PageContainerComponent,
    TopMenuComponent,
    ContentComponent,
    MainMenuComponent,
    FooterComponent,
    RichTextComponent,
    PageTitleComponent,
    TwoColumnContentTabComponent,
    ImageGridOverlayComponent,
    BillboardComponent,
    TwoColumnContentComponent,
    InsightsDisclaimerComponent,
    TwoRowImageContentComponent,
    MarketoFormComponent,
    CardGridComponent,
    SocialMediaIconsComponent,
    ContactUsComponent,
    FtiProfilesComponent,
    DynamicCardComponent,
    DisplayGridComponent,
    ArticleListComponent,
    ArticleDetailComponent,
    ListMenuComponent,
    CareerbottombillboardComponent,
    TabContentComponent,
    ArticleComponent,
    LeavingSiteModalComponent,
    AwardsListComponent,
    SearchComponent,
    RelatedPostsComponent,
    SimpleGridComponent,
    ActiveViewComponent,
    ArticleGridViewComponent,
    InlineImagesComponent,
    JumpLinksComponent,
    JumpLinksContentComponent,
    VideoCardsComponent,
    SafeContentPipe,
    GoogleMapComponent,
    ProfileCountComponent,
  ],
  imports: [
    NgxSocialShareModule,
    BrowserModule,
    AppRoutingModule,
    BrSdkModule,
    FormsModule,
    NgbModule,
    BrowserAnimationsModule,
    PipesModule,
    ReactiveFormsModule,
    DebugModule,
    AgmCoreModule.forRoot({
      apiKey: Constant.googleMapKey,
      apiVersion: '3.32',
    }),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    EnvConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [EnvConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResourceApiHeadersInterceptor,
      multi: true,
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-K6D3NMW' },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AppComponent,
    PageContainerComponent,
    TopMenuComponent,
    ContentComponent,
    MainMenuComponent,
    FooterComponent,
    RichTextComponent,
    PageTitleComponent,
    TwoColumnContentTabComponent,
    ImageGridOverlayComponent,
    BillboardComponent,
    TwoColumnContentComponent,
    InsightsDisclaimerComponent,
    TwoRowImageContentComponent,
    MarketoFormComponent,
    CardGridComponent,
    SocialMediaIconsComponent,
    ContactUsComponent,
    FtiProfilesComponent,
    DynamicCardComponent,
    DisplayGridComponent,
    ArticleListComponent,
    ArticleDetailComponent,
    ListMenuComponent,
    CareerbottombillboardComponent,
    TabContentComponent,
    ArticleComponent,
    LeavingSiteModalComponent,
    AwardsListComponent,
    SearchComponent,
    RelatedPostsComponent,
    SimpleGridComponent,
    ActiveViewComponent,
    ArticleGridViewComponent,
    InlineImagesComponent,
    JumpLinksComponent,
    JumpLinksContentComponent,
    VideoCardsComponent,
  ],
})
export class AppModule {}
