import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Component as BrComponent, initialize, Page } from '@bloomreach/spa-sdk';
import { Constant } from '../../../ft-constants/constant';
import { from, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { PrerenderService } from '@services/prerender.service';
import { WINDOW } from '@ng-web-apis/common';
import { AppStateService } from '@services/app-state.service';
import { PageConfig, PageContainerService } from '@pages/services/page-container.service';
export interface articleContent {
  authorName?: string;
  articleDate?: string;
  preTitle?: string;
  title?: string;
  content?: string;
  imageCompound?: any;
  link?: any;
  pageUrl?: any;
  html?: any;
}
@Component({
  selector: 'ft-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleListComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  pageable: any;
  noImageUrl = Constant.noImage;
  recordCount = 10;
  showButton: boolean = true;
  clickCount: number = 0;
  href: string = '';
  heading: string = '';
  cardGridContent: any;
  articleList: any = [];
  newPageData: any;
  isInsightsPage: boolean;
  authorAndDate: string = '';
  content: any;
  spaBaseUrl: string = '';
  locale = '';
  constructor(
    private changeDetector: ChangeDetectorRef,
    private location: Location,
    private preRenderService: PrerenderService,
    private appStateService: AppStateService,
    @Inject(WINDOW) readonly windowRef: Window,
    private pageContainerService: PageContainerService
  ) {}

  /**
   * Angular hook
   */
  ngOnInit(): void {
    this.spaBaseUrl = this.appStateService.getApiUrls().spaBaseUrl;
    const currentPath = this.location.path().split('/');
    const pathFragment = currentPath.reverse()[0];
    this.isInsightsPage = pathFragment === Constant.isInsights || this.location.path() === '/insights/index' ? true : false;
    this.content = this.getContent();
    this.locale = this.appStateService.getLocale();
    // If pre render load all articles
    //if(this.preRenderService.isPrerender()){
    //for (let i = 0; i < this.pageable?.totalPages; i++) {
    //this.loadMore();
    //}
    // }
  }

  /**
   * Get the document from resourceApi
   */
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  /**
   *  Get article tile content
   */
  getContent() {
    if (!this.pageable) {
      this.pageable = this.page.getComponent('main-content').getComponent('FTI-list-component').getModels().pageable;
      //this.pageable = this.component.getModels<PageableModels>().pageable;
      //this.articleList = []
      this.getArticleData(this.pageable?.items, false);
      this.articleList.shift();
      if (this.isInsightsPage) {
        this.cardGridContent = this.articleList.splice(0, 4);
      }
      /* Load More button fix*/
      if (this.pageable?.endOffset < this.pageable?.total) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    }
    return this.pageable;
  }

  /**
   * Get current page url
   * @param ref
   * @param fromLoadMore
   * @returns
   */
  getPageUrl(ref: any, fromLoadMore?) {
    if (ref) {
      if (fromLoadMore && this.newPageData) {
        return this.newPageData?.getContent(ref)?.getUrl();
      } else {
        return this.page?.getContent(ref)?.getUrl();
      }
    }
    return;
  }

  getNextbatch(pageId): Observable<Page> {
    const url = this.location.path();
    let pathFragment = '';
    if (url.indexOf('en-ca') != -1) {
      pathFragment = this.location.path().replace('/fiduciarytrust-en-ca', '');
      pathFragment = pathFragment.replace('/en-ca', '');
    } else if (url.indexOf('fr-ca') != -1) {
      pathFragment = this.location.path().replace('/fiduciarytrust-fr-ca', '');
      pathFragment = pathFragment.replace('/fr-ca', '');
    } else {
      pathFragment = this.location.path().replace('/fiduciarytrust', '');
    }

    const pageConfig: PageConfig = {
      configuration: {
        ...this.appStateService.getBrConfiguration(),
        // The spa sdk is going to add in elements to the path based on options and context
        // so we need to supply JUST the current path to the content.
        request: { path: Constant.basePath + pathFragment + '?page=' + pageId },
      },
      mapping: {},
    };

    return from(
      initialize(pageConfig.configuration).then((page: Page) => {
        this.pageContainerService.updateLinks(page); // need to fix links for pagemodel 1.0
        pageConfig.page = page;
        return page;
      })
    );
    // return this.http.get(Constant.basePath + '/resourceapi' + pathFragment + '?page=' + pageId);
  }

  getArticleListComponent(page: Page) {
    const comp = page.getComponent().getComponentById(this.component.getId()); // get same component in new page
    if (comp?.getName() === 'FTI-list-component') { // sanity check
      this.showButton = comp?.getModels()?.pageable?.next;
      return comp.getModels().pageable.items;
    }

    return null;
  }

  loadMore() {
    // Load everything if prerender
    if (this.preRenderService.isPrerender()) {
      this.showButton = false;
      for (let i = 1; i < this.pageable?.totalPages; i++) {
        this.getNextbatch(i + 1).subscribe((data) => {
          this.newPageData = data;
          if (data) {
            this.getArticleData(data, true);
            this.changeDetector.detectChanges();
          }
          this.pageable.currentPage++;
        });
      }
    } else {
      if (this.pageable?.next) {
        this.showButton = true;
        this.getNextbatch(this.pageable?.currentPage + 1).subscribe((data) => {
          this.newPageData = data;
          if (data) {
            this.getArticleData(data, true);
            this.changeDetector.detectChanges();
          }
          this.pageable.currentPage++;

          if (this.preRenderService.isPrerender()) {
            this.loadMore();
          }
        });
      } else {
        this.showButton = false;
      }
    }
  }

  getImageUrl(elem: any, isMobileImage: boolean) {
    let imageUrl = '';
    if (elem?.imagelink) {
      imageUrl = this.page?.getContent(elem.imagelink)?.getData()?.pmHeadshotCircle?.links?.site?.href;
    } else if (elem?.imageCompound?.contentType === 'ftcore:ImageCompound' && elem?.imageCompound?.widenAsset) {
      if (isMobileImage && elem?.imageCompound?.mobileImage?.widenDocument) {
        imageUrl = JSON.parse(elem?.imageCompound?.mobileImage?.widenDocument).url ? JSON.parse(elem?.imageCompound?.mobileImage?.widenDocument).url : '';
      } else {
        imageUrl = JSON.parse(elem?.imageCompound?.widenAsset).url ? JSON.parse(elem?.imageCompound?.widenAsset).url : '';
      }
    }
    if (imageUrl.indexOf('original') != -1 && !isMobileImage) {
      imageUrl = imageUrl.replace('original', 'webp') + Constant.optimisedBannerImg;
    } else if (!imageUrl || imageUrl === '') {
      imageUrl = Constant.noImage;
    }
    return imageUrl;
  }
  getMobileImageUrl(elem: any) {
    let imageUrl = '';
    if (elem?.imageCompound?.contentType === 'ftcore:ImageCompound' && elem?.imageCompound?.widenAsset) {
      if (elem?.imageCompound?.mobileImage?.widenDocument) {
        imageUrl = JSON.parse(elem?.imageCompound?.mobileImage?.widenDocument).url ? JSON.parse(elem?.imageCompound?.mobileImage?.widenDocument).url : '';
      } else {
        imageUrl = JSON.parse(elem?.imageCompound?.widenAsset).url ? JSON.parse(elem?.imageCompound?.widenAsset).url : '';
      }
    }
    if (imageUrl.indexOf('original') != -1) {
      imageUrl = imageUrl.replace('original', 'webp') + Constant.optimisedBannerImgWithCenterAndCrop;
    } else if (!imageUrl || imageUrl === '') {
      imageUrl = Constant.noImage;
    }
    return imageUrl;
  }
  /**
   * Pageable object
   */
  get pagination(): Pageable {
    this.pageable = this.component.getModels<PageableModels>().pageable;
    //this.getArticleData(this.pageable?.items);
    return this.pageable;
  }

  /**
   * Marshall the data into a simple object for the template
   * @param data
   */
  tidyArticleContent(item): articleContent {
    const data = item?.getData();
    let tidyData: articleContent = {};

    // Read authors field
    let authors = data?.articleBanner?.authors;
    tidyData.authorName = authors?.toLowerCase().includes('no author') ? null : authors;
    // Try to read new fiduciary:DisplayDate field & change format
    let dateStr = data?.displayDate || data?.articleBanner?.date;
    tidyData.articleDate = dateStr;

    // Read banner content
    tidyData.preTitle = data?.articleBanner?.ftitopBannerSection?.preTitle;
    tidyData.title = data?.articleBanner?.ftitopBannerSection?.imageCompound?.title;
    tidyData.imageCompound = data?.articleBanner?.ftitopBannerSection?.imageCompound;
    tidyData.link = data?.articleBanner?.ftitopBannerSection?.link;
    tidyData.html = data?.articleBanner?.ftitopBannerSection?.html;

    //form page url using category & page name
    let category = data?.articleBanner?.staticdropdown ? data?.articleBanner?.staticdropdown.trim() : '';
    category = category.toLowerCase().replaceAll('&', '').replaceAll(' ', '-');
    /* let basePathForCanada = "";
    if(window.location.href.includes('en-ca')){
      basePathForCanada = "/fiduciarytrust-en-ca";
    }else if(window.location.href.includes('fr-ca')){
      basePathForCanada = "/fiduciarytrust-fr-ca";
    }else{
      basePathForCanada = "";
    } */
    tidyData.pageUrl = item.getUrl();

    return tidyData;
  }

  /**
   * Get latest article data
   * @param arr
   * @param fromLoadMore
   */
  getArticleData(arr: Page, fromLoadMore) {
    let pageableArr = fromLoadMore ? this.getArticleListComponent(arr) : arr;

    for (let i = 0; i < pageableArr?.length; i++) {
      if (fromLoadMore) {
        this.articleList.push(this.tidyArticleContent(arr?.getContent(pageableArr[i])));
      } else {
        this.articleList.push(this.tidyArticleContent(this.page?.getContent(pageableArr[i])));
      }
    }
  }
}
