<div class="container position-relative bg-lightgray pageTitle">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container *ngIf="page?.isPreview()" [brManageContentButton]="document" parameter="document" [relative]="false" root=""> </ng-container>
      <div class="section-wrp">
        <div class="row">
          <h2
            class="col-xs-12"
            [ngClass]="{ 'pb-5': content?.paddingBottom === 'm', 'pt-5': content?.paddingTop === 'm', 'pb-2': content?.paddingBottom === 's', 'pt-2': content?.paddingTop === 's', 'en-ca':isCanadaSite }"
            #title>
            {{ content?.title }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>
