import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation, ElementRef, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Constant } from 'src/app/ft-constants/constant';

@Component({
  selector: 'ft-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageTitleComponent implements OnInit, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  textAlign: string;
  isCanadaSite:boolean;
  @ViewChild('title', { static: false }) titleElem: ElementRef;
  constructor(private renderer: Renderer2, private el: ElementRef) {}
  ngAfterViewInit(): void {
    if (this.textAlign) {
      this.renderer.addClass(this.titleElem.nativeElement, this.textAlign);
    }
  }

  ngOnInit(): void {
    this.isCanadaSite = Constant.isCanadaSite;
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    this.textAlign = this.component?.getParameters()?.alignment;
    return this.component?.getParameters();
  }
}
