<div class="container position-relative cardGrid bg-lightgray">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div *ngIf="content">
        <div class="grid section-wrp">
          <ng-container *ngIf="!checkIfContactCard && !cardGridRow">
            <div *ngFor="let gridItem of cardGridArr; index as i" class="row">
              <div class="left-content oldgrid col-xs-12 col-md-4 single-column col-sm-12">
                <ng-container *ngIf="singleColumnContent[i]?.imgUrl !== ''; then imageElem; else paragraphElem"> </ng-container>
                <ng-template #imageElem>
                  <a
                    *ngIf="singleColumnContent[i].linkCompound?.linkCollection && singleColumnContent[i].linkCompound?.linkCollection[0].contentType === 'ftcore:LinkInternalPage'"
                    class=""
                    aria-label="article"
                    [routerLink]="getInternalLink(singleColumnContent[i]?.linkCompound?.linkCollection)"
                  >
                    <img [src]="singleColumnContent[i]?.imgUrl" width="331" alt="image" />
                  </a>
                  <a
                    *ngIf="singleColumnContent[i].linkCompound?.linkCollection && singleColumnContent[i].linkCompound?.linkCollection[0].contentType !== 'ftcore:LinkInternalPage'"
                    class=""
                    aria-label="article"
                    [href]="getInternalLink(singleColumnContent[i]?.linkCompound?.linkCollection)"
                  >
                    <img [src]="singleColumnContent[i]?.imgUrl" width="331" alt="image" />
                  </a>
                </ng-template>
                <ng-template #paragraphElem>
                  <a
                    *ngIf="singleColumnContent[i].linkCompound?.linkCollection && singleColumnContent[i].linkCompound?.linkCollection[0].contentType === 'ftcore:LinkInternalPage'"
                    class="grid-item-link"
                    aria-label="article"
                    [routerLink]="getInternalLink(singleColumnContent[i]?.linkCompound?.linkCollection)"
                  ></a>
                  <a
                    *ngIf="singleColumnContent[i].linkCompound?.linkCollection && singleColumnContent[i].linkCompound?.linkCollection[0].contentType !== 'ftcore:LinkInternalPage'"
                    class="grid-item-link"
                    aria-label="article"
                    [href]="getInternalLink(singleColumnContent[i]?.linkCompound?.linkCollection)"
                  ></a>
                  <div class="grid-item single col-xs-12">
                    <div *ngIf="singleColumnContent[i].paragraphBlock.content === ''">
                      <p *ngIf="singleColumnContent[i]?.content?.preTitle">{{ singleColumnContent[i]?.content?.preTitle }}</p>
                      <h3>{{ singleColumnContent[i]?.content?.title }}</h3>
                      <p [innerHTML]="singleColumnContent[i]?.content?.description"></p>
                      <p>
                        <a class="read-more">{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</a>
                      </p>
                    </div>
                    <div *ngIf="singleColumnContent[i].paragraphBlock.content !== ''" [innerHTML]="singleColumnContent[i].paragraphBlock.content"></div>
                  </div>
                </ng-template>
              </div>
              <div class="right-content col-xs-12 col-md-8 double-column col-sm-12">
                <div class="grid-item double col-xs-12">
                  <div class="single col-xs-12 col-sm-6 white col-xs-arrow-up" [ngClass]="{ 'arrow-left': isInsightsPage, 'arrow-right': !isInsightsPage }">
                    <div *ngIf="doubleColumnContent[i].paragraphBlock.content === ''">
                      <p *ngIf="doubleColumnContent[i]?.content?.preTitle">{{ doubleColumnContent[i]?.content?.preTitle }}</p>
                      <h3>{{ doubleColumnContent[i]?.content?.title }}</h3>
                      <p [innerHTML]="doubleColumnContent[i]?.content?.description"></p>
                      <p>
                        <a class="read-more">{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</a>
                      </p>
                    </div>
                    <div *ngIf="doubleColumnContent[i].paragraphBlock.content !== ''" [innerHTML]="doubleColumnContent[i]?.paragraphBlock?.content"></div>
                  </div>
                  <div style="background:url({{ doubleColumnContent[i].imgUrl }}) center center/cover no-repeat" class="single col-xs-12 col-sm-6 white"></div>
                  <ng-container *ngIf="doubleColumnContent[i]?.linkCompound?.linkCollection">
                    <a
                      *ngIf="doubleColumnContent[i]?.linkCompound?.linkCollection[0]?.contentType === 'ftcore:LinkInternalPage'"
                      class="grid-item-link"
                      aria-label="article"
                      [routerLink]="getInternalLink(doubleColumnContent[i]?.linkCompound?.linkCollection)"
                    ></a>
                    <a
                      *ngIf="doubleColumnContent[i]?.linkCompound?.linkCollection[0]?.contentType !== 'ftcore:LinkInternalPage'"
                      class="grid-item-link"
                      aria-label="article"
                      [href]="getInternalLink(doubleColumnContent[i]?.linkCompound?.linkCollection)"
                    ></a>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="cardGridRow && !checkIfContactCard">
            <div *ngFor="let gridItem of cardGridRow; index as i" class="row">
              <ng-container *ngFor="let item of gridItem.items; index as j">
                <div
                  class="col-xs-12 col-sm-12"
                  [ngClass]="[item.contentType === 'fiduciary:SingleColumn' ? 'single-column col-md-4' : 'double-column col-md-8', j === 0 ? 'left-content' : 'right-content']"
                >
                  <ng-container *ngIf="item.contentType === 'fiduciary:SingleColumn'">
                    <ng-container *ngIf="item.singleColumn?.imgUrl !== ''; then imageElem; else paragraphElem"> </ng-container>
                    <ng-template #imageElem>
                      <div style="position: relative">
                        <img [src]="item.singleColumn?.imgUrl" width="331" alt="image" />
                        <a
                          target="_blank"
                          *ngIf="item.singleColumn?.linkCompound?.linkCollection && item.singleColumn?.linkCompound?.linkCollection[0]?.contentType !== 'ftcore:LinkInternalPage'"
                          class="grid-item-link"
                          aria-label="article"
                          [href]="getInternalLink(item.singleColumn?.linkCompound?.linkCollection)"
                        ></a>
                        <a
                          *ngIf="item.singleColumn?.linkCompound?.linkCollection && item.singleColumn?.linkCompound?.linkCollection[0]?.contentType === 'ftcore:LinkInternalPage'"
                          class="grid-item-link"
                          aria-label="article"
                          [routerLink]="getInternalLink(item.singleColumn?.linkCompound?.linkCollection)"
                        ></a>
                      </div>
                    </ng-template>
                    <ng-template #paragraphElem>
                      <div class="grid-item single col-xs-12">
                        <a
                          target="_blank"
                          *ngIf="item.singleColumn?.linkCompound?.linkCollection && item.singleColumn?.linkCompound?.linkCollection[0]?.contentType !== 'ftcore:LinkInternalPage'"
                          class="grid-item-link"
                          aria-label="article"
                          [href]="getInternalLink(item.singleColumn?.linkCompound?.linkCollection)"
                        ></a>
                        <a
                          *ngIf="item.singleColumn?.linkCompound?.linkCollection && item.singleColumn?.linkCompound?.linkCollection[0]?.contentType === 'ftcore:LinkInternalPage'"
                          class="grid-item-link"
                          aria-label="article"
                          [routerLink]="getInternalLink(item.singleColumn?.linkCompound?.linkCollection)"
                        ></a>
                        <div *ngIf="item.singleColumn?.paragraphBlock.content === ''">
                          <p>{{ item.singleColumn?.content?.preTitle }}</p>
                          <h3>{{ item.singleColumn?.content?.title }}</h3>
                          <p [innerHTML]="item.singleColumn?.content?.description"></p>
                          <p>
                            <a class="read-more">{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</a>
                          </p>
                        </div>
                        <div *ngIf="item.singleColumn?.paragraphBlock.content !== ''" [innerHTML]="item.singleColumn?.paragraphBlock.content"></div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-container *ngIf="item.contentType === 'fiduciary:DoubleColumn'">
                    <div class="grid-item double col-xs-12">
                      <div class="single col-xs-12 col-sm-6 white col-xs-arrow-up" [ngClass]="{ 'arrow-left': isInsightsPage, 'arrow-right': !isInsightsPage }">
                        <div *ngIf="item.doubleColumn?.paragraphBlock?.content !== ''" [innerHTML]="item.doubleColumn?.paragraphBlock?.content"></div>
                        <div *ngIf="item.doubleColumn?.paragraphBlock.content === ''">
                          <p *ngIf="item.doubleColumn?.content?.preTitle">{{ item.doubleColumn?.content?.preTitle }}</p>
                          <h3>{{ item.doubleColumn?.content?.title }}</h3>
                          <p [innerHTML]="item.doubleColumn?.content?.description"></p>
                          <p>
                            <a class="read-more">{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</a>
                          </p>
                        </div>
                      </div>
                      <div style="background:url({{ item.doubleColumn?.imgUrl }}) center center/cover no-repeat" class="single col-xs-12 col-sm-6 white"></div>
                      <ng-container *ngIf="item.doubleColumn?.linkCompound?.linkCollection">
                        <a
                          *ngIf="item.doubleColumn?.linkCompound?.linkCollection[0]?.contentType === 'ftcore:LinkInternalPage'"
                          class="grid-item-link"
                          aria-label="article"
                          [routerLink]="getInternalLink(item.doubleColumn?.linkCompound?.linkCollection)"
                        ></a>
                        <a
                          target="_blank"
                          *ngIf="item.doubleColumn?.linkCompound?.linkCollection[0]?.contentType !== 'ftcore:LinkInternalPage'"
                          class="grid-item-link"
                          aria-label="article"
                          [href]="getInternalLink(item.doubleColumn?.linkCompound?.linkCollection)"
                        ></a>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div class="contact-card" *ngIf="checkIfContactCard && !cardGridRow">
            <div class="row">
              <div class="single-column col-md-6 col-lg-4">
                <div class="grid-item single brand-secondary col-xs-12">
                  <div *ngIf="cardGridContentList[0]?.items[0]?.contentType !== 'ftcore:ImageCompound'">
                    <div *ngIf="cardGridContentList[0]?.items[0]?.content" [innerHTML]="cardGridContentList[0]?.items[0]?.content"></div>
                    <a
                      *ngIf="cardGridContentList[0]?.items[1]?.linkCollection && cardGridContentList[0]?.items[1]?.linkCollection[0].contentType === 'ftcore:LinkInternalPage'"
                      [routerLink]="getInternalLink(cardGridContentList[0]?.items[1]?.linkCollection)"
                    >
                      {{ cardGridContentList[0]?.items[1]?.linkCollection[0]?.displayText }}
                    </a>
                    <a
                      *ngIf="cardGridContentList[0]?.items[1]?.linkCollection && cardGridContentList[0]?.items[1]?.linkCollection[0].contentType !== 'ftcore:LinkInternalPage'"
                      [href]="getInternalLink(cardGridContentList[0]?.items[1]?.linkCollection)"
                    >
                      {{ cardGridContentList[0]?.items[1]?.linkCollection[0]?.displayText }}
                    </a>
                  </div>
                  <img [src]="getImageUrl(cardGridContentList[0]?.items[0])" *ngIf="cardGridContentList[0]?.items[0]?.contentType === 'ftcore:ImageCompound'" />
                </div>
              </div>
              <div class="double-column col-md-12 col-lg-8">
                <div class="grid-item double col-xs-12">
                  <div class="single col-xs-12 col-sm-6 white col-xs-arrow-up col-sm-arrow-right col-md-arrow-right col-lg-arrow-right">
                    <div class="content" [innerHTML]="cardGridContentList[0]?.itemsTwo[0]?.content"></div>
                  </div>
                  <div class="single col-xs-12 col-sm-6" style="background:url({{ getImageUrl(cardGridContentList[0]?.itemsTwo[1]) }}); margin-bottom:0; background-size: cover;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
