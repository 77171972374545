<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container *ngIf="page?.isPreview()" [brManageContentButton]="document" parameter="document" [relative]="false" root=""> </ng-container>
  <ng-container *ngIf="document">
    <div class="row tabcontent">
      <ng-container *ngIf="layout === profileAndTabsLayout">
        <div class="col-md-4 col-sm-4 left-content">
          <ng-container *ngFor="let item of tabData?.items">
            <h2 *ngIf="item.type == 'TitleBlock'">{{ item.title }}</h2>
            <div *ngIf="item.type == 'ParagraphBlock'" [innerHTML]="item.content"></div>
          </ng-container>
        </div>
        <div class="col-md-8 col-sm-8 right-content">
          <ng-container *ngFor="let item of tabData?.itemsTwo">
            <h3 *ngIf="item.type == 'TitleBlock'">{{ item.title }}</h3>
            <div *ngIf="item.type == 'ParagraphBlock'" [innerHTML]="item.content"></div>
            <img alt="image" *ngIf="item.$ref || item.widenAsset" class="" [src]="getImageUrl(item)" />
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="layout === smallTabsLayout">
        <ng-container *ngIf="isCustodyPage">
          <div class="col-md-12 left-content" [ngClass]="tabData.itemsTwo.length === 0 ? 'col-lg-12' : 'col-lg-7'">
            <ng-container *ngFor="let item of tabData?.items">
              <h2 *ngIf="item.type == 'TitleBlock'">{{ item.title }}</h2>
              <div class="wrapper" *ngIf="item.type == 'ParagraphBlock'" [innerHTML]="item.content"></div>
            </ng-container>
          </div>
          <div *ngIf="tabData.itemsTwo.length !== 0" class="col-lg-5 col-md-12 right-content">
            <ng-container *ngFor="let item of tabData?.itemsTwo">
              <img alt="image" *ngIf="item.$ref || item.widenAsset" class="col-md-pull-2" [src]="getImageUrl(item)" />
              <br *ngIf="item.$ref || item.widenAsset" />
              <h3 *ngIf="item.type == 'TitleBlock'">{{ item.title }}</h3>
              <div *ngIf="item.type == 'ParagraphBlock'" [innerHTML]="item.content"></div>
              <a *ngIf="item.contentType === 'ftcore:LinkCompound' && item?.linkCollection[0]?.document" [routerLink]="getInternalLink(item?.linkCollection[0]?.document)">
                {{ item?.linkCollection[0]?.displayText }}
              </a>
              <a *ngIf="item.contentType === 'ftcore:LinkCompound' && item?.linkCollection[0]?.widenAssetCompound" [href]="getPdf(item?.linkCollection[0]?.widenAssetCompound)" target="_blank">
                {{ item?.linkCollection[0]?.displayText }}
              </a>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!isCustodyPage">
          <div class="col-md-12 col-sm-12 left-content not-custody" [ngClass]="tabData.itemsTwo.length === 0 ? 'col-lg-12' : 'col-lg-6'">
            <ng-container *ngFor="let item of tabData?.items">
              <h2 *ngIf="item.type == 'TitleBlock'">{{ item.title }}</h2>
              <div class="wrapper" *ngIf="item.type == 'ParagraphBlock'" [innerHTML]="item.content"></div>
              <ng-container *ngIf="item.contentType === 'ftcore:LinkCompound'">
                <a *ngIf="item?.linkCollection[0]?.contentType === 'ftcore:LinkInternalPage'" [routerLink]="getInternalLink(item?.linkCollection[0]?.document)">
                  {{ item?.linkCollection[0]?.displayText }}
                </a>
              </ng-container>
              <ng-container *ngIf="item.contentType === 'ftcore:LinkCompound'">
                <strong>
                  <span *ngIf="item.linkCollection[0]?.externalSuffix">{{ item.linkCollection[0].externalSuffix }}</span>
                  <a *ngIf="item.linkCollection[0]?.displayText" href="{{ item?.linkCollection[0]?.url }}">
                    {{ item?.linkCollection[0].displayText }}
                  </a>
                </strong>
              </ng-container>
            </ng-container>
          </div>
          <div *ngIf="tabData.itemsTwo.length !== 0" class="col-lg-5 col-md-12 col-sm-12 offset-lg-1 right-content not-custody">
            <ng-container *ngFor="let item of tabData?.itemsTwo">
              <img alt="image" *ngIf="item.$ref || item.widenAsset" class="col-sm-12 col-xs-12 col-md-pull-2" [src]="getImageUrl(item)" />
              <br *ngIf="item.$ref || item.widenAsset" />
              <h3 *ngIf="item.type == 'TitleBlock'">{{ item.title }}</h3>
              <div *ngIf="item.type == 'ParagraphBlock'" [innerHTML]="item.content"></div>
              <ng-container *ngIf="item.contentType === 'ftcore:LinkCompound'">
                <a *ngIf="item?.linkCollection[0]?.contentType === 'ftcore:LinkInternalPage'" [routerLink]="getInternalLink(item?.linkCollection[0]?.document)">
                  {{ item?.linkCollection[0]?.displayText }}
                </a>
                <a *ngIf="item?.linkCollection[0]?.widenAssetCompound" [href]="getPdf(item?.linkCollection[0]?.widenAssetCompound)" target="_blank">
                  {{ item?.linkCollection[0]?.displayText }}
                </a>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
