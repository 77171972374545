import { Component, Input, OnInit, Inject } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from '@ng-web-apis/common';
@Component({
  selector: 'ft-leaving-site-modal',
  templateUrl: './leaving-site-modal.component.html',
  styleUrls: ['./leaving-site-modal.component.scss'],
})
export class LeavingSiteModalComponent implements OnInit {
  @Input() url;
  constructor(public activeModal: NgbActiveModal, @Inject(WINDOW) private windowRef: Window) {}

  ngOnInit(): void {}
  closeModal() {
    this.windowRef.open(this.url, '_blank');
    this.activeModal.close();
  }
}
