<div class="container position-relative component-variant-homepage">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="row">
        <div class="col-xs-12">
          <div class="banner" [style.background-image]="'url(' + bannerImg + ')'">
            <div class="row">
              <div class="col-sm-12">
                <h1 class="header-commentary mb2">
                  <span class="ft-2x">{{ activeBanner.bigTitle }}</span>
                </h1>
              </div>
              <div class="row hidden-xs mt3">
                <div class="col-sm-4">
                  <div class="panel view-box" [innerHTML]="activeBanner.macroView.content"></div>
                </div>
                <div class="col-sm-4">
                  <div class="panel view-box" [innerHTML]="activeBanner.sectorView.content"></div>
                </div>
                <div class="col-sm-4">
                  <div class="panel view-box" [innerHTML]="activeBanner.tacticalView.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="grid">
          <div class="grid-locked">
            <ng-container *ngFor="let item of tabsCard; index as i">
              <div class="my-panel">
                <div class="my-panel-body">
                  <div class="row" id="anc1">
                    <div class="col-sm-12">
                      <p class="header-views">{{ item.title }}</p>
                      <div class="row">
                        <p class="col-sm-6">{{ item.leftTitle }}</p>
                        <p class="col-sm-6">
                          <small>{{ item.rightTitle }}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr class="mt2 hidden-xs" />
                  <div class="row mt4">
                    <div class="col-md-3 col-sm-4">
                      <ul ngbNav #nav="ngbNav" class="nav-pills" role="tablist" orientation="vertical">
                        <li [ngbNavItem]="ind" class="" *ngFor="let tabItem of item.tabs; index as ind">
                          <a ngbNavLink class="">
                            {{ tabItem?.tabTitle }}
                          </a>
                          <ng-template ngbNavContent>
                            <div class="col-md-7 col-sm-12">
                              <p *ngIf="getImg(tabItem.image) !== ''"><img [src]="getImg(tabItem.image)" /></p>
                              <div [innerHTML]="tabItem.content.content"></div>
                            </div>
                          </ng-template>
                        </li>
                      </ul>
                    </div>
                    <div [ngbNavOutlet]="nav" class="col-md-9 col-sm-8"></div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
