import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Constant } from '../../ft-constants/constant';
import { Location } from '@angular/common';
import brightcovePlayerLoader from '@brightcove/player-loader';
import { AppStateService } from '@services/app-state.service';

@Component({
  selector: 'ft-video-cards',
  templateUrl: './video-cards.component.html',
  styleUrls: ['./video-cards.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoCardsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  accountID: any;
  videoID: any;
  locale = '';
  constructor(private appStateService: AppStateService) {}

  ngOnInit(): void {
    this.locale = this.appStateService.getLocale();
  }
  ngAfterViewInit() {
    for (let i = 0; i < this.videoCards.length; i++) {
      const elem = this.videoCards[i].embeddedVideo;
      this.setVideoAttributes(elem, 'player-' + i);
    }
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    return this.document?.getData();
  }
  get gridList() {
    return this.content.profile;
  }
  getImg(elem) {
    let imageUrl = '';
    if (elem.widenDocument && elem.widenDocument !== '') {
      imageUrl = JSON.parse(elem.widenDocument).url ? JSON.parse(elem.widenDocument).url : '';
    }
    return imageUrl;
  }
  setVideoAttributes(item, id) {
    let attrs = item.videoEmbedCode.split('\r\n');
    for (let i = 0; i < attrs.length; i++) {
      let item = attrs[i];
      item = item.split('=');
      if (item[0] === 'data-video-id') {
        this.videoID = item[1];
        this.videoID = this.videoID.replaceAll('"', '');
      } else if (item[0] === 'data-account-id') {
        this.accountID = item[1];
        this.accountID = this.accountID.replaceAll('"', '');
      }
    }
    if (this.accountID && this.videoID) {
      this.videoembeded(this.videoID, this.accountID, id);
    }
  }
  videoembeded(video_id: any, account_id: any, playerId: any) {
    console.log('videoEmbedded');
    brightcovePlayerLoader({
      refNode: '#' + playerId,
      accountId: account_id,
      videoId: video_id,
      refNodeInsert: 'replace',
      embedOptions: {
        tagName: brightcovePlayerLoader.EMBED_TAG_NAME_VIDEO,
      },
      options: {
        width: '520px',
        height: '300px',
        playButton: 'center',
      },
    })
      .then(function (success) {})
      .catch(function (error) {});
  }
  get videoCards() {
    return this.content.videoCard;
  }
  getPageUrl(ref: any) {
    const pageUrl = this.page?.getContent(ref)?.getData()?.links?.site?.href;
    return pageUrl;
  }
}
