import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, Renderer2, ViewChildren, QueryList, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Constant } from 'src/app/ft-constants/constant';
@Component({
  selector: 'ft-two-row-image-content',
  templateUrl: './two-row-image-content.component.html',
  styleUrls: ['./two-row-image-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TwoRowImageContentComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  imageWithContentList = [];
  constructor() {}
  ngOnInit(): void {}
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    return this.document?.getData();
  }

  get imageWithContent() {
    this.imageWithContentList = this.content?.ftitwoRowImage || [];
    this.getImages();
    return this.imageWithContentList;
  }

  getImages() {
    const data = this.content?.ftitwoRowImage || [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      let imageUrl;
      if (item.imagelink) {
        imageUrl = this.page?.getContent(item.imagelink).getUrl;
      } else if (item?.imageCompound?.contentType === 'ftcore:ImageCompound' && item?.imageCompound?.widenAsset) {
        imageUrl = JSON.parse(item?.imageCompound?.widenAsset).url ? JSON.parse(item?.imageCompound?.widenAsset).url : '';
      }
      item.imgUrl = imageUrl && imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') + Constant.optimisedPrimaryLandingImg : '';
    }
    this.imageWithContentList = data;
  }
  getInternalLink(document: any) {
    if(document){
      return this.page?.getContent(document).getUrl();
    }
    return;
  }
  get title() {
    return this.page?.getTitle();
  }
}
