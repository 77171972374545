import { Component, HostBinding, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { Component as BrComponent, Content, Menu, Page } from '@bloomreach/spa-sdk';
import { getMenus, getModelFromRef } from '@utils/pagemodel-utils';

@Component({
  selector: 'ft-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  menu: Content;
  menuModel: Menu;

  @HostBinding('class') class = 'navbar-nav col-12';

  ngOnInit() {
    this.menu = getModelFromRef(this.page, this.component, 'menu');
    this.menuModel = getMenus(this.page, this.component);
  }

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get isPreview() {
    return this.page?.isPreview();
  }
}
