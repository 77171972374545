import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
@Component({
  selector: 'ft-two-column-content',
  templateUrl: './two-column-content.component.html',
  styleUrls: ['./two-column-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TwoColumnContentComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  isRadnor: boolean;
  constructor() {}
  ngOnInit(): void {
    this.isRadnor = window.location.pathname.indexOf('radnor') > 0 ? true : false;
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  get twoColContent() {
    return this.content?.twoColumnBlock || [];
  }
  getImg(elem) {
    let imageUrl = '';
    if (elem.widenDocument && elem.widenDocument !== '') {
      imageUrl = JSON.parse(elem.widenDocument).url ? JSON.parse(elem.widenDocument).url : '';
    }else if (elem.contentType === 'ftcore:ImageCompound' && elem.widenAsset) {
      imageUrl = JSON.parse(elem.widenAsset).url ? JSON.parse(elem.widenAsset).url : '';
    }
    return imageUrl;
  }
  get title() {
    return this.page?.getTitle();
  }
}
