import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Configuration, Page } from '@bloomreach/spa-sdk';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PageConfig, PageContainerService } from '../services/page-container.service';
import { Constant } from 'src/app/ft-constants/constant';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { WINDOW } from '@ng-web-apis/common';
import { Logger } from '@utils/logger';
import { PrerenderService } from '@services/prerender.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AppStateService } from '@services/app-state.service';

const logger = Logger.getLogger('PageContainerComponent');

export interface DataWindow extends Window {
  // TODO: define type for dataLayer
  dataLayer: any[];
}

interface PageAnalyticsMetadata {
  assetClass: [];
  fundBrand: [];
  investmentTeam: [];
  investmentTheme: [];
}

@Component({
  selector: 'ft-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent implements OnInit {
  configuration: Configuration;
  page: Page;
  mapping: Object;
  layout;
  tabCount = 0;
  profileCount;
  isSearchPage;
  contactForm;
  outlookContactForm;
  isEmailSubscriptionPage;
  isArticleDetailsPage;
  isOutlookContactForm;
  isInsightsPage;
  pageLoaded = false;
  isCanadaSite;
  @ViewChild('emailSubForm', { static: false }) emailSubForm: NgForm;
  @ViewChild('outlookForm', { static: false }) outlookForm: NgForm;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private location: Location,
    private router: Router,
    private pageContainerService: PageContainerService,
    private activatedRoute: ActivatedRoute,
    @Inject(WINDOW) readonly windowRef: DataWindow,
    private meta: Meta,
    private title: Title,
    private changeDetectorRef: ChangeDetectorRef,
    private preRenderService: PrerenderService,
    private gtmService: GoogleTagManagerService,
    private appStateService: AppStateService
  ) {
    this.pageContainerService.page$.pipe(takeUntil(this.unsubscribe$)).subscribe((pageConfig: PageConfig) => {
      // Page Config
      this.page = pageConfig.page;
      this.mapping = pageConfig.mapping;
      this.configuration = pageConfig.configuration;

      this.layout = this.page.getComponent().getParameters()?.layout;
      this.tabCount = this.page.getComponent().getParameters()?.tabCount;
      const profileCountComp = this.page?.getComponent('main-content')?.getComponent('FTI-profile-count'); //parseInt(this.page[modelKey].page?._meta?.params?.profileCount);
      this.profileCount = profileCountComp ? parseInt(profileCountComp.getParameters().count) : null;
      Constant.basePath = this.configuration?.['spaBaseUrl'];
      this.setMetaData(this.page);
      this.pageLoadCompletedEvent();
      this.changeDetectorRef.detectChanges();
    });

    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        const currentPath = this.location?.['_platformLocation'].location.pathname.split('/');
        const pathFragment = currentPath.reverse()[0];
        this.isSearchPage = pathFragment === Constant.searchPage ? true : false;
        this.isEmailSubscriptionPage = pathFragment === Constant.emailSubscriptionPage ? true : false;
        this.isOutlookContactForm = pathFragment === Constant.outlookContactPage ? true : false;
        this.isInsightsPage = pathFragment === Constant.insightsPage ? true : false;
        this.pageContainerService.newPage(location.path());
        this.isCanadaSite = Constant.isCanadaSite;
        setTimeout(() => {
          (this.windowRef as any).prerenderReady = true;
        }, 15000);
      });
  }
  ngOnInit(): void {
    this.contactForm = new FormGroup({
      firstname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      lastname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      clientOrProspectOrRefSource: new FormControl('', [Validators.minLength(10)]),
    });
    this.outlookContactForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.pattern('[- +()0-9]+')]),
      eventLocation: new FormControl('', [Validators.required, Validators.minLength(3)]),
    });
  }
  onSubmit() {
    this.emailSubForm.resetForm();
  }
  onSubmitOutlookForm() {
    this.outlookForm.resetForm();
  }

  /**
   * Returns string from PageAnalyticsMetadata array item
   * @param data - PageAnalyticsMetadata Array item
   */
  private joinAnalyticsMetadata(data: []): string {
    if (data && data?.length > 0) {
      return data.join(',');
    }
  }

  private pageLoadCompletedEvent(): void {
    this.gtmService.pushTag({
      event: 'page_view',
      detailed_event: 'Page Load Completed',
    });
  }

  private setMetaData(page: Page): void {
    const metadata = this.page.getComponent().getModels().pageData;
    const analyticsMetadata: PageAnalyticsMetadata = metadata?.analyticsMetadata;
    if (metadata) {
      if (metadata.title) {
        this.title.setTitle(metadata.title + (metadata.appendedTitleText ? ' | ' + metadata.appendedTitleText : ''));
      }

      // Horrible
      metadata.title = metadata?.title?.replace(' | Fiduciary Trust Company International', '');

      if (metadata.socialImage) {
        metadata.socialImage = metadata.socialImage.replace('original', 'jpeg');
        metadata.socialImage += '?w=1200&c=true&k=c';
      }

      this.updateMetaTags([
        { name: 'description', content: metadata.description },
        { name: 'keywords', content: metadata.keywords },
        { name: 'language', content: metadata.language },
        {
          property: 'og:title',
          content: metadata.socialTitle && metadata.socialTitle !== '' ? metadata.socialTitle : metadata.title,
        },
        { property: 'og:site_name', content: metadata.siteName },
        {
          property: 'og:description',
          content: metadata.socialDescription && metadata.socialDescription !== '' ? metadata.socialDescription : metadata.description,
        },
        {
          property: 'og:image',
          content: metadata.socialImage && metadata.socialImage !== '' ? metadata.socialImage : 'https://www.fiduciarytrust.com/assets/images/no-image-found.jpg',
        },
        { property: 'twitter:site', content: metadata.twitterSite },
        { property: 'twitter:card', content: metadata.twitterCard },
      ]);

      const robotsContent: string[] = [];
      robotsContent.push(metadata.noIndex ? 'noindex' : 'index');
      robotsContent.push(metadata.noFollow ? 'nofollow' : 'follow');

      this.gtmService.pushTag({
        event: 'PageView',
        PageURL: this.location.path(),
        PageTitle: metadata.title,
      });

      this.gtmService.pushTag({
        page_location: this.location.path(),
        event: 'page_load_started',
        detailed_event: 'Page Load Started',
        page_title: metadata.title,
        page_type: metadata?.pageType,
        language_code: metadata?.language,
        asset_class: this.joinAnalyticsMetadata(analyticsMetadata?.assetClass),
        fund_brand: this.joinAnalyticsMetadata(analyticsMetadata?.fundBrand),
        investment_theme: this.joinAnalyticsMetadata(analyticsMetadata?.investmentTheme),
        page_data: {
          asset_class: this.joinAnalyticsMetadata(analyticsMetadata?.assetClass),
          fund_brand: this.joinAnalyticsMetadata(analyticsMetadata?.fundBrand),
          investment_theme: this.joinAnalyticsMetadata(analyticsMetadata?.investmentTheme),
          language_code: metadata?.language,
          page_location: this.location.path(),
          page_title: metadata?.title,
          page_type: metadata?.pageType,
          article_title: undefined,
          author: undefined,
          country: undefined,
          fund_category: undefined,
          fund_class: undefined,
          fund_name: undefined,
          fund_number: undefined,
          fund_tab: undefined,
          fund_ticker: undefined,
          fund_type: undefined,
          language: undefined,
          name: undefined,
          page_category: undefined,
          page_subcategory: undefined,
          personalization_experience: undefined,
          personalization_target: undefined,
          personalization_type: undefined,
          publication_series: undefined,
          publish_date: undefined,
          segmentation: undefined,
          type: undefined,
          vehicle: undefined,
        },
      });

      const countryCode = this.appStateService?.locale === 'en-ca' ? 'CA' : undefined;

      this.gtmService.pushTag({
        event: 'detect_user',
        detailed_event: 'User Detected',
        is_internal_user: undefined,
        user_data: {
          country_code: countryCode,
          market_code: countryCode,
        },
      });
    } else {
      // Default hack just now:
      this.updateMetaTags([
        { name: 'description', content: 'Fiduciary Trust International is an investment and wealth manager, providing personalized solutions to individuals, families and foundations.' },
        { name: 'keywords', content: 'Fiduciary Trust International' },
        { name: 'language', content: 'en-us' },
        {
          property: 'og:title',
          content: 'Fiduciary Trust International',
        },
        { property: 'og:image', content: '/assets/images/no-image-found.jpg' },
        { property: 'og:site_name', content: 'Fiduciary Trust International' },
        {
          property: 'og:description',
          content: 'Fiduciary Trust International is an investment and wealth manager, providing personalized solutions to individuals, families and foundations.',
        },
      ]);

      this.gtmService.pushTag({
        event: 'PageView',
        PageURL: this.location.path(),
        PageTitle: 'Fiduciary Trust International',
      });
    }
  }

  private updateMetaTags(tags: MetaDefinition[]): void {
    tags.forEach((tag) => {
      if (tag.content && tag.content !== '') {
        let selector: string;
        if (tag.property != null) {
          selector = `property='${tag.property}'`;
        } else {
          selector = `name='${tag.name}'`;
        }
        this.meta.updateTag(tag, selector);
      }
    });
  }
}
