<div class="container position-relative image-overlay bg-lightgray">
  <div class="section-inner-txt">
    <div class="section-wrp">
      <div class="meet-people-section" #meetPeople *ngIf="isGridOverlay">
        <div class="row" #imageGridWrap>
          <div
            *ngFor="let item of profileArray; index as i"
            class="profile-wrapper col-sm-12 col-xs-12 col-md-6 col-lg-4 position-relative"
            #imageWrapRef
            id="imgBlock-{{ i + 1 }}"
            (click)="toggle($event)"
            (keydown.enter)="toggle($event)"
          >
            <ng-container brComponent="profile-area-{{ i + 1 }}"></ng-container>
          </div>
          <div class="grid-overlay hide" #gridOverlay>
            <div [@animate]="show">
              <div class="overlay-close-button" tabindex="0" (click)="hideOverlay()"></div>
              <div class="grid-content" #gridContent></div>
              <div class="arrow" #gridContentArrow [ngClass]="{ left: arrowPosition == 1, middle: arrowPosition == 2, right: arrowPosition == 3 }"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="contact-us-profiles" *ngIf="!isGridOverlay">
        <div class="row">
          <div *ngFor="let item of profileArray; index as i" class="profile-wrapper col-sm-12 col-xs-12 col-md-4 position-relative">
            <ng-container brComponent="profile-area-{{ i + 1 }}"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
