import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Constant } from '../../ft-constants/constant';
@Component({
  selector: 'ft-careerbottombillboard',
  templateUrl: './careerbottombillboard.component.html',
  styleUrls: ['./careerbottombillboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CareerbottombillboardComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  topbannerImage: any;
  topbannerText: any;
  imageWithContentList = [];
  opportunitieslink: any;
  opportunitylable: any;
  constructor() {}

  ngOnInit(): void {
    this.opportunitieslink = Constant.opportunitiesLink;
    this.opportunitylable = Constant.opportunitiesLable;
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    return this.document?.getData();
  }

  get imageWithContent() {
    this.imageWithContentList = this.content?.ftitopBannerSection || [];
    this.getImages();
    return this.imageWithContentList;
  }

  getImages() {
    const data = this.content?.ftitopBannerSection || [];
    this.imageWithContentList = data;
  }
  get bannerimage() {
    const data = this.content?.ftitopBannerSection;
    let imageUrl = '';
    if (data?.imagelink) {
      imageUrl = this.page?.getContent(data?.imagelink?.$ref).getUrl() || '';
    } else if (data?.imageCompound?.contentType === 'ftcore:ImageCompound' && data?.imageCompound?.widenAsset) {
      imageUrl = JSON.parse(data?.imageCompound?.widenAsset).url ? JSON.parse(data?.imageCompound?.widenAsset).url : '';
    }
    this.topbannerImage = imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') + Constant.optimisedBannerImg : '';
    return this.topbannerImage;
  }
  get bannerText() {
    const data = this.content?.ftitopBannerSection;
    this.topbannerText = data?.html;
    return this.topbannerText;
  }
  get title() {
    return this.page?.getTitle();
  }
  get isPreview() {
    return this.page?.isPreview() || {};
  }
}
