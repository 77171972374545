import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Constant } from 'src/app/ft-constants/constant';

@Component({
  selector: 'ft-fti-profiles',
  templateUrl: './fti-profiles.component.html',
  styleUrls: ['./fti-profiles.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FtiProfilesComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  isGridOverlay!: any;
  constructor() {}

  ngOnInit(): void {
    this.isGridOverlay = this.overlayBoolean;//this.pageLayout() === Constant.isProfileAndTabsLayout ? true : false;
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    return this.document?.getData();
  }
  get description() {
    return this.document?.getData()?.content;
  }
  get imageTitle() {
    return this.document?.getData()?.title;
  }
  get profilePreTitle() {
    return this.document?.getData().preTitle;
  }
  get profileTitle() {
    return this.document?.getData().title;
  }
  get profileDesignation() {
    return this.document?.getData().designation;
  }
  get profileDescription() {
    return this.document?.getData().description;
  }
  get overlayBoolean() {
    return this.document?.getData().booleanRadioGroup;
  }
  getImageUrl(elem: any) {
    let imageUrl = '';
    if (elem.image) {
      imageUrl = this.page?.getContent(elem.image)?.getData()?.original?.links?.site?.href;
    } else if (elem?.imageCompound?.contentType === 'ftcore:ImageCompound' && elem?.imageCompound?.widenAsset) {
      imageUrl = JSON.parse(elem?.imageCompound?.widenAsset).url ? JSON.parse(elem?.imageCompound?.widenAsset).url : '';
      imageUrl = imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') + Constant.optimisedProfileImg : '';
    }
    return imageUrl;
  }
  pageLayout() {
    const modelKey = 'model';
    return this.page?.getComponent()?.[modelKey]._meta?.params?.layout;
  }
}
