import { NgModule } from '@angular/core';
import { IsExternalLinkPipe } from './is-external-link.pipe';
import { IsInternalLinkPipe } from './is-internal-link.pipe';
import { ParseUrlPipe } from './parse-url.pipe';

// Components
@NgModule({
  declarations: [IsExternalLinkPipe, IsInternalLinkPipe, ParseUrlPipe],
  imports: [],
  exports: [IsExternalLinkPipe, IsInternalLinkPipe, ParseUrlPipe],
  providers: [],
})
export class PipesModule {}
