import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { AppStateService } from '@services/app-state.service';
import { Constant } from '../../../ft-constants/constant';
@Component({
  selector: 'ft-awards-list',
  templateUrl: './awards-list.component.html',
  styleUrls: ['./awards-list.component.scss'],
})
export class AwardsListComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  channelName: string = '';
  isAdvisoryPage: boolean = false;
  isCanadaSite:boolean;
  constructor(private appStateService: AppStateService) {}

  ngOnInit(): void {
    this.channelName = this.appStateService.getChannel();
    this.isAdvisoryPage = window.location.pathname.indexOf(Constant.advisoryBoard) !== -1 ? true : false;
    this.isCanadaSite = Constant.isCanadaSite;
  }

  get content() {
    const arrContent = [];
    const data = this.component.getModels();

    const arrData = [];
    const arrYear = [];

    data?.pageable?.items.forEach((element) => {
      arrContent.push(this.page?.getContent(element));
    });

    if (arrContent.length > 0) {
      arrContent.forEach((element) => {
        const model = element.getData();
        if (arrYear.indexOf(model.year) === -1) {
          arrYear.push(model.year);
          arrData.push({ year: model.year, data: [] });
        }

        arrData.forEach((value, key) => {
          if (arrData[key].year === model.year) {
            const dataKey = 'data';
            arrData[key][dataKey].push(model);
          }
        });
      });
    }
    arrData.sort((a, b) => {
      return b.year - a.year;
    });
    console.clear();
    console.log("Awards: \n"+ JSON.stringify(arrData));
    return arrData;
  }

  get contentBlock() {
    return this.content || [];
  }

  getImageUrl(elem: any) {
    let imageUrl = '';
    if (elem?.imagelink) {
      imageUrl = this.page?.getContent(elem?.imagelink)?.getData()?.original?.links?.site?.href;
    } else if (elem?.imageCompound?.contentType === 'ftcore:ImageCompound' && elem?.imageCompound?.widenAsset) {
      imageUrl = JSON.parse(elem?.imageCompound?.widenAsset).url ? JSON.parse(elem?.imageCompound?.widenAsset).url : '';
      imageUrl = imageUrl.indexOf('original') !== -1 ? imageUrl.replace('original', 'webp') + Constant.optimisedBannerImg : '';
    }
    return imageUrl;
  }

  getPageUrl(ref: any) {
    const pageUrl = this.page?.getContent(ref)?.getData()?.links?.site?.href;
    return pageUrl;
  }
}
