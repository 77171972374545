<div class="container position-relative cardGrid bg-lightgray">
  <div class="section-inner-txt">
    <div class="grid section-wrp">
      <div class="row">
        <!-- Card -->
        <div class="col col-xs-12 col-md-8">
          <ng-container *ngTemplateOutlet="dblCard"></ng-container>
        </div>
        <div class="col col-xs-12 col-md-4">
          <ng-container *ngTemplateOutlet="imgCard"></ng-container>
        </div>
      </div>

      <div class="row">
        <!-- Card -->
        <div class="col col-xs-12 col-md-4">
          <ng-container *ngTemplateOutlet="card"></ng-container>
        </div>
        <div class="col col-xs-12 col-md-8">
          <ng-container *ngTemplateOutlet="dblCard"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Card template -->
<ng-template #imgCard>
  <div class="card">
    <!-- Card Image -->
    <div class="card__image">
      <img src="https://via.placeholder.com/468x60" alt="Image" />
    </div>
  </div>
</ng-template>

<!-- Card template -->
<ng-template #card>
  <div class="card">
    <!-- Card Content -->
    <div class="card__content">
      <p class="card__pre-title">Market Commentary</p>
      <h3 class="card__title">Card 1</h3>
      <p class="card__date">01/14/2022</p>
    </div>
    <!-- / Card Content -->
  </div>
</ng-template>

<!-- Double Card template -->
<ng-template #dblCard>
  <div class="card">
    <!-- Card Image -->
    <div class="card__image">
      <img src="https://via.placeholder.com/468x60" alt="Image" />
    </div>
    <!-- Card Content -->
    <div class="card__content">
      <p class="card__pre-title">Market Commentary</p>
      <h3 class="card__title">Card 1</h3>
      <p class="card__date">01/14/2022</p>
    </div>
    <!-- / Card Content -->
  </div>
</ng-template>
