<div class="container position-relative video-cards bg-lightgray">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="row">
        <div class="grid">
          <div class="grid-locked row">
            <ng-container *ngFor="let item of videoCards; index as i">
              <div class="col-sm-6">
                <div class="wrapper">
                  <h6 class="header-primary-landing">{{ item.title }}</h6>
                  <div style="display: block; position: relative; max-width: 100%">
                    <div class="player" id="{{ 'player-' + i }}"></div>
                  </div>
                  <div class="desc" [innerHTML]="item.description"></div>
                  <a class="redBtn" [routerLink]="getPageUrl(item.link.linkCollection[0].document)">{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
