<div class="modal-body">
  <p>
    Clicking OK below will take you to an independent site. Information and services provided on this independent site are not reviewed by, guaranteed by, or endorsed by Fiduciary Trust Company
    International or its affiliates. Please keep in mind that this independent site's terms and conditions, privacy and security policies, or other legal information may be different from those of
    Fiduciary Trust Company International's site. Fiduciary Trust Company International is not liable for any direct or indirect technical or system issues, consequences, or damages arising from your
    use of this independent website.
  </p>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="primary-btn" (click)="closeModal()">OK</button>
  <button type="button" class="outline-btn" (click)="activeModal.close('Cancel click')">CANCEL</button>
</div>
