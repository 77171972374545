import { Inject, Injectable } from '@angular/core';
import { EnvConfigService } from './env-config.service';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
import { Logger } from '@utils/logger';
import { AppInsightsLoggerOutput } from '@utils/logging/app-insights-logger-output';
import { Constant } from 'src/app/ft-constants/constant';
import { ConsoleLoggerOutput } from '@utils/logging/console-logger-output';
import { HttpClient } from '@angular/common/http';
import { Configuration, PageModel } from '@bloomreach/spa-sdk';
import { map } from 'rxjs/operators';

const logger = Logger.getLogger('AppStateService');

declare var appInitChannelConfig;

export interface ApiUrls {
  cmsBaseUrl?: string;
  spaBaseUrl: string;
  origin: string;
  endpoint?: string;
}

/**
 * TODO refactor later
 * Temporary solution to cater for application state until we decide what we really want to do
 */
@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  channel = 'ft-core';
  locale = 'en';
  apiUrls: ApiUrls;
  preview: boolean;
  token: string;
  auth: string;
  private debugPageModel = false;
  private spaBaseUrl: string;
  private authState: Observable<any>;
  constructor(private envConfig: EnvConfigService, @Inject(DOCUMENT) private documentRef: Document, @Inject(WINDOW) readonly windowRef: Window, private httpClient: HttpClient) {
    const params = new URLSearchParams(this.windowRef.location.search);
    this.debugPageModel = params.get('debug') === 'pagemodel';
    this.token = params.get('token');
    this.auth = envConfig.getEnvConfig()?.auth;

    this.channel = appInitChannelConfig ? appInitChannelConfig?.channelId : this.channel;
    this.locale = appInitChannelConfig ? appInitChannelConfig?.locale : this.locale;

    this.initializeLogging();
  }
  getSpaBaseUrl(): string {
    return this.spaBaseUrl;
  }

  initializeLogging() {
    Logger.addConfiguration({
      defaultOutputs: [new AppInsightsLoggerOutput(this.envConfig, 'Fiduciary'), new ConsoleLoggerOutput()],
    });
  }

  // TODO: temp hacky way to work out if on home page
  isHomePage(): boolean {
    const spaBaseUrl: string = this.getApiUrls().spaBaseUrl;
    const path: string = this.windowRef.location.pathname.replace(spaBaseUrl, '');
    return ['/', ''].includes(path);
  }

  /**
   * if set, enables personalisation banner on site
   * The url points to the gateway homepage content
   */
  getGatewayUrl(): string {
    logger.debug('getGatewayUrl()', appInitChannelConfig);
    return appInitChannelConfig?.gatewayUrl || null;
  }

  /**
   * returns true if site has sign-in i.e. US initially
   * if false, we don't need to bother making accounts calls etc
   */
  hasAuthentication(): boolean {
    logger.debug('hasAuthentication()', appInitChannelConfig);
    return Boolean(appInitChannelConfig?.authentication);
  }

  /**
   * Get the homepage url for this channel
   */
  getHomePageUrl(): string {
    logger.debug(this);
    return `${this.getApiUrls().cmsBaseUrl}`;
  }

  getChannel(): string {
    return this.channel;
  }

  getLocale(): string {
    return this.locale;
  }

  getBaseResourceApiUrl(): string {
    return `${this.getApiUrls().cmsBaseUrl}/resourceapi/`;
  }

  getToken(): string {
    return this.token;
  }

  getCookieApiUrl(): string {
    return this.envConfig.getEnvConfig().cookieApiUrl;
  }

  getPdsDefaultEnv(): string {
    return this.envConfig.getEnvConfig().defaultPds;
  }
  getPdsApiUrl(env: string): string {
    return this.envConfig.getEnvConfig()[`${env}PdsApiUrl`];
  }

  getLiteratureApiUrl(): string {
    return this.envConfig.getEnvConfig().literatureApiUrl;
  }

  getLiteratureDownloadFinraUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadFinraUrl;
  }

  getLiteratureDownloadUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadUrl;
  }
  getLiteratureBaseApiUrl(): string {
    return this.envConfig.getEnvConfig().literatureBaseApiUrl;
  }

  getLiteratureOrderHistoryListUrl(): string {
    return this.envConfig.getEnvConfig().literatureOrderHistoryList;
  }

  getSearchAPIUrl(): string {
    return this.envConfig.getEnvConfig().searchUrl;
  }

  getSearchStatusAPIUrl(): string {
    return this.envConfig.getEnvConfig().searchStatusUrl;
  }

  getInsightsAPIUrl(): string {
    return this.envConfig.getEnvConfig().insightsUrl;
  }

  getEnvironment(): string {
    return this.envConfig.getEnvConfig().environment;
  }

  getCommentaryContentUrl(): string {
    return this.envConfig.getEnvConfig().commentaryContentUrl;
  }

  getFundCommentaryKey(): string {
    return this.envConfig.getEnvConfig().fundCommentaryKey;
  }

  /**
   * method to get the Accounts API
   */
  getAccountsApiUrl(): string {
    return this.envConfig.getEnvConfig().accountsApiUrl;
  }

  /**
   * method to get auth token from local storage
   */
  public getAuthToken = (): any => {
    let token: any;
    this.authState.subscribe((val: any) => (token = val.authToken));
    return token;
  };

  getLiteratureImageUrl(): string {
    return this.envConfig.getEnvConfig().literatureImageUrl;
  }

  getLiteratureDownloadBaseUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadBaseUrl;
  }

  getLiteratureDownloadZipUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadZipUrl;
  }
  /**
   * Load Api urls from App Config Service
   */
  getApiUrls(): ApiUrls {
    if (!this.apiUrls) {
      const envConfig = this.envConfig.getEnvConfig();
      if (envConfig) {
        const cmsPort = envConfig.apiPort !== undefined && envConfig.apiPort !== 80 && envConfig.apiPort !== 443 ? ':' + envConfig.apiPort : '';
        const origin = `${envConfig.apiScheme}://${envConfig.apiUrl}${cmsPort}`;

        // allows us to pull base urls from channel or environment config if they have been set
        let spaBaseUrl = (appInitChannelConfig ? appInitChannelConfig.spabaseurl : false) || envConfig.spabaseurl || '';

        let cmsBaseUrl = (appInitChannelConfig ? appInitChannelConfig.cmsbaseurl : false) || envConfig.cmsBaseUrl || `${this.documentRef.location.origin}${spaBaseUrl}`;

        if (envConfig.singleHost) {
          spaBaseUrl = cmsBaseUrl = `/site/${this.getChannel()}`;
        }

        this.apiUrls = {
          spaBaseUrl,
          endpoint: cmsBaseUrl + '/resourceapi',
          origin,
        };
        this.spaBaseUrl = spaBaseUrl;
      }
    }
    return this.apiUrls;
  }
  getBrConfiguration(): Configuration {
    return {
      ...this.getApiUrls(),
      httpClient: (
        ...[{ data: body, headers, method, url }]: Parameters<
          Configuration["httpClient"]
        >
      ) => {
        return this.httpClient
          .request<PageModel>(method, url, {
            body,
            headers: headers as Record<string, string | string[]>,
            responseType: 'json',
          })
          .pipe(map((data) => ({ data })))
          .toPromise();
      },
    };
  }

  getEnvConfig() {
    return this.envConfig.getEnvConfig();
  }
  /**
   * method to get the Accounts Domain
   */
  getAccountsDomain(): string {
    return this.envConfig.getEnvConfig().accountsDomain;
  }
  /**
   * method to get the Accounts Domain
   */
  getProfileDomain(): string {
    return this.envConfig.getEnvConfig().profileDomain;
  }

  getFTSearchApiUrl(): string {
    if (window.location.origin?.indexOf(Constant.enusHost) !== -1) {
      return this.envConfig.getEnvConfig().searchUrlENUS;
    } else if (window.location.origin?.indexOf(Constant.encaHost) !== -1) {
      return this.envConfig.getEnvConfig().searchUrlENCA;
    } else if (window.location.origin?.indexOf(Constant.enusHostPre) !== -1) {
      return this.envConfig.getEnvConfig().searchUrlPreENUS;
    } else if (window.location.origin?.indexOf(Constant.encaHostPre) !== -1) {
      return this.envConfig.getEnvConfig().searchUrlPreENCA;
    } else {
      return this.envConfig?.getEnvConfig()?.ftSearchApiUrl;
    }
  }

  getFTElasticSearchApiUrl(dataSource): string {
    if (window.location.origin?.indexOf(Constant.enusHost) !== -1) {
      return Constant.searchUrlProdENUS;
    } else if (window.location.origin?.indexOf(Constant.encaHost) !== -1) {
      return Constant.searchUrlProdENCA;
    } else if (window.location.origin?.indexOf(Constant.enusHostPre) !== -1) {
      return Constant.searchUrlPreENUS;
    } else if (window.location.origin?.indexOf(Constant.encaHostPre) !== -1) {
      return Constant.searchUrlPreENCA;
    } else if (dataSource === Constant.devHost || window.location.origin?.indexOf(Constant.devHost) !== -1) {
      return Constant.searchUrlDev;
    } else if (dataSource === Constant.stagingHost || window.location.origin?.indexOf(Constant.stagingHost) !== -1) {
      return Constant.searchUrlStaging;
    } else if (dataSource === Constant.betaHost || window.location.origin?.indexOf(Constant.betaHost) !== -1) {
      return Constant.searchUrlBeta;
    } else {
      return Constant.searchUrlProdENUS;
    }
  }

  isDebugPageModel(): boolean {
    return this.debugPageModel;
  }

  loadStyle(styleUrl: string, id: string) {
    const head = this.documentRef.getElementsByTagName("head")[0];

    const styleLink = this.documentRef.getElementById(id) as HTMLLinkElement;
    if (styleLink) {
      styleLink.href = styleUrl;
    } else {
      const style = this.documentRef.createElement("link");
      style.id = id;
      style.rel = "stylesheet";
      style.href = `${styleUrl}`;

      head.appendChild(style);
    }
  }
}
