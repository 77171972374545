<div class="container position-relative inline-images bg-lightgray">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="row">
        <div class="grid">
          <div class="grid-locked row">
            <ng-container *ngFor="let item of gridList; index as i">
              <ng-container *ngIf="item.description.content === ''">
                <img [src]="getImg(item.image)" [width]="item.width" [height]="item.height" />
              </ng-container>
              <div *ngIf="item.description.content !== ''" class="col-xs-12 col-sm-12 col-md-3">
                <img [src]="getImg(item.image)" [width]="item.width" [height]="item.height" />
                <div class="imgGridContent" [innerHtml]="item.description.content"></div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
