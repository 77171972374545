<div class="container position-relative bg-lightgray topBanner">
  <div class="section-inner-txt bg-lightgray">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>

      <div class="container bg-lightgray">
        <div class="article-billboard row">
          <div class="col-sm-10 col-sm-offset-2" [style.padding-left]="isCanadaSite ? '8px' : '10px'" style="padding-right: 0">
            <div
              class="jumbotron article-billboard-large"
              *ngIf="content?.articleBanner?.ftitopBannerSection?.imagelink || content?.articleBanner?.ftitopBannerSection?.imageCompound"
              style="background-image:url({{ getImageUrl(content?.articleBanner?.ftitopBannerSection) }})"
            >
              <div class="row">
                <div class="col-xs-12 col-sm-pull-2 col-sm-4 col-md-4 article-billboard-overlay color-black" [ngClass]="{ 'en-in': isCanadaSite }">
                  <h4 *ngIf="preTitle && preTitle !== null">{{ preTitle }}</h4>
                  <h2 *ngIf="bannerTitle && bannerTitle !== null">{{ bannerTitle }}</h2>
                  <div [ngClass]="{ 'en-in': isCanadaSite }" class="bannerText" *ngIf="bannerText?.value !== ''" [innerHtml]="bannerText?.value"></div>
                  <p class="authorAndDate">
                    <span
                      >{{ content?.articleBanner?.date | date: "MM.dd.yyyy":"UTC +5:30"
                      }}<span *ngIf="content?.articleBanner?.authors && content?.articleBanner?.authors !== 'No author'">&nbsp;-&nbsp;{{ content?.articleBanner?.authors }}</span></span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div
              class="jumbotron article-billboard-large"
              *ngIf="!content?.articleBanner?.ftitopBannerSection?.imagelink && !content?.articleBanner?.ftitopBannerSection?.imageCompound"
              style="background-image:url({{ noimageUrl }})"
            >
              <div class="row">
                <div class="col-xs-12 col-sm-pull-2 col-sm-4 col-md-4 article-billboard-overlay color-black" [ngClass]="{ 'en-in': isCanadaSite }">
                  <h4 *ngIf="preTitle && preTitle !== null">{{ preTitle }}</h4>
                  <h2 *ngIf="bannerTitle && bannerTitle !== null">{{ bannerTitle }}</h2>
                  <div [ngClass]="{ 'en-in': isCanadaSite }" class="bannerText" *ngIf="bannerText?.value !== ''" [innerHtml]="bannerText?.value"></div>
                  <p class="authorAndDate">
                    <span
                      >{{ content?.articleBanner?.date | date: "MM.dd.yyyy":"UTC +5:30"
                      }}<span *ngIf="content?.articleBanner?.authors && content?.articleBanner?.authors !== 'No author'">&nbsp;-&nbsp;{{ content?.articleBanner?.authors }}</span></span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clear" style="clear: both; height: 2em"></div>
        <div class="row grid-locked">
          <div class="col-sm-8">
            <div class="ftci-panel-our-insights-article">
              <div *ngFor="let item of twoColumnBlock">
                <img alt="image" *ngIf="item.imgUrl" [src]="item.imgUrl" [ngClass]="{ 'en-in': isCanadaSite }" />
                <div class="video-container" [ngClass]="{ 'en-in': isCanadaSite }" *ngIf="item.videoEmbedCode && !youtubeEmbed" [style.max-width]="isCanadaSite ? '100%' : '550px'">
                  <div id="player-container"></div>
                </div>
                <div *ngIf="item.videoEmbedCode && youtubeEmbed" [innerHtml]="youtubeEmbed"></div>
                <div [innerHtml]="item.content"></div>
                <div id="audio-player" *ngIf="item.contentType === 'fiduciary:EmbeddedAudio'"></div>
                <blockquote *ngIf="item.contentType === 'ftcore:Blockquote'">
                  <p>{{ item.quote }}</p>
                </blockquote>
                <div *ngIf="item.prominentText" [innerHTML]="item.prominentText.content" class="prominent"></div>
                <div class="highlightBox" *ngIf="item.contentType === 'fiduciary:FTIHighlightBox'">
                  <div *ngIf="item.paragraphBlock" [innerHtml]="item?.paragraphBlock?.content"></div>
                  <a class="btn-red-outline" *ngIf="item.button" [routerLink]="getInternalLink(item?.button?.linkCollection[0]?.document)">{{ item?.button?.linkCollection[0]?.displayText }}</a>
                  <a
                    class=""
                    *ngIf="item.linkCompound.length > 0 && item?.linkCompound[0]?.linkCollection[0]?.document"
                    [href]="getInternalLink(item?.linkCompound[0]?.linkCollection[0]?.document)"
                    target="_blank"
                    >{{ item?.linkCompound[0]?.linkCollection[0]?.displayText }}</a
                  >
                  <a
                    class=""
                    *ngIf="item.linkCompound.length > 0 && !item?.linkCompound[0]?.linkCollection[0]?.document"
                    [href]="getWidenAssetLink(item?.linkCompound[0]?.linkCollection[0])"
                    target="_blank"
                    >{{ item?.linkCompound[0]?.linkCollection[0]?.displayText }}</a
                  >
                  <ng-container *ngIf="item.imageGridCompound">
                    <ul class="row imgGridWrapper" *ngIf="item.imageGridCompound.imageGrid">
                      <li *ngFor="let gridItem of item.imageGridCompound.imageGrid" class="col-xs-12 col-sm-12 col-md-3 imgGrid">
                        <img [src]="getImageUrl(gridItem)" alt="image" />
                        <div class="imgGridContent" [innerHtml]="gridItem.description.content"></div>
                      </li>
                    </ul>
                  </ng-container>
                </div>
                <div class="mt-4 mb-4 imageWithLinkWrapper" *ngIf="item.contentType === 'fiduciary:FTIImageWithLink'">
                  <a class="" *ngIf="item.linkCompound && item?.linkCompound?.linkCollection[0]?.document" [routerLink]="getInternalLink(item?.linkCompound?.linkCollection[0]?.document)"
                    ><img [src]="getImageUrl(item)" alt="image"
                  /></a>
                  <a
                    class=""
                    *ngIf="item.linkCompound && item?.linkCompound?.linkCollection[0]?.contentType === 'ftcore:LinkURL'"
                    [href]="getWidenAssetLink(item?.linkCompound?.linkCollection[0])"
                    target="_blank"
                  >
                    <img [src]="getImageUrl(item)" alt="image" />
                  </a>
                </div>
                <div class="" *ngIf="item.contentType === 'fiduciary:FTIInlineImageCompound'" [style.text-align]="item.align ? item.align : 'left'">
                  <img [src]="getImageUrl(item)" alt="image" [width]="item.width" [height]="item.height" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="ftci-grid">
              <div class="grid" [ngStyle]="{ padding: isCanadaSite ? '0px' : '0 10px' }">
                <div class="grid-row" style="padding: 0">
                  <div class="single-column-social col-xs-12">
                    <div class="ftci-share-box" [ngStyle]="{ 'margin-top': isCanadaSite ? '-91px' : '-89px' }">
                      <div class="col-xs-3">
                        <h3>
                          <span *ngIf="isCanadaFrench">Partager</span>
                          <span *ngIf="!isCanadaFrench">Share</span>
                        </h3>
                      </div>
                      <div class="col-xs-9">
                        <div class="share-icons">
                          <span style="float: left" [style.padding]="isCanadaSite ? '0px 0px 0px 0px' : '0px 0px 0px 5px'">
                            <div aria-label="social icon" class="icon-first {{ isCanadaSite ? 'en-ca' : '' }}" [socialShare]="'in'">
                              <div
                                [style.background-image]="'url(https://franklintempletonprod.widen.net/content/ece02i3mt0/original/ftci-share-icons.png)'"
                                class="share-linkedin {{ isCanadaSite ? 'en-ca' : '' }}"
                              ></div>
                            </div>
                          </span>
                          <span style="float: left" [style.padding]="isCanadaSite ? '0px 0px 0px 0px' : '0px 0px 0px 5px'">
                            <div aria-label="social icon" class="icon-second {{ isCanadaSite ? 'en-ca' : '' }}" [socialShare]="'tw'">
                              <div
                                [style.background-image]="'url(https://franklintempletonprod.widen.net/content/ece02i3mt0/original/ftci-share-icons.png)'"
                                class="share-twitter {{ isCanadaSite ? 'en-ca' : '' }}"
                              ></div>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div class="grid-row" [style.padding]="isCanadaSite ? '22px 0 20px' : '20px 0 15px'" [style.margin-bottom]="isCanadaSite ? '0' : '20px'">
                  <div class="grid-item swa-wrp">
                    <a [routerLink]="contactUsLink" class="btn-red btn-swa-red">Speak with an Advisor </a>
                  </div>
                </div>
                <span *ngIf="articleprofiledata">
                  <div class="grid-row" style="padding: 0" *ngFor="let profile of articleprofiledata">
                    <div class="single-column-social col-xs-12" *ngIf="profile.image">
                      <div class="grid-item single brand-secondary col-xs-12 speaker" style="padding-bottom: 100%" [style.background-image]="'url(' + profile.image + ')'">
                        <p class="caption">{{ profile.title }}</p>
                      </div>
                    </div>
                    <div class="profileclear"></div>
                  </div>
                </span>
                <div class="clear" style="clear: both; height: 1em"></div>
                <!-- Hiding related posts temporarily as per FTCI review until fixed -->
                <!-- <div class="grid-row">
                  <div class="single-column col-xs-12">
                    <div class="grid-item single white col-xs-12" *ngIf="relatedListPosts.length > 0">
                      <div class="ftci-list-related-posts">
                        <h2>{{ relatedPostsLabel }}</h2>
                        <ul>
                          <li *ngFor="let topic of relatedListPosts">
                            <a [routerLink]="topic.relatedpageUrl">{{ topic.displayName }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!--<div class="row grid-locked">
          <div class="col-sm-12">
            <div class="ftci-grid">
              <div class="grid">
                <div class="grid-row row">
                  <div class="single-column col-sm-12 col-md-4" *ngIf="nextPostValue">
                    <div class="grid-item col-xs-12 single white">
                      <div class="ftci-card-related-insights">
                        <div class="col-xs-12">
                          <p class="category">{{ articleCategory }}</p>
                          <h3>{{ nextPostValue.displayName }}</h3>
                          <p class="about">{{ nextPostValue.date | date: "d.MM.y" }}</p>
                          <p></p>
                          <a aria-label="Read More" class="read-more">
                            <span class="sr-only">{{ nextPostValue.displayName }}</span
                            >{{ nextPostLabel }}</a
                          >
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <a aria-label="Read More" class="grid-item-link" [routerLink]="getPageUrl(nextPostValue.documentUrl)"></a>
                    </div>
                  </div>
                  <div class="single-column col-sm-12 col-md-4" *ngIf="previousPostValue">
                    <div class="grid-item col-xs-12 single white">
                      <div class="ftci-card-related-insights">
                        <div class="col-xs-12">
                          <p class="category">{{ articleCategory }}</p>
                          <h3>{{ previousPostValue.displayName }}</h3>
                          <p class="about">{{ previousPostValue.date | date: "d.MM.y" }}</p>
                          <p></p>
                          <a aria-label="Read More" class="read-more">
                            <span class="sr-only">{{ previousPostValue.displayName }}</span
                            >{{ previousPostLabel }}</a
                          >
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <a aria-label="previous post" class="grid-item-link" [routerLink]="getPageUrl(previousPostValue.documentUrl)"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</div>
