import { Component, HostBinding, Input, OnInit, SimpleChanges, OnChanges, ElementRef, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { Component as BrComponent, Menu, Page, Document, Content } from '@bloomreach/spa-sdk';
import { Location } from '@angular/common';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { Constant } from 'src/app/ft-constants/constant';
import { AppStateService } from '@services/app-state.service';
import { getMenus, getModelFromRef } from '@utils/pagemodel-utils';

@Component({
  selector: 'ft-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  isCustodyPage: boolean;
  showSubmenu: boolean = false;
  showMobileMenu: boolean = false;
  siteName: string;
  accountLoginSubMenu = Constant.accountLoginSubMenu;
  showSearch = false;
  currUrl: string;
  currPathFragment: string;
  isCanadaSite: boolean;
  locale = '';
  @HostBinding('class') class = 'navbar-nav';
  searchForm: any = {
    isActive: false,
    value: '',
    error: false,
  };
  menu: Content;
  menuModel: Menu;
  utilityNavItems: any;

  @ViewChild('dropdown', { static: false }) dropDown: ElementRef;
  constructor(private location: Location, private renderer: Renderer2, private el: ElementRef, private router: Router, private appStateService: AppStateService) {}
  ngAfterViewInit(): void {
    if (this.currUrl.indexOf('article-pages') != -1) {
      document.querySelector('li.nav-item.active')?.classList?.remove('active');
      const insightsNavElem = document.querySelector("a[href*='/insights']").parentElement;
      insightsNavElem.classList.add('active');
    }
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    this.locale = this.appStateService.getLocale();
    this.currUrl = this.location.path();
    const currentPath = this.location.path().split('/');
    this.currPathFragment = currentPath.reverse()[0];
    this.isCustodyPage = this.currPathFragment === Constant.isCustody ? true : false;
    this.siteName = Constant.basePath;
    this.isCanadaSite = Constant.isCanadaSite;
    this.menu = getModelFromRef(this.page, this.component, 'menu');
    this.menuModel = getMenus(this.page, this.component);
    this.utilityNavItems = this.getUtilityNav(this.page);
  }

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get isPreview() {
    return this.page?.isPreview();
  }

  get content() {
    return this.document?.getData();
  }

  get link() {
    return this.content?.link && this.page.getContent<Document>(this.content.link);
  }

  get topMenu() {
    return this.page?.getComponent('header-content')?.getComponent('header-top-menu')?.getModels()?.menu || {};
  }
  get topMenuChildren() {
    let len = 0;
    if (this?.topMenu?.siteMenuItems) {
      len = this.topMenu?.siteMenuItems?.length - 1 || 0;
      return this.topMenu?.siteMenuItems[len]?.childMenuItems;
    }
    return;
  }
  doSearch() {
    if (this?.searchForm?.value === '') {
      this.searchForm.error = true;
      this.showSearch = false;
      return;
    }
    this.showSearch = true;
    this.searchForm.isActive = false;
    this?.router?.navigate([this.siteName, Constant.searchPage], { queryParams: { queryText: this?.searchForm?.value } });
  }
  navigateToPage(event: any, url: any) {
    event.preventDefault();
    if (url === this.location.path()) {
      this.showMobileMenu = false;
    } else {
      this?.router?.navigate([url]);
    }
  }
  displaySubMenu(isClientLogin: any, url: any, event: any, siteData?: any) {
    const pageUrl = url || false;
    if (isClientLogin) {
      if (this.dropDown?.nativeElement?.classList.contains('active')) {
        this?.renderer?.removeClass(this?.dropDown?.nativeElement, 'active');
        return false;
      } else {
        this?.renderer?.addClass(this?.dropDown?.nativeElement, 'active');
      }
    } else {
      if (pageUrl) {
        event.preventDefault();
        if (pageUrl === this?.location?.path()) {
          this.showMobileMenu = false;
        } else {
          if(siteData && siteData?.type !== '' && siteData?.type === 'external') {
            window.open(siteData.href,'_self');
          } else {
            this?.router?.navigate([pageUrl]);
          }
        }
      }
    }
  }

  getUtilityNav(page: Page) {
    var utilityNavItems = [];
    const utilityNavKey = this.getUtilityNavKey(page);
    utilityNavItems = page.toJSON().page[utilityNavKey].data.siteMenuItems;

    return utilityNavItems;
  }

  getUtilityNavKey(page: Page) {
    var utilityNavKey = "";
    const pageAsJson = page.toJSON().page;

    for (var key of Object.keys(pageAsJson)) {
      if (pageAsJson[key].type == "menu" && pageAsJson[key].data.name == "utility-nav") {
        utilityNavKey = key;
      }
    }

    return utilityNavKey;
  }

  /**
   * Check if the passed navigation item has the "isClientLogin = true" property set or not.
   *
   * @param navItem The navigation item to check.
   * @returns {boolean} - True if navItem has the "isClientLogin = true" property, otherwise false.
   */
  isClientLogin(navItem): boolean {
    let clientLogin:boolean = false;

    if (navItem.properties["hst:parameternames"]) {
      for (var i=0; i < navItem.properties["hst:parameternames"].length; i++) {
        if (navItem.properties["hst:parameternames"][i] === "isClientLogin" && navItem.properties["hst:parametervalues"][i] === "true") {
          clientLogin = true;
        }
      }
    }

    return clientLogin;
  }
}
