<div class="container position-relative jump-links bg-lightgray">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="row">
        <div class="grid">
          <div class="grid-locked">
            <div class="panelbody">
              <div class="row top-row">
                <ng-container *ngFor="let item of jumpList; index as i">
                  <div class="col-sm-6 jump-link-item" [ngClass]="[i == 0 || i == 4 ? 'col-md-3' : 'col-md-2']">
                    <p>
                      <a routerLink="/new-fiduciary-trust-online-experience" [fragment]="item.jumpLinkId">
                        <img [src]="getImg(item.image)" class="img-responsive" />
                      </a>
                    </p>
                    <div class="p-2 text-center hidden-md hidden-lg">
                      <h3 class="mb-2">{{ item.title }}</h3>
                      <p>{{ item.description }}</p>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="bottom-row row text-center hidden-xs hidden-sm mt-3">
                <ng-container *ngFor="let item of jumpList; index as i">
                  <div class="my-columns" [ngClass]="[i == 0 || i == 4 ? 'col-md-3' : 'col-md-2']">
                    <h3 class="mb-2">{{ item.title }}</h3>
                    <p>{{ item.description }}</p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
