<ng-template [ngIf]="menu">
  <div class="container primary-nav mb-2" [ngClass]="isCanadaSite ? 'mt-1' : 'mt-2'">
    <div class="logo-wrap">
      <a [routerLink]="siteName" aria-label="Fiduciary Trust International Home">
        <div class="logo float-start" [ngClass]="{ 'logo-canada': isCanadaSite }"></div>
      </a>
    </div>
    <nav class="navbar navbar-expand-lg navbar-light" [ngClass]="{ 'en-ca': isCanadaSite }">
      <ng-container [brManageMenuButton]="menu"></ng-container>
      <ul *ngIf="!isCustodyPage" class="nav navbar-nav me-auto hidden-xs hidden-sm main-navigation" [ngClass]="{ 'has-edit-button': page?.isPreview() }">
        <li class="nav-item" *ngFor="let menuItem of menuModel.siteMenuItems" [ngClass]="{ active: menuItem.links.site?.href === currUrl }">
          <a *ngIf="!menuItem.links.site">
            {{ menuItem.name }}
          </a>
          <a *ngIf="menuItem.links?.site | isExternalLink" [href]="menuItem.links.site?.href">
            {{ menuItem.name }}
          </a>
          <a *ngIf="menuItem.links?.site | isInternalLink" [routerLink]="page.getUrl(menuItem.links.site?.href)">
            {{ menuItem.name }}
          </a>

          <ng-container *ngIf="menuItem.childMenuItems?.length">
            <ul class="mainmenu-submenu-ul">
              <li class="mainmenu-submenu-li" *ngFor="let childMenuItem of menuItem.childMenuItems">
                <a *ngIf="!childMenuItem.links?.site">
                  {{ childMenuItem.name }}
                </a>
                <a *ngIf="childMenuItem.links?.site | isExternalLink" [href]="childMenuItem.links.site?.href">
                  {{ childMenuItem.name }}
                </a>
                <a *ngIf="childMenuItem.links?.site | isInternalLink" [routerLink]="page.getUrl(childMenuItem.links.site?.href)">
                  {{ childMenuItem.name }}
                </a>
              </li>
            </ul>
          </ng-container>
        </li>
      </ul>
      <div *ngIf="!isCustodyPage" class="search-form d-flex">
        <button class="search-button" (click)="searchForm.isActive = searchForm.isActive ? false : true" aria-label="Search Button"></button>
        <form class="search-form-inner" (ngSubmit)="doSearch()" *ngIf="searchForm.isActive">
          <input
            type="search"
            placeholder="{{ locale === 'fr-ca' ? 'Rechercher  Fiduciary Trust' : 'Search Fiduciary Trust' }}"
            [(ngModel)]="searchForm.value"
            name="value"
            [ngClass]="{ error: searchForm.error }"
          />
          <input type="submit" class="d-none" />
        </form>
      </div>
      <div class="hidden-md hidden-lg">
        <div class="mobile-menu" role="banner">
          <div class="dropdown" [ngClass]="{ open: showMobileMenu }">
            <button
              type="button"
              (click)="showMobileMenu = !showMobileMenu"
              class="btn btn-default dropdown-toggle menu-burger"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              aria-label="search"
            ></button>
            <div class="dropdown-backdrop"></div>
            <div class="dropdown-menu" *ngIf="showMobileMenu" #dropdown>
              <ul class="page-1" role="menu">
                <ng-container *ngIf="!isCustodyPage">
                  <ng-container *ngFor="let menuItem of menuModel.siteMenuItems">
                    <li class="primary" role="presentation" *ngIf="menuItem.links.site?.href" (click)="navigateToPage($event, page.getUrl(menuItem.links.site?.href))">
                      <a [ngClass]="{ active: menuItem.selected }">
                        {{ menuItem.name }}
                      </a>
                    </li>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!isCustodyPage">
                  <li
                    (click)="displaySubMenu(topMenuItem.parameters?.isClientLogin, page.getUrl(topMenuItem.links.site?.href), $event, topMenuItem.links?.site)"
                    (keydown.enter)="displaySubMenu(topMenuItem.parameters?.isClientLogin, page.getUrl(topMenuItem.links.site?.href), $event, topMenuItem.links?.site)"
                    class="utility"
                    tabindex="0"
                    role="presentation"
                    *ngFor="let topMenuItem of topMenu.siteMenuItems"
                    [ngClass]="{ 'client-login': topMenuItem.parameters?.isClientLogin }"
                  >
                    <a class="no-default-focus">
                      {{ topMenuItem.name }}
                    </a>
                  </li>
                </ng-container>
                <ng-container *ngIf="isCustodyPage">
                  <li
                    (click)="
                      displaySubMenu(
                        topMenu.siteMenuItems[topMenu.siteMenuItems.length - 1].parameters?.isClientLogin,
                        page.getUrl(topMenu.siteMenuItems[topMenu.siteMenuItems.length - 1].links.site?.href),
                        $event
                      )
                    "
                    (keydown.enter)="
                      displaySubMenu(
                        topMenu.siteMenuItems[topMenu.siteMenuItems.length - 1].parameters?.isClientLogin,
                        page.getUrl(topMenu.siteMenuItems[topMenu.siteMenuItems.length - 1].links.site?.href),
                        $event
                      )
                    "
                    tabindex="0"
                    class="utility client-login"
                    role="presentation"
                  >
                    <a class="no-default-focus">
                      {{ topMenu.siteMenuItems[topMenu.siteMenuItems.length - 1].name }}
                    </a>
                  </li>
                </ng-container>
                <ng-container *ngIf="isCanadaSite && utilityNavItems">
                  <ng-container *ngFor="let navItem of utilityNavItems">
                    <ng-container *ngIf="isClientLogin(navItem)">
                      <li *ngFor="let childNavItem of navItem.childMenuItems" class="primary" role="presentation">
                        <a [href]="childNavItem.links.site.href" target="_blank">
                          {{ childNavItem.name }}
                        </a>
                      </li>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="isCanadaSite && utilityNavItems">
                  <ng-container *ngFor="let navItem of utilityNavItems">
                    <li *ngIf="navItem.name === 'Français' || navItem.name === 'English'" class="primary" role="presentation">
                      <a [href]="navItem.links.site?.href">
                        {{ navItem.name }}
                      </a>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
              <ul class="page-2" role="menu">
                <li class="primary return" role="presentation" (click)="displaySubMenu(true, false, $event)">
                  <a role="menuitem" href> <span class="glyphicon glyphicon-menu-left"></span>Main Menu</a>
                </li>
                <ng-container *ngIf="!isCustodyPage">
                  <li class="utility" role="presentation" *ngFor="let childItem of topMenuChildren">
                    <a [href]="childItem.links.site?.href" class="no-default-focus" aria-current="page" role="menuitem">{{ childItem.name }}</a>
                  </li>
                </ng-container>
                <li *ngIf="isCustodyPage" class="utility" role="presentation">
                  <a [href]="topMenuChildren[0].links.site?.href" class="no-default-focus" aria-current="page" role="menuitem"> {{ accountLoginSubMenu }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</ng-template>
