import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PageConfig, PageContainerService } from '@pages/services/page-container.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { isTemplateMiddle } from 'typescript';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  data: any;
  initialNavigationComplete = false;
  layout = '';
  constructor(private titleService: Title, private metaService: Meta, private pageContainerService: PageContainerService, private router: Router, private gtmService: GoogleTagManagerService) {
    this?.pageContainerService?.page$.subscribe((pageConfig: PageConfig) => {
      this.data = pageConfig?.page;
      this.initialNavigationComplete = true;
      this.layout = this.data?.['model'].page?._meta?.params?.layout;
    });
    let fav = document.querySelector("link[rel='icon']");
    if (location.host.indexOf('staging') != -1 || location.host.indexOf('dev') != -1 || location.host.indexOf('localhost') != -1) {
      fav['href'] = 'fiduciarytrust/assets/images/favicon.ico?' + Date.now();
    } else {
      fav['href'] = 'assets/images/favicon.ico?' + Date.now();
    }
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        /* const gtmTag = {
          event: 'page',
          pageName: item.url
        }; */
      }
    });
  }
}
