import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { AppStateService } from '@services/app-state.service';
import { Constant } from '../../../ft-constants/constant';
@Component({
  selector: 'ft-top-banner',
  templateUrl: './billboard.component.html',
  styleUrls: ['./billboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BillboardComponent implements OnInit {
  /**
   * Bloomreach SDK inputs
   */
  @Input() component!: BrComponent;
  @Input() page: Page;

  topbannerImage: any;
  topbannerText: any;

  imageWithContentList = [];
  opportunitieslink: any;
  opportunitylable: any;

  firstArticleInList: any;

  content: any;
  document: any;
  bannerContent: any;
  bannerimage: string;
  dynamicLink: string;
  links: any;
  isCanadaSite: boolean;

  locale = '';
  constructor(private appStateService: AppStateService) {}

  /**
   * Ng Init method
   */
  ngOnInit(): void {
    this.locale = this.appStateService.getLocale();
    this.opportunitieslink = Constant.opportunitiesLink;
    this.opportunitylable = Constant.opportunitiesLable;
    // Init content
    this.document = this.getDocument();
    this.content = this.getContent();
    this.bannerContent = this.getBannerContent();
    this.bannerimage = this.getBannerImage();
    //this.isCanadaSite = Constant.isCanadaSite;
    this.isCanadaSite = window.location.pathname === Constant.canadaEngChannel || window.location.pathname === Constant.canadaFrChannel;
    //
  }

  /**
   * Read component document
   * @returns document from page model
   */
  getDocument() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  /**
   * Read component content
   * @returns content from page model
   */
  getContent() {
    const listComp = this.page.getComponent('main-content').getComponent('FTI-list-component');
    if (listComp) {
      const items = listComp.getModels().pageable.items;
      this.firstArticleInList = this.getFirstArticle(items);
    } else {
      this.firstArticleInList = null;
    }
    return this.document?.getData();
  }

  /**
   * Read content of the first article from list component.
   * @param items
   * @returns
   */
  getFirstArticle(items) {
    const item = this.page?.getContent(items[0]);
    const data = item?.getData();
    let author = data?.articleBanner?.authors;
    data.articleBanner.authors = author && author.toLowerCase().includes('no author') ? null : author;
    this.dynamicLink = item.getUrl();
    return data.articleBanner;
  }

  /**
   * If this banner contains an article list - it will return the dynamic banner
   * else the static banner content
   */
  getBannerContent() {
    const banner = this.firstArticleInList ? this.firstArticleInList : this.content;
    let bannerContent = banner.ftitopBannerSection;
    // Lets clean up the fields for the template in here
    // If title is set use it else fall back to image title
    bannerContent.title = bannerContent.title || bannerContent.imageCompound?.title;
    // authors
    bannerContent.authors = banner.authors;
    // date
    bannerContent.date = banner.date;
    bannerContent.additionalContent = bannerContent.additionalContent ? bannerContent.additionalContent.value : null;
    return bannerContent;
  }

  /**
   * Reads the banner image from the static content or the first article in the list.
   * @returns bannerimage - string
   */
  getBannerImage() {
    // Read image dynamically
    const data = this.bannerContent;
    let imageUrl = '';
    if (data?.imagelink) {
      imageUrl = this.page?.getContent(data.imagelink)?.getUrl() || '';
    } else if (data?.imageCompound?.contentType === 'ftcore:ImageCompound' && data?.imageCompound?.widenAsset) {
      imageUrl = JSON.parse(data?.imageCompound?.widenAsset).url ? JSON.parse(data?.imageCompound?.widenAsset).url : '';
    }

    this.topbannerImage = imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') + Constant.optimisedBannerImg : '';
    return this.topbannerImage;
  }

  /**
   * Read page url from content ref
   * @param ref
   * @returns
   */
  getPageUrl(ref: any) {
    const pageUrl = this.page?.getContent(ref)?.getUrl();
    return pageUrl;
  }

  get insideCardBlock() {
    return this.content?.insightsCardBlock || {};
  }
  get insideBlockTitle() {
    return this.insideCardBlock?.titleBlock?.title || '';
  }
  get insideBlockContent() {
    return this.insideCardBlock?.html?.value || null;
  }
  get insideBlockMenuContent() {
    return this.insideCardBlock?.linkCompound || {};
  }
  get insideBlockBoolean() {
    return this.insideCardBlock?.booleanRadioGroup || false;
  }

  get buttonName() {
    return this.insideCardBlock?.string || null;
  }

  get item() {
    return this.document?.getData() || {};
  }

  get imageWithContent() {
    this.imageWithContentList = this.bannerContent || [];
    this.getImages();
    return this.imageWithContentList;
  }

  /**
   *
   */
  getImages() {
    const data = this.bannerContent || [];
    this.imageWithContentList = data;
  }

  get bannerText() {
    const data = this.bannerContent || {};
    this.topbannerText = data;
    return this.topbannerText;
  }

  get title() {
    return this?.page?.getTitle() || {};
  }

  get isPreview() {
    return this?.page?.isPreview() || {};
  }

  get bannerLinks() {
    return this.bannerContent?.linkCompound || [];
  }

  get readMoreLink() {
    return this.bannerContent?.link || null;
  }
  get preTitle() {
    return this.bannerContent?.preTitle || null;
  }
  get bannerTitle() {
    return this.bannerContent?.imageCompound?.title || null;
  }
  get bannerButtons() {
    return this.bannerContent?.button || null;
  }
}
