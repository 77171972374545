import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DebugPageModelComponent } from "./debug-page-model.component";
import { TYPE_CONTAINER_ITEM_UNDEFINED } from "@bloomreach/spa-sdk";
import { NgJsonEditorModule } from "ang-jsoneditor";

@NgModule({
  declarations: [DebugPageModelComponent],
  imports: [CommonModule, NgJsonEditorModule],
})
export class DebugModule {
  // getComponentMapping(): ComponentMapping {
  //   const mapping: ComponentMapping = {};
  //   mapping[TYPE_CONTAINER_ITEM_UNDEFINED] = DebugPageModelComponent;
  //   return mapping;
  // }
}
