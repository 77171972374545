<div class="container position-relative bg-lightgray topBanner">
  <div class="section-inner-txt bg-lightgray">
    <div class="container position-relative">
      <div class="section-inner-txt" *ngFor="let content of contentBlock">
        <div class="row award-list" [ngClass]="{'en-ca':isCanadaSite}">
          <h6 *ngIf="content.year && !isAdvisoryPage">{{ content.year }}</h6>

          <div class="col-sm-12">
            <div class="col-md-12">
              <ul *ngIf="content.data">
                <li *ngFor="let item of content.data">
                  <div class="row">
                    <div class="col-xs-12" [ngStyle]="{'padding':isCanadaSite?'0 12px':'0 10px'}" [ngClass]="isCanadaSite?'col-sm-1':'col-sm-2'">
                      <img *ngIf="item?.imagelink || item?.imageCompound?.widenAsset" src="{{ getImageUrl(item) }}" alt="image" loading="lazy" />

                      <img *ngIf="!item?.imagelink && !item?.imageCompound?.widenAsset" src="/{{ channelName }}/assets/images/no-image-found.jpg" alt="image" loading="lazy" />
                    </div>
                    <div class="col-xs-12" [ngClass]="isCanadaSite?'col-sm-11':'col-sm-10'">
                      <div [innerHTML]="item?.html?.value"></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
