<div class="container" *ngIf="item?.name != 'footer-content'; else footerContent">
  <div class="section-inner-txt">
    <div *ngIf="document" class="section-wrp">
      <h2>{{ title }}</h2>
      <div [innerHTML]="item.content?.value"></div>
    </div>
  </div>
</div>

<ng-template #footerContent>
  <div *ngIf="document" class="footer-section position-relative" [ngClass]="{ 'has-edit-button': page?.isPreview() }">
    <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ContentBlocks-document"
      folderTemplateQuery="new-ContentBlocks-folder"
      parameter="document"
      [relative]="false"
      root=""
    >
    </ng-container>
    <div class="row">
      <div class="col-md-10">
        <div [innerHTML]="item.content?.value"></div>
      </div>

      <div class="col-md-2 text-end" *ngIf="!isCanadaSite">
        <ul class="social-links list-inline">
          <li class="list-inline-item">
            <a href="https://www.linkedin.com/company/fiduciary-trust-company" target="_blank">
              <img src="binaries/content/gallery/global/fiduciary-trust/images/ftci-social-linkedin.png" alt="linkedin" />
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://twitter.com/FiduciaryTrust" target="_blank">
              <img src="binaries/content/gallery/global/fiduciary-trust/images/twitter-grey-social.png" alt="twitter" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
