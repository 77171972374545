declare var appInitChannelConfig;
export class Constant {
  public static isAwards: string = 'awards';
  public static isHistory: string = '90th-anniversary';
  public static isWhoWeArePage: string = 'who-we-are';
  public static isCustody: string = 'custody';
  public static isInsights: string = 'insights';
  public static isProfileAndTabsLayout: string = 'profile-and-tabs';
  public static isSmallTabsLayout: string = 'small-tabs';
  public static isCharitableGivingSubmenu: string = 'Charitable Giving';
  public static accountLoginSubMenu: string = 'Account Login';
  public static marketoFormLoadUrl: string = '//pages.to.franklintempleton.com';
  //public static marketoFormLoadUrl: string = '//446-ACY-111.mktoweb.com';
  public static marketoFormMchinkId: string = '848-IAP-939';
  //public static marketoFormMchinkId: string = '446-ACY-111';
  public static opportunitiesLink: string = 'https://franklintempleton.wd5.myworkdayjobs.com/Primary-External-1';
  public static opportunitiesLable: string = 'VIEW OPPORTUNITIES';
  public static previousPost: string = 'PREVIOUS POST';
  public static nextPost: string = 'NEXT POST';
  public static relatedPost: string = 'Related Posts';
  public static channelName: string = '/fiduciarytrust';
  public static canadaEngChannel: string = '/fiduciarytrust-en-ca';
  public static canadaFrChannel: string = '/fiduciarytrust-fr-ca';
  public static contactUsPage: string = 'contact-us';
  public static contactUsLink: string = '/contact-us';
  public static searchPage: string = 'search';
  public static insightsPage: string = 'insights';
  public static emailSubscriptionPage: string = 'email-subscription-form';
  public static outlookContactPage: string = 'outlook2017-contact-form';
  public static ftSearchUrlProd = 'https://search2.franklintempleton.com/SearchController/api/controller/search';
  public static ftSearchUrlStatusProd = 'https://search.franklintempleton.com/SearchController/api/controller/status';
  public static ftSearchUrlDev = 'https://dev-gateway.ftsites.com/search/SearchController/api/controller/search';
  public static ftSearchUrlStage = 'https://dev-gateway.ftsites.com/search/staging/SearchController/api/controller/search';
  public static ftSearchUrlStatusDev = 'https://search.corp.frk.com/SearchController/api/controller/status';
  public static ftSearchUrlError = 'https://searchError.corp.frk.com/SearchController/api/controller/search';
  public static isTaxGuideLead: string = 'tax-guide-lead-form';
  public static notAvailable: string = 'content-not-available';
  public static whatWeDo: string = 'what-we-do';
  public static advisoryBoard: string = 'advisoryboard';
  public static noImage: string = Constant.channelName + '/assets/images/no-image-found-list.jpg';
  public static optimisedBannerImg: string = '?w=1066';
  public static optimisedBannerImgWithCenterAndCrop: string = '?w=640&h=640&keep=c&crop=yes';
  public static optimisedProfileImg: string = '?w=640';
  public static optimisedPrimaryLandingImg: string = '?w=470';
  public static basePath = appInitChannelConfig.spaBaseUrl;
  public static googleMapKey = 'AIzaSyDikptsaOgQ15vz8OcyLApNoA18WW_gGjk';
  public static isCanadaSite = window.location.host.includes('.ca') || window.location.href.includes('en-ca') || window.location.href.includes('fr-ca');
  public static frcaLocale: string = 'fr-ca';
  public static encaLocale: string = 'en-ca';
  public static enusLocale: string = 'en-us';
  public static enusHost: string = 'www.fiduciarytrust.com';
  public static encaHost: string = 'www.fiduciarytrust.ca';
  public static enusHostPre: string = 'pre.fiduciarytrust.com';
  public static encaHostPre: string = 'pre.fiduciarytrust.ca';
  public static encaHostDev: string = 'pre.fiduciarytrust.ca';
  public static encaHostStaging: string = 'pre.fiduciarytrust.ca';
  public static encaHostBeta: string = 'pre.fiduciarytrust.ca';
  public static devHost: string = 'dev';
  public static stagingHost: string = 'staging';
  public static betaHost: string = 'beta';
  public static prodEnv: string = 'prod';
  public static searchUrlDev: string = 'https://dev.ftsites.com/api/search';
  public static searchUrlStaging: string = 'https://staging.ftsites.com/api/search';
  public static searchUrlBeta: string = 'https://beta.ftsites.com/api/search';
  public static prodSearchApiUrl: string = 'https://www.fiduciarytrust.com/api/search';
  public static searchUrlProdENUS: string = 'https://www.fiduciarytrust.com/api/search';
  public static searchUrlPreENUS: string = 'https://pre.fiduciarytrust.com/api/search';
  public static searchUrlProdENCA: string = 'https://www.fiduciarytrust.ca/api/search';
  public static searchUrlPreENCA: string = 'https://pre.fiduciarytrust.ca/api/search';
  public static SITE_SEARCH_PAGES: string = 'pages';
}
