import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Location } from '@angular/common';
import { LeavingSiteModalComponent } from '@shared/leaving-site-modal/leaving-site-modal.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from 'src/app/ft-constants/constant';

@Component({
  selector: 'ft-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabContentComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  layout: string;
  tabContentList = [];
  isCustodyPage: boolean;
  profileAndTabsLayout: string = Constant.isProfileAndTabsLayout;
  smallTabsLayout: string = Constant.isSmallTabsLayout;
  constructor(private location: Location, public modalService: NgbModal) {}

  ngOnInit(): void {
    this.layout = this.page?.getComponent().getParameters().layout;
    const currentPath = this.location.path().split('/');
    const pathFragment = currentPath.reverse()[0];
    this.isCustodyPage = pathFragment === Constant.isCustody ? true : false;
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }
  get tabTitle() {
    return this.content?.tabTitle;
  }
  get tabData() {
    return this.content?.tabContent || [];
  }
  getInternalLink(document: any) {
    if (document && document !== null && document !== undefined) {
      return this.page?.getContent(document).getData().links?.site?.href;
    }
    return '';
  }
  getPdf(elem: any) {
    if (elem.widenDocument) {
      return JSON.parse(elem.widenDocument).url;
    }
    return '';
  }

  getImageUrl(elem: any) {
    let imageUrl = '';
    if (elem.$ref) {
      imageUrl = this.page?.getContent(elem)?.getData()?.links?.site?.href;
    } else if (elem.contentType === 'ftcore:ImageCompound' && elem.widenAsset) {
      imageUrl = JSON.parse(elem.widenAsset).url ? JSON.parse(elem.widenAsset).url : '';
    }
    return imageUrl;
  }
  openPopup(link: any, ev: any) {
    ev.preventDefault();
    const modalRef = this.modalService.open(LeavingSiteModalComponent);
    modalRef.componentInstance.url = link;
  }
}
