<div class="container position-relative jump-link-content bg-lightgray">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="row">
        <div class="grid">
          <div class="grid-locked">
            <ng-container *ngFor="let item of jumpList; index as i">
              <div [id]="item.jumpLinkID" class="my-panel">
                <div class="my-panel-body" style="padding-left: 3%; padding-right: 3%">
                  <h1>{{ item.mainTitle }}</h1>
                  <div class="row">
                    <div class="col-md-6">
                      <h3 class="">{{ item.leftContentTitle }}</h3>
                      <ul>
                        <li *ngFor="let itemData of item.leftContentDesc">{{ itemData }}</li>
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <p><img [src]="getImg(item.rightImage)" class="img-responsive" /></p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
