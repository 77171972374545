import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';

@Component({
  selector: 'ft-simple-grid',
  templateUrl: './simple-grid.component.html',
  styleUrls: ['./simple-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SimpleGridComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  constructor() {}

  ngOnInit(): void {}
}
