import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DataSearchService, FtSearchResponse } from 'src/app/services/data-search.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { Constant } from 'src/app/ft-constants/constant';
import { LOCATION } from "@ng-web-apis/common";

@Component({
  selector: 'ft-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  loading = false;
  dataSource: string;
  searchResults;
  isDataFound: boolean = false;
  searchComponentDestroyed$: Subject<boolean> = new Subject();
  queryText;
  @ViewChild('result', { static: true }) resultFound: ElementRef;
  @ViewChild('noResult', { static: true }) noResultFound: ElementRef;
  locale = '';
  noResultText: string;
  searchHelpText: string;
  genericSearchTermText: string;
  removingFilterText: string;
  typographicalMistakes: string;
  pageUrl: string;
  loaderPath:string;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataSearch: DataSearchService, private appStateService: AppStateService, @Inject(LOCATION) readonly locationRef: Location) {}

  ngOnInit(): void {
    this.locale = this.appStateService.getLocale();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.dataSource = this.appStateService.getEnvironment();
      //this.dataSource = "dev";
      if (params['dataSource'] === 'error') {
        this.dataSource = 'error';
      }
      this.queryText = params['queryText'];
    });

    this.callSearch(this.queryText);
    if (this.locale === Constant.frcaLocale) {
      this.noResultText = 'Aucun résultat pour';
      this.searchHelpText = 'Aide à la recherché';
      this.genericSearchTermText = 'Utiliser un terme de recherche plus générique';
      this.removingFilterText = 'Essayer d\'enlever les filtres';
      this.typographicalMistakes = 'Vérifier que la recherche ne comporte pas d\'erreur typographique';
    } else {
      this.noResultText = 'No Results for';
      this.searchHelpText = 'Search Help';
      this.genericSearchTermText = 'Use a more generic search term';
      this.removingFilterText = 'Try removing filters';
      this.typographicalMistakes = 'Check your search for typographical mistakes';
    }
  }
  sanitizeContent(content) {
    return content.replaceAll('fiduciary:FiduciaryArticlePage', ' ');
  }
  callSearch(query) {
    this.loaderPath = (this.dataSource !== Constant.prodEnv) ? this.locationRef.origin + Constant.channelName : '';
    this.loading = true;
    this.dataSearch
      .performSearch(query, this.dataSource)
      .pipe(takeUntil(this.searchComponentDestroyed$))
      .subscribe(({ results }) => {
        this.searchResults = results?.find((resultObject: FtSearchResponse) => resultObject?.name === Constant.SITE_SEARCH_PAGES)?.response || [];
        //this.searchResults.replaceAll("fiduciary:FiduciaryArticlePage","");
        this.isDataFound = Object.keys(this.searchResults).length > 0 && this.searchResults?.hits?.hits?.length > 0 ? true : false;
        this.loading = false;
        if (this.isDataFound) {
          document.getElementById('queryInput').focus();
        }
      });
  }
  navigateByUrl(res) {
    let url = '';
    if (res._source.reference) {
      url = '/' + res._source.reference.split('.com/')[1];
    }
    this.router.navigateByUrl(url);
  }
  setPageUrl(res) {
    this.pageUrl = '';
    if (res._source.reference) {
      if (res._source.reference.indexOf('.com') > 0) {
        this.pageUrl = res._source.reference.split('.com')[1];
      } else if (res._source.reference.indexOf('.ca') > 0) {
        this.pageUrl = res._source.reference.split('.ca')[1];
      }
    }

    if (this.dataSource === Constant.betaHost) {
      if (this.pageUrl.includes('/en-ca')) {
        this.pageUrl = this.pageUrl.replace('/en-ca', Constant.canadaEngChannel);
      } else if (this.pageUrl.includes('/fr-ca')) {
        this.pageUrl = this.pageUrl.replace('/fr-ca', Constant.canadaFrChannel);
      } else {
        this.pageUrl = Constant.channelName + '/' + this.pageUrl;
      }
    }

    return this.pageUrl;
  }
  ngOnDestroy() {
    // unsubscribe from Observables
    this.searchComponentDestroyed$.next(true);
    this.searchComponentDestroyed$.complete();
  }
}
