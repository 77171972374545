<div class="container position-relative bg-lightgray contentBlock" [ngClass]="{'noBgColor':noBgColor}">
  <div class="section-inner-txt" [ngClass]="{'noBgColor':noBgColor}" >
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div *ngIf="document" class="section-wrp">
        <!-- <h2 *ngIf="!isHistoryPage && !isAdvisoryPage" class="heading col-md-12">{{ content.displayName }}</h2> -->

        <div *ngFor="let content of contentBlock" class="growth-content col-md-12">
          <div *ngFor="let item of content?.items">
            <img alt="image" *ngIf="item.imageUrl != '' && ! item.imageProperties" [src]="item.imageUrl" width="693" height="313" />
            <img alt="image" *ngIf="item.imageUrl != '' && item.imageProperties" [src]="item.imageUrl" [style]="{width:item?.imageProperties?.imageSize}" />
            <div *ngIf="content.sectionId!=='flagText'" [innerHTML]="item.content" [ngClass]="{ 'responsive-p': isHistoryPage || isAwardsPage || isWhoWeArePage || isAdvisoryPage }"></div>
            <div *ngIf="item.prominentText && content.sectionId!=='flagText'" [innerHTML]="item.prominentText.content" class="prominent"></div>
            <ng-container *ngIf="isFinancialMonthPlanning && item.buttons && item.buttons.length > 0">
              <div class="col-sm-4 mt-3 mb-3">
                <a [routerLink]="getPageUrl(item?.buttons[0]?.linkCompound?.linkCollection[0]?.document)" class="btn-red">{{ item?.buttons[0]?.linkCompound?.linkCollection[0]?.displayText }}</a>
              </div>
            </ng-container>
            <div *ngIf="content.sectionId==='flagText'" [innerHTML]="item.content" class="flagtext"></div>
          </div>
        </div>
        <div class="growth-content col-md-12 white" *ngIf="isWomenPage">
          <div style="background: white; padding: 0 15px 15px">
            <h5>
              <strong>Live Webinar: Insights for Women - Gaining Financial Confidence<br /><br /></strong>
            </h5>
            <h5>
              <strong><a href="https://event.on24.com/wcc/r/3036729/55E2BA5594FF90763983D1C57A893146" target="_blank">WATCH NOW</a></strong>
            </h5>
            <p>
              In this panel discussion, three of our top female wealth advisors explore the best practices women are using to successfully confront financial challenges, view wealth holistically and
              achieve their lifelong goals.
            </p>
            <div style="width: 400px">
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="33%" style="text-align: center">
                      <img
                        src="https://franklintempletonprod.widen.net/content/gsycvypnol/original/Krouner_Linda_640x640_color.jpg"
                        loading="lazy"
                        width="100"
                        height="100"
                        style="margin-bottom: 0"
                      /><span>&nbsp;<span>&nbsp;</span></span>
                    </td>
                    <td width="33%" style="text-align: center">
                      <img
                        src="https://franklintempletonprod.widen.net/content/ngl68lmro5/original/McCarthy_Jennifer_640x640.jpg"
                        alt=""
                        width="100"
                        height="100"
                        loading="lazy"
                        class=""
                        style="margin-bottom: 0"
                      />
                    </td>
                    <td width="33%" style="text-align: center">
                      <img src="https://franklintempletonprod.widen.net/content/upbrkydwp0/original/amy-ko-640.jpg" alt="" width="100" height="100" loading="lazy" class="" style="margin-bottom: 0" />
                    </td>
                  </tr>
                  <tr>
                    <td width="33%" style="text-align: center; vertical-align: top; line-height: 18px">
                      <small
                        ><span>Linda Krouner<br />Senior Portfolio Manager</span></small
                      >
                    </td>
                    <td width="33%" style="text-align: center; vertical-align: top; line-height: 18px">
                      <small
                        ><span>Jennifer McCarthy&nbsp;<br />Trust Counsel&nbsp; &nbsp;<br /></span
                      ></small>
                    </td>
                    <td width="33%" style="text-align: center; vertical-align: top; line-height: 18px">
                      <small
                        ><span>Amy Ko<br />Senior Relationship Manager<br /></span
                      ></small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
