import { Component, OnInit, Input, OnChanges, ViewEncapsulation, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Constant } from '../../ft-constants/constant';
import brightcovePlayerLoader from '@brightcove/player-loader';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppStateService } from "@services/app-state.service";
@Component({
  selector: 'ft-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleDetailComponent implements OnInit, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  topbannerImage: any;
  topbannerText: any;
  imageWithContentList = [];
  relatedTopic = [];
  paginateValue = [];
  referenceValue = [];
  currentRef: any;
  rightBlockTitle = [];
  previousPostValue: any;
  nextPostValue: any;
  articleCategory: any;
  previousPostLabel: any;
  nextPostLabel: any;
  relatedPostsLabel: any;
  contactUsLink: any;
  channelName: any;
  articleProfile: any = [];
  articleprofileDetail: any = {};
  notAvailable: any;
  noimageUrl: any;
  accountID: any;
  videoID: any;
  relatedListPosts: any = [];
  contributors: string = '';
  authors: string = '';
  audioEmbedCode: string = '';
  authorAndDateJoined: string = '';
  authorName: any;
  articleDate: any;
  isCanadaSite: any;
  youtubeEmbed: SafeHtml = null;
  isCanadaFrench: boolean;
  url;
  channel: string;
  locale: string;
  constructor(private sanitizeHtml: DomSanitizer,
    private appStateService: AppStateService) {}

  ngOnInit(): void {
    this.url = window.location.href;
    this.previousPostLabel = Constant.previousPost;
    this.nextPostLabel = Constant.nextPost;
    this.relatedPostsLabel = Constant.relatedPost;
    this.channel = this.appStateService.getChannel();
    this.locale = this.appStateService.getLocale();
    const currUrl = location.host;
    if (currUrl.includes('dev') || currUrl.includes('staging') || currUrl.includes('beta') || currUrl.includes('localhost')) {
      this.contactUsLink = '/' + this.channel + '/' + Constant.contactUsPage;
    } else {
      if(this.locale === Constant.encaLocale) {
          this.contactUsLink = '/' + Constant.encaLocale + '/' + Constant.contactUsPage;
      } else  if(this.locale === Constant.frcaLocale) {
          this.contactUsLink = '/' + Constant.frcaLocale + '/' + Constant.contactUsPage;
      } else  if(this.locale === Constant.enusLocale) {
          this.contactUsLink = '/' + Constant.contactUsPage;
      }
    }
    this.channelName = Constant.channelName;
    this.noimageUrl = this.channelName + Constant.noImage;
    this.notAvailable = this.channelName + '/' + Constant.notAvailable;
    this.isCanadaSite = Constant.isCanadaSite;
    this.isCanadaFrench = location.href.indexOf('fr-ca') > 0 ? true : false;
  }
  ngAfterViewInit() {
    if (this.accountID && this.videoID) {
      brightcovePlayerLoader({
        refNode: '#player-container',
        accountId: this.accountID,
        videoId: this.videoID,
        refNodeInsert: 'replace',
        embedOptions: {
          tagName: brightcovePlayerLoader.EMBED_TAG_NAME_VIDEO,
        },
        options: {
          width: '520px',
          height: '300px',
          playButton: 'center',
        },
      })
        .then(function (success) {
          // The player has been created!
        })
        .catch(function (error) {
          // Player creation failed!
        });
    }
    const articleDate = document.querySelector('.billboard-overlay p:last-child:not(.authorAndDate)');
    const dateAuthorElem = document.querySelector('.billboard-overlay .authorAndDate');
    const pattern = /^\d{1,2}.\d{1,2}.\d{4}$/;
    this.authorAndDateJoined = this.articleAuthors();
    /* if (articleDate) {
      let elemData: string;
      if (articleDate.textContent.indexOf('-') !== -1) {
        elemData = articleDate.textContent.split('-')[0];
      } else {
        elemData = articleDate.textContent.split(' ')[0];
      }
      if (pattern.test(elemData)) {
        articleDate.innerHTML = this.authorAndDateJoined;
        dateAuthorElem.setAttribute('style', 'display:none');
      } else {
        dateAuthorElem.setAttribute('style', 'display:block');
        dateAuthorElem.textContent = this.authorAndDateJoined;
      }
    } else {
      dateAuthorElem.setAttribute('style', 'display:block');
      dateAuthorElem.textContent = this.authorAndDateJoined;
    } */
    if (this.audioEmbedCode !== '') {
      document.querySelector('#audio-player').innerHTML = this.audioEmbedCode;
    }
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get articleprofiledata() {
    this.contributors = '';
    this.articleProfile = [];
    const data = this.content?.articleMetadata;
    const modelKey = 'model';
    const contentKey = 'content';
    if (data) {
      data.mirror.forEach((element) => {
        const profiledocument = this.page.getContent(element)?.getData();
        let imageUrl = '';
        if (profiledocument.image) {
          imageUrl = this.page?.getContent(profiledocument.image)?.getUrl();
        } else if (profiledocument?.imageCompound?.widenAsset) {
          const widenAsset = JSON.parse(profiledocument.imageCompound.widenAsset);
          imageUrl = widenAsset?.url ? widenAsset.url : '';
        }
        const image = imageUrl.indexOf('original') !== -1 ? imageUrl.replace('original', 'webp') + Constant.optimisedProfileImg : '';
        this.articleprofileDetail = { image, title: profiledocument?.title };
        this.contributors = this.contributors && this.contributors !== '' ? this.contributors + ', ' + profiledocument?.title : profiledocument?.title;
        this.articleProfile.push(this.articleprofileDetail);
      });
    }

    return this.articleProfile;
  }

  getTitlevalue(scope: any) {
    const words = scope?.split('-') || [];
    return words
      .map(function (word) {
        return word?.charAt(0).toUpperCase() + word?.substring(1).toLowerCase();
      })
      .join(' ');
  }

  getInternalLink(document: any) {
    return this.page?.getContent(document).getUrl(); // getData().links?.site?.href;
  }
  getWidenAssetLink(elem: any) {
    let widenLink = '';
    if (elem.widenAssetCompound && elem.widenAssetCompound.widenDocument) {
      widenLink = JSON.parse(elem.widenAssetCompound.widenDocument).url ? JSON.parse(elem.widenAssetCompound.widenDocument).url : '';
    } else if (elem.url) {
      widenLink = elem.url;
    }
    return widenLink;
  }
  get content() {
    return this.document?.getData();
  }
  get item() {
    return this.document?.getData();
  }
  get imageWithContent() {
    this.imageWithContentList = this.content?.ftitopBannerSection || [];
    this.getImages();
    return this.imageWithContentList;
  }

  getImages() {
    const data = this.content?.ftitopBannerSection || [];
    this.imageWithContentList = data;
  }
  relatedNews() {
    const modelKey = 'model';
    const data = this.page?.getComponent('side-content')?.getComponent('FTI-related-posts')?.[modelKey];
    data?.models?.pagination?.items.forEach((element) => {
      let elemData = this.getPageContent(element);
      this.relatedListPosts.push({ displayName: elemData.displayName, relatedpageUrl: elemData.links?.site?.href });
    });
    return this.relatedListPosts;
  }
  getPageContent(ref: any) {
    return this.page?.getContent(ref)?.getData();
  }
  get bannerimage() {
    const data = this.content?.articleBanner?.ftitopBannerSection;
    let imageUrl = '';
    if (data?.imagelink) {
      imageUrl = this.page?.getContent(data.imagelink.$ref).getUrl() || '';
    } else if (data?.imageCompound?.contentType === 'ftcore:ImageCompound' && data?.imageCompound?.widenAsset) {
      imageUrl = JSON.parse(data?.imageCompound?.widenAsset).url ? JSON.parse(data?.imageCompound?.widenAsset).url : '';
    }
    this.topbannerImage = imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') + Constant.optimisedBannerImg : '';
    return this.topbannerImage;
  }
  getImageUrl(elem: any) {
    let imageUrl = '';
    if (elem.imagelink) {
      imageUrl = this.page?.getContent(elem.imagelink)?.getData()?.pmHeadshotCircle?.links?.site?.href;
    } else if (elem.imageCompound && elem.imageCompound.contentType === 'ftcore:ImageCompound' && elem.imageCompound.widenAsset) {
      imageUrl = JSON.parse(elem.imageCompound.widenAsset).url ? JSON.parse(elem.imageCompound.widenAsset).url : '';
    } else if (elem.imageUrl && elem.imageUrl.contentType === 'ftcore:WidenAssetCompound' && elem.imageUrl.widenDocument) {
      imageUrl = JSON.parse(elem.imageUrl.widenDocument).url ? JSON.parse(elem.imageUrl.widenDocument).url : '';
    } else if (elem.image && elem.image.contentType === 'ftcore:WidenAssetCompound' && elem.image.widenDocument) {
      imageUrl = JSON.parse(elem.image.widenDocument).url ? JSON.parse(elem.image.widenDocument).url : '';
    }
    if (!imageUrl || imageUrl === '') {
      imageUrl = this.channelName + Constant.noImage;
    }

    return imageUrl;
  }
  get twoColumnBlock() {
    const data = this.content?.twoColContentTab?.twoColumnBlock;
    let imageval = '';
    let imageUrl = '';
    if (data) {
      data.items.forEach((item, ind) => {
        if (item.$ref) {
          imageUrl = this.page?.getContent(item.$ref).getUrl();
        } else if (item.contentType === 'ftcore:ImageCompound' && item.widenAsset) {
          imageUrl = JSON.parse(item.widenAsset).url ? JSON.parse(item.widenAsset).url : '';
        } else {
          imageval = 'not images';
          imageUrl = '';
        }
        item.imgUrl = imageUrl;

        if (item.contentType === 'ftcore:EmbeddedVideo') {
          if (item.videoEmbedCode.indexOf('youtube') > 0) {
            this.youtubeEmbed = this.sanitizeHtml.bypassSecurityTrustHtml(item.videoEmbedCode);
          } else {
            let videocodembedcode = item.videoEmbedCode.split('\r\n');
            this.setVideoAttributes(videocodembedcode);
          }
        }
        if (item.contentType === 'fiduciary:EmbeddedAudio') {
          this.audioEmbedCode = unescape(item.audio);
        }
      });
    }
    return data?.items;
  }
  setVideoAttributes(attrs) {
    for (let i = 0; i < attrs.length; i++) {
      let item = attrs[i];
      item = item.split('=');
      if (item[0] === 'data-video-id') {
        this.videoID = item[1];
        this.videoID = this.videoID.replaceAll('"', '');
      } else if (item[0] === 'data-account-id') {
        this.accountID = item[1];
        this.accountID = this.accountID.replaceAll('"', '');
      }
    }
    if (this.accountID && this.videoID) {
      this.videoembeded(this.videoID, this.accountID);
    }
  }
  videoembeded(video_id: any, account_id: any) {
    console.log('videoEmbedded');
    brightcovePlayerLoader({
      refNode: '#player-container',
      accountId: account_id,
      videoId: video_id,
      refNodeInsert: 'replace',
      embedOptions: {
        tagName: brightcovePlayerLoader.EMBED_TAG_NAME_VIDEO,
      },
      options: {
        width: '520px',
        height: '300px',
        playButton: 'center',
      },
    })
      .then(function (success) {
        // The player has been created!
      })
      .catch(function (error) {
        // Player creation failed!
      });
  }
  getPageUrl(ref: any) {
    let pageUrl = this.page?.getContent(ref)?.getData()?.links?.site?.href;

    if (!pageUrl) {
      pageUrl = this.notAvailable;
    }
    return pageUrl;
  }
  get rightBlockImages() {
    const data = this.content?.twoColContentTab?.twoColumnBlock;
    let imageval = '';
    let imageUrl = '';
    if (data) {
      data?.itemsTwo.forEach((item, ind) => {
        if (item.$ref) {
          imageUrl = this.page?.getContent(item.$ref).getUrl();
        } else if (item.contentType === 'ftcore:ImageCompound' && item.widenAsset) {
          imageUrl = JSON.parse(item.widenAsset).url ? JSON.parse(item.widenAsset).url : '';
        } else {
          imageval = 'not images';
          imageUrl = '';
        }
        if (item?.title) {
          this.rightBlockTitle[ind - 1] = item?.title;
        }
        item.imageUrl = imageUrl;
      });
    }
    return data?.itemsTwo;
  }
  get socialIcons() {
    const dataSocial = this.content?.ftisocialLink || [];
    for (let i = 0; i < dataSocial.length; i++) {
      let item = dataSocial[i];
      let imageSocialUrl = '';

      if (item?.imagelink) {
        imageSocialUrl = this.page?.getContent(item?.imagelink)?.getData()?.original.links.site.href;
      } else if (item?.imageCompound?.contentType === 'ftcore:ImageCompound' && item?.imageCompound?.widenAsset) {
        imageSocialUrl = JSON.parse(item?.imageCompound?.widenAsset).url ? JSON.parse(item?.imageCompound?.widenAsset).url : '';
      }
      item.imageSocialUrl = imageSocialUrl;
    }
    return dataSocial;
  }
  get bannerText() {
    const data = this.content?.articleBanner?.ftitopBannerSection;
    this.topbannerText = data?.html;
    return this.topbannerText;
  }
  get preTitle() {
    return this?.content?.articleBanner?.ftitopBannerSection?.preTitle || null;
  }
  get bannerTitle() {
    return this?.content?.articleBanner.ftitopBannerSection?.imageCompound?.title || null;
  }
  articleAuthors() {
    let authors = this.content?.articleBanner?.authors;
    this.authorName = authors;
    let articleDate: any = new Date(this.content?.articleBanner?.date).toLocaleDateString();
    this.articleDate = articleDate;
    articleDate = articleDate.replaceAll('/', '.');
    if (authors !== '' && authors != 'No author' && authors !== null) {
      return articleDate + '- ' + authors;
    } else {
      return articleDate;
    }
  }

  get title() {
    return this.page?.getTitle();
  }
  get isPreview() {
    return this.page?.isPreview();
  }
}
