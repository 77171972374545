<ng-container>
  <div class="container bg-lightgray">
    <div class="col-12">
      <div class="search-panel bg-lightgray">
        <form action="" class="modal-search-form" role="search" method="post">
          <div class="row">
            <div class="col-8 search-input-wrapper">
              <input
                id="queryInput"
                [(ngModel)]="queryText"
                name="queryInput"
                type="text"
                class="input-search"
                aria-controls="1"
                title="{{ locale === 'fr-ca' ? 'Rechercher  Fiduciary Trust' : 'Search Fiduciary Trust' }}"
                autocomplete="off"
                spellcheck="false"
                tabindex="-1"
              />
            </div>
            <div class="col-1 submit-btn-wrapper">
              <button class="col-xs-1 btn btn-primary search-submit" (click)="callSearch(queryText)">
                {{ locale === "fr-ca" ? "Aller" : "Go" }}
              </button>
            </div>
          </div>
        </form>

        <div *ngIf="loading" style="padding: 5rem; text-align: center">
          <img style="margin: auto" src="{{ loaderPath }}/assets/images/loader.gif" />
        </div>

        <div *ngIf="!loading && isDataFound" class="results-wrpper row pt-4" #result>
          <h2 class="panel-results-title">{{ locale === "fr-ca" ? "Aucun résultat" : "Search Results" }}</h2>
          <div class="col-md-10 search-result-list pt-4">
            <div class="row search-result-row">
              <div class="col-xs-12">
                <div class="result-record" *ngFor="let res of searchResults?.hits?.hits">
                  <div class="result-body">
                    <p class="result-title">
                      <a *ngIf="res.highlight.title && res.highlight.title[0] !== ''" aria-label="search" [routerLink]="setPageUrl(res)" [innerHTML]="res.highlight.title[0]"></a>
                      <a *ngIf="!res.highlight.title || res.highlight.title[0] === ''" aria-label="search" [routerLink]="setPageUrl(res)" [innerHTML]="res._source.title"></a>
                    </p>
                    <p *ngIf="res._source.description && res._source.description !== ''" class="search-result__excerpt" [innerHTML]="res._source.description"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!loading && !isDataFound" class="results-wrpper row mt-4" #noResult>
          <h2 class="panel-results-title">
            {{ noResultText }} <strong>{{ queryText }}</strong>
          </h2>
          <div class="col-md-10 no-result pt-5">
            <div class="row search-result-row">
              <div class="col-xs-12">
                <div class="result-record">
                  <div class="result-body">
                    <h3 class="mb-4">{{ searchHelpText }}</h3>
                    <ul>
                      <li>
                        <span>{{ genericSearchTermText }}</span>
                      </li>
                      <li>
                        <span>{{ removingFilterText }}</span>
                      </li>
                      <li>
                        <span>{{ typographicalMistakes }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
