<div class="container position-relative twoColTab bg-lightgray">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div>
        <div class="grid section-wrp1">
          <div class="small-tabs" *ngIf="layout === smallTabsLayout">
            <div class="section-wrp">
              <ul ngbNav #nav="ngbNav" class="nav-tabs grid-row" [destroyOnHide]="false">
                <li [ngbNavItem]="i" class="" *ngFor="let item of tabArray; index as i">
                  <a ngbNavLink class="">
                    <ng-container *ngIf="getTabTitle(i + 1)">{{ getTabTitle(i + 1) }}</ng-container>
                  </a>
                  <ng-template ngbNavContent>
                    <ng-container brComponent="tab-area-{{ i + 1 }}"></ng-container>
                  </ng-template>
                </li>
              </ul>
            </div>
            <div class="row bg-white" style="margin: 0">
              <div class="section-wrp">
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
              </div>
            </div>
          </div>
          <div class="large-tabs" *ngIf="layout === profileAndTabsLayout">
            <div class="section-wrp hidden-xs">
              <ul ngbNav #nav="ngbNav" class="nav-tabs grid-row" [destroyOnHide]="false">
                <li [ngbNavItem]="i" class="single-column col-xs-12 col-sm-4" *ngFor="item; of: tabArray; index as i">
                  <a ngbNavLink class="grid-item col-xs-12 col-md-4 single white">
                    <div class="tabTitleWrapper">
                      <div class="t-row upper">
                        <div class="t-cell">
                          <h2 *ngIf="getTabPreTitle(i + 1)">{{ getTabPreTitle(i + 1) }}</h2>
                        </div>
                      </div>
                      <div class="t-row lower">
                        <div class="t-cell">
                          <h3 *ngIf="getTabTitle(i + 1)">{{ getTabTitle(i + 1) }}</h3>
                        </div>
                      </div>
                    </div>
                  </a>
                  <ng-template ngbNavContent>
                    <ng-container brComponent="tab-area-{{ i + 1 }}"></ng-container>
                  </ng-template>
                </li>
              </ul>
            </div>
            <div class="row bg-white hidden-xs" style="margin: 0">
              <div class="section-wrp">
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
              </div>
            </div>
            <div class="white hidden-sm hidden-md hidden-lg">
              <div class="mobile-tab">
                <ng-container *ngFor="let item of tabData">
                  <div class="wrapper row">
                    <h2 *ngIf="item?.tabTitle?.preTitle">{{ item?.tabTitle?.preTitle }}</h2>
                    <div class="col-sm-8">
                      <h3 *ngIf="item?.tabTitle?.title">{{ item?.tabTitle?.title }}</h3>
                    </div>
                  </div>
                  <div class="wrapper row">
                    <div class="col-sm-4 left-column no-float">
                      <ng-container *ngFor="let childItem of item?.tabContent?.items">
                        <h2 *ngIf="childItem.type == 'TitleBlock'">{{ childItem.title }}</h2>
                        <div *ngIf="childItem.type == 'ParagraphBlock'" [innerHTML]="childItem.content"></div>
                      </ng-container>
                    </div>
                    <div class="col-sm-8 right-column no-float">
                      <ng-container *ngFor="let childItem of item?.tabContent?.itemsTwo">
                        <h3 *ngIf="childItem.type == 'TitleBlock'">{{ childItem.title }}</h3>
                        <div *ngIf="childItem.type == 'ParagraphBlock'" [innerHTML]="childItem.content"></div>
                        <img alt="image" *ngIf="getImageUrl(childItem)" class="" [src]="getImageUrl(childItem)" />
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
