<div class="container position-relative article-grid bg-lightgray">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="row">
        <div class="grid">
          <div class="grid-row row">
            <div class="single-column col-sm-6 col-md-4" *ngFor="let item of gridList; index as i">
              <a [href]="item.externalLink.url">
                <img [src]="getImg(item.image)" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
