import { AfterViewInit, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { Location } from '@angular/common';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { getModelFromRef } from '@utils/pagemodel-utils';

@Component({
  selector: 'ft-list-menu',
  templateUrl: './list-menu.component.html',
  styleUrls: ['./list-menu.component.scss'],
})
export class ListMenuComponent implements OnInit, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  siteMenu;
  collapseIn;
  selectedTab;
  isSelectedPage: boolean;
  currentPath;
  show = 'collapse';
  @HostBinding('class') class = 'navbar-nav col-12';
  constructor(private location: Location) {}
  ngAfterViewInit(): void {
    const insightsNavElem = document.querySelector("a[href*='/what-we-do']").parentElement;
    insightsNavElem.classList.add('active');
  }

  ngOnInit(): void {
    this.siteMenu = getModelFromRef(this.page, this.component, 'menu');
    const currentMenu = this.menu.filter((menuObj) => menuObj.selected === true )
    this.selectedTab = currentMenu?.[0]?.name;
  }

  get menu() {
    const siteNavItems = this.siteMenu?.items;

    const sitemenuItems = [];
    siteNavItems?.forEach((element) => {
      sitemenuItems.push(element.model);
    });
    return sitemenuItems;
  }

  clickEvent() {
    //this.show=this.show==="collapse"?"collapse-in":"collapse";
    this.collapseIn = !this.collapseIn;
  }
}
