import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Location } from '@angular/common';
import { Constant } from 'src/app/ft-constants/constant';
import { single } from 'rxjs/operators';
import { AppStateService } from '@services/app-state.service';

@Component({
  selector: 'ft-card-grid',
  templateUrl: './card-grid.component.html',
  styleUrls: ['./card-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardGridComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  cardGridContentList = [];
  isContactCard = false;
  isInsightsPage: boolean;
  cardGridArr;
  singleColumnContent = [];
  doubleColumnContent = [];
  myJson: any = JSON;
  locale = '';
  constructor(private location: Location, private appStateService: AppStateService) {}

  ngOnInit(): void {
    this.locale = this.appStateService.getLocale();
    const currentPath = this.location.path().split('/');
    const pathFragment = currentPath.reverse()[0];
    this.isInsightsPage = pathFragment === Constant.isInsights ? true : false;
  }
  get document() {
    const { document } = this.component?.getModels() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }
  get checkIfContactCard() {
    this.isContactCard = this.content?.cardGridRow || this.content?.doubleColumn ? false : true;
    if (this.isContactCard) {
      this.cardGridContentList = this.content?.cardGrid || [];
    } else {
      if (!this.content.cardGridRow) {
        this.cardGridArr = Array(this.content.singleColumn.length).fill(this.content.singleColumn.length);
        this.singleColumnContent = this.content.singleColumn;
        this.doubleColumnContent = this.content.doubleColumn;
      }
      this.getImages();
    }
    return this.isContactCard;
  }
  get cardGridRow() {
    return this.content.cardGridRow || null;
  }
  get title() {
    return this.page?.getTitle();
  }
  get cardGridContent() {
    this.cardGridContentList = this.content?.cardGrid || [];
    let itemLinkOne = '';
    let itemLinkTwo = '';

    if (this.checkIfContactCard == false) {
      this.cardGridContentList.forEach((item) => {
        if (item?.items.length == 2) {
          if (item?.items) {
            item.anchorLinkOne = this.getInternalLink(item?.items[1]?.linkCollection[0]?.document);
            item.items.forEach((elem) => {
              if (elem.contentType === 'ftcore:LinkCompound') {
                if (elem?.linkCollection[0]?.contentType === 'ftcore:LinkInternalPage') {
                  item.items.anchorLink = this.getInternalLink(elem?.linkCollection[0]?.document);
                }
              }
            });
          }
          if (item?.itemsTwo) {
            item.anchorLinkTwo = this.getInternalLink(item?.itemsTwo[2]?.linkCollection[0]?.document);
          }
        }
      });
    }
    this.getImages();
    return this.cardGridContentList;
  }
  getLink(elem: any) {
    /* Extracting the anchor tag's href value from innerHTML  */
    const domDiv = document?.createElement('div');
    domDiv.innerHTML = elem?.content;
    const akey = 'a';
    const hrefkey = 'href';
    const link = domDiv?.querySelector('a');
    const hrefAttr = link?.getAttribute('href');
    return hrefAttr;
  }
  getInternalLink(elem: any) {
    if (elem[0] && elem[0].document && elem[0].contentType === 'ftcore:LinkInternalPage') {
      return this.page?.getContent(elem[0].document).getUrl(); // getData().links?.site?.href;
    } else if (elem[0] && elem[0].contentType === 'ftcore:LinkURL') {
      return elem[0].url;
    } else if (elem[0] && elem[0].contentType === 'ftcore:LinkWidenAsset') {
      const widenJson = elem[0].widenAssetCompound.widenDocument;
      return JSON.parse(widenJson).url;
    } else {
      return '';
    }
  }
  getImages() {
    if (this.isContactCard) {
      const data = this.content?.cardGrid || [];
      data.forEach((item, ind) => {
        item?.items?.forEach((elem) => {
          // check if the $ref value points to image url or link compound url
          if (elem?.$ref && elem?.$ref.indexOf('.jpg') === -1 && elem?.$ref.indexOf('.jpeg') === -1 && elem?.$ref.indexOf('.png') === -1) {
            const url = elem?.$ref?.split('/content/')[1];
            const modelKey = 'model';
            const contentKey = 'content';
            elem.$ref = this.page?.[modelKey]?.[contentKey]?.[url]?.links?.site?.href;
          } else if (elem?.contentType === 'ftcore:ParagraphBlock') {
            elem.link = this.getLink(elem);
          } else if (elem?.contentType === 'ftcore:ImageCompound' && elem?.widenAsset) {
            elem.$ref = JSON.parse(elem?.widenAsset).url ? JSON.parse(elem?.widenAsset).url : '';
          }
        });
        item.itemsTwo.forEach((elem) => {
          //check if the $ref value points to image url or link compound url
          if (elem?.$ref && elem?.$ref.indexOf('.jpg') === -1 && elem?.$ref.indexOf('.jpeg') === -1 && elem?.$ref.indexOf('.png') === -1) {
            const url = elem?.$ref?.split('/content/')[1];
            const modelKey = 'model';
            const contentKey = 'content';
            elem.$ref = this.page?.[modelKey]?.[contentKey]?.[url]?.links?.site?.href;
          } else if (elem?.contentType === 'ftcore:ParagraphBlock') {
            elem.link = this.getLink(elem);
          } else if (elem?.contentType === 'ftcore:ImageCompound' && elem?.widenAsset) {
            elem.$ref = JSON.parse(elem?.widenAsset).url ? JSON.parse(elem?.widenAsset).url : '';
          }
        });
      });
      this.cardGridContentList = data;
    } else if (this.content.singleColumn) {
      for (let i = 0; i < this.cardGridArr.length; i++) {
        let singleCol = this.content.singleColumn;
        let doubleCol = this.content.doubleColumn;
        let imageUrl = singleCol[i].imageCompound?.widenAsset !== '' ? JSON.parse(singleCol[i].imageCompound?.widenAsset).url : '';
        this.singleColumnContent[i].imgUrl = imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') : imageUrl;
        imageUrl = doubleCol[i].imageCompound?.widenAsset !== '' ? JSON.parse(doubleCol[i].imageCompound?.widenAsset).url : Constant.noImage;
        this.doubleColumnContent[i].imgUrl = imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') : imageUrl;
      }
    } else {
      let imageUrl = Constant.noImage;
      for (let i = 0; i < this.cardGridRow.length; i++) {
        let cardGridItems = this.cardGridRow[i].items;
        for (let j = 0; j < cardGridItems.length; j++) {
          let singleCol = cardGridItems[j].singleColumn;
          let doubleCol = cardGridItems[j].doubleColumn;
          if (singleCol) {
            imageUrl = singleCol.imageCompound?.widenAsset !== '' ? JSON.parse(singleCol.imageCompound?.widenAsset).url : '';
            cardGridItems[j].singleColumn.imgUrl = imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') : imageUrl;
          }
          if (doubleCol) {
            imageUrl = doubleCol.imageCompound?.widenAsset !== '' ? JSON.parse(doubleCol.imageCompound?.widenAsset).url : Constant.noImage;
            cardGridItems[j].doubleColumn.imgUrl = imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') : imageUrl;
          }
        }
      }
    }
  }
  getImageUrl(elem: any) {
    let imageUrl = Constant.noImage;
    if (elem?.$ref && elem?.$ref.indexOf('.jpg') !== -1 && elem?.$ref.indexOf('.jpeg') !== -1 && elem?.$ref.indexOf('.png') !== -1) {
      imageUrl = this.page?.getContent(elem.$ref).getUrl();
    } else if (elem?.contentType === 'ftcore:ImageCompound' && elem?.widenAsset) {
      imageUrl = JSON.parse(elem?.widenAsset).url ? JSON.parse(elem?.widenAsset).url : imageUrl;
      imageUrl = imageUrl.indexOf('original') != -1 ? imageUrl.replace('original', 'webp') : imageUrl;
    }
    return imageUrl;
  }
}
