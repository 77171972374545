import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';

@Component({
  selector: 'ft-insights-disclaimer',
  templateUrl: './insights-disclaimer.component.html',
  styleUrls: ['./insights-disclaimer.component.scss'],
})
export class InsightsDisclaimerComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  constructor() {}

  ngOnInit(): void {}

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  get contentBlock() {
    return this.content?.contentBlock || [];
  }
}
