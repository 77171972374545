import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Constant } from '../../ft-constants/constant';
import { Location } from '@angular/common';

@Component({
  selector: 'ft-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  isAwardsPage: boolean;
  isHistoryPage: boolean;
  isWhoWeArePage: boolean;
  isAdvisoryPage: boolean;
  contentData: any;
  imageData: any;
  isFinancialMonthPlanning: boolean;
  isWomenPage: boolean;
  noBgColor: boolean;
  constructor(private location: Location, private cb: ChangeDetectorRef) {}

  ngOnInit(): void {
    const currentPath = this.location.path().split('/');
    const pathFragment = currentPath.reverse()[0];
    this.isAwardsPage = pathFragment === Constant.isAwards ? true : false;
    this.isWhoWeArePage = pathFragment === Constant.isWhoWeArePage ? true : false;
    this.isHistoryPage = pathFragment === Constant.isHistory ? true : false;
    this.isAdvisoryPage = pathFragment === Constant.advisoryBoard ? true : false;
    this.isFinancialMonthPlanning = window.location.pathname === '/financial-planning-month' ? true : false;
    this.isWomenPage = window.location.pathname === '/women' ? true : false;
  }
  ngAfterViewInit() {
    if (this.contentData) {
      this.noBgColor = this.contentData[0]?.sectionId === 'flagText' ? true : false;
      this.cb.detectChanges();
    }
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  get contentBlock() {
    this.contentData = (this.content?.contentBlock ? this.content?.contentBlock : this.content?.contentBlocks) || [];
    let imageval = '';
    let imageUrl = '';
    this.contentData.forEach((item, ind) => {
      item.items.forEach((image, val) => {
        if (image.$ref) {
          imageUrl = this.page?.getContent(image.$ref).getUrl();
        } else if (image?.contentType === 'ftcore:ImageCompound' && image?.widenAsset) {
          imageUrl = JSON.parse(image?.widenAsset).url ? JSON.parse(image?.widenAsset).url : '';
        } else {
          imageval = 'not images';
          imageUrl = '';
        }
        image.imageUrl = imageUrl;
      });
    });
    return this.contentData;
  }
  get imageBlock() {
    this.imageData = this.content?.contentBlock || [];
    let imageval = '';
    let imageUrl = '';
    this.imageData.forEach((item, ind) => {
      item.items.forEach((image, val) => {
        if (image.$ref) {
          imageUrl = this.page.getContent(image.$ref).getUrl();
        } else if (image.contentType === 'ftcore:ImageCompound' && image.widenAsset) {
          imageUrl = JSON.parse(image.widenAsset).url ? JSON.parse(image.widenAsset).url : '';
        } else {
          imageval = 'not images';
          imageUrl = '';
        }
      });
    });
    return this.imageData;
  }
  get title() {
    return this?.page?.getTitle();
  }
  getPageUrl(ref: any) {
    return this.page?.getContent(ref)?.getData()?.links?.site?.href;
  }
}
