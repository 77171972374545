<ng-container *ngIf="page && mapping && configuration">
  <div class="loading" *ngIf="!pageLoaded">
    <div class="loading__inner"></div>
  </div>
  <br-page [configuration]="configuration" [mapping]="mapping" [page]="page">
    <ng-template let-page="page">
      <header>
        <ng-container brComponent="header-content"></ng-container>
      </header>
      <ng-container *ngIf="!isSearchPage">
        <section class="lead-section">
          <ng-container brComponent="lead-content"></ng-container>
        </section>
        <section class="main-section">
          <ng-container brComponent="main-content"></ng-container>
        </section>
        <section [ngSwitch]="layout">
          <section *ngSwitchCase="'profile-and-tabs'">
            <ft-two-column-content-tab [numberOfTabs]="tabCount" [page]="page" [layout]="layout"></ft-two-column-content-tab>
            <section>
              <ng-container brComponent="profile-title"></ng-container>
            </section>
            <ft-image-grid-overlay *ngIf="!isCanadaSite" [numberOfProfiles]="7" [page]="page" [isGridOverlay]="true"></ft-image-grid-overlay>
            <ng-container *ngIf="profileCount">
              <ft-image-grid-overlay [numberOfProfiles]="profileCount" [page]="page" [isGridOverlay]="true"></ft-image-grid-overlay>
            </ng-container>
          </section>
          <section *ngSwitchCase="'small-tabs'">
            <section>
              <ng-container brComponent="profile-title"></ng-container>
            </section>
            <ft-two-column-content-tab [numberOfTabs]="tabCount" [page]="page" [layout]="layout"></ft-two-column-content-tab>
            <ng-container *ngIf="profileCount">
              <ft-image-grid-overlay [numberOfProfiles]="profileCount" [page]="page" [isGridOverlay]="true"></ft-image-grid-overlay>
            </ng-container>
          </section>
          <section *ngSwitchCase="'profile-and-marketo-form'">
            <ft-image-grid-overlay [numberOfProfiles]="profileCount" [page]="page" [isGridOverlay]="false"></ft-image-grid-overlay>
          </section>
        </section>
        <ng-container *ngIf="layout == 'article'">
          <section class="article-section">
            <ng-container brComponent="article-content"></ng-container>
          </section>
          <section class="side-section">
            <ng-container brComponent="side-content"></ng-container>
          </section>
        </ng-container>
        <section class="lower-section">
          <ng-container brComponent="lower-content"></ng-container>
        </section>
      </ng-container>
      <ng-container *ngIf="isSearchPage">
        <ft-search></ft-search>
      </ng-container>
      <ng-container *ngIf="isEmailSubscriptionPage">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="emailSubscribeForm" #emailSubForm="ngForm" method="POST">
          <div class="offset-lg-1 formGroupWrap">
            <p class="row">
              <label for="firstname" class="col-xs-12 col-sm-5">First Name </label>
              <input type="text" id="firstname" name="firstname" formControlName="firstname" class="col-xs-12 col-sm-7" />
            </p>
            <p class="row">
              <label for="lastname" class="col-xs-12 col-sm-5">Last Name </label>
              <input type="text" id="lastname" name="lastname" formControlName="lastname" class="col-xs-12 col-sm-7" />
            </p>
            <p class="row">
              <label for="email" class="col-xs-12 col-sm-5">Email </label>
              <input type="text" id="email" name="email" formControlName="email" class="col-xs-12 col-sm-7" />
            </p>
            <p class="row">
              <label for="clientOrProspectOrRefSource" class="col-xs-12 col-sm-5"
                >Are you a client, prospect or referral source? <br />
                <small>(Optional)</small></label
              >
              <input id="clientOrProspectOrRefSource" name="clientOrProspectOrRefSource" formControlName="clientOrProspectOrRefSource" class="col-xs-12 col-sm-7" />
            </p>
          </div>
          <div class="col-sm-12">
            <div class="button-wrap col-sm-12 d-flex">
              <button type="reset" class="col-sm-3 outline-btn">CANCEL</button>
              <button type="submit" class="col-sm-3 primary-btn">SEND</button>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="isOutlookContactForm">
        <form [formGroup]="outlookContactForm" (ngSubmit)="onSubmitOutlookForm()" class="outlookForm" #outlookForm="ngForm" method="POST">
          <div class="offset-lg-1 formGroupWrap">
            <p class="row">
              <label for="name" class="col-xs-12 col-sm-5">Name </label>
              <input type="text" id="name" name="name" formControlName="name" class="col-xs-12 col-sm-7" />
            </p>
            <p class="row">
              <label for="email" class="col-xs-12 col-sm-5">Email Address</label>
              <input type="text" id="email" name="email" formControlName="email" class="col-xs-12 col-sm-7" />
            </p>
            <p class="row">
              <label for="phone" class="col-xs-12 col-sm-5">Phone Number </label>
              <input type="text" id="phone" name="phone" formControlName="phone" class="col-xs-12 col-sm-7" />
            </p>
            <p class="row">
              <label for="eventLocation" class="col-xs-12 col-sm-5">Enter Event Location (see above)</label>
              <input id="eventLocation" name="eventLocation" formControlName="eventLocation" class="col-xs-12 col-sm-7" />
            </p>
          </div>
          <div class="col-sm-12">
            <div class="button-wrap col-sm-12 d-flex">
              <button type="reset" class="col-sm-3 outline-btn">CANCEL</button>
              <button type="submit" class="col-sm-3 primary-btn">SEND</button>
            </div>
          </div>
        </form>
      </ng-container>
      <footer class="footer-content">
        <div class="container">
          <div class="foote-inner" [ngClass]="{ 'en-ca': isCanadaSite }">
            <div class="section-inner">
              <ng-container brComponent="footer-content"></ng-container>
            </div>
          </div>
        </div>
      </footer>
    </ng-template>
  </br-page>
</ng-container>
