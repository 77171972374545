import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
@Component({
  selector: 'ft-related-posts',
  templateUrl: './related-posts.component.html',
  styleUrls: ['./related-posts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RelatedPostsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  constructor() {}

  ngOnInit(): void {}
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
}
