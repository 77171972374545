import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';

@Component({
  selector: 'ft-social-media-icons',
  templateUrl: './social-media-icons.component.html',
  styleUrls: ['./social-media-icons.component.scss'],
})
export class SocialMediaIconsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  imageWithContentList = [];
  imageText: any;

  constructor() {}

  ngOnInit(): void {}

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  get contentBlock() {
    this.imageWithContentList = this.content?.ftisocialLink || [];
    this.getImages();
    return this.imageWithContentList;
  }

  get contentText() {
    this.imageText = this.content || [];
    return this.imageText;
  }

  getImages() {
    const data = this.content?.ftisocialLink || [];
    data.forEach((item, ind) => {
      let imageUrl;
      if (item.imagelink) {
        const url = item.imagelink.$ref.split('/content/')[1];
        const modelKey = 'model';
        const contentKey = 'content';
        imageUrl = this.page[modelKey][contentKey][url].links.site.href;
      } else if (item.contentType === 'ftcore:ImageCompound' && item.widenAsset) {
        imageUrl = JSON.parse(item.widenAsset).url ? JSON.parse(item.widenAsset).url : '';
      }
      item.imgUrl = imageUrl;
    });
    this.imageWithContentList = data;
  }
}
