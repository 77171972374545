<ng-template [ngIf]="menu">
  <div class="col-12 position-relative">
    <ng-container [brManageMenuButton]="menu"></ng-container>
    <ul class="footer-menu list-inline" role="menu" [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <li class="list-inline-item" *ngFor="let item of menuModel.siteMenuItems">
        <a *ngIf="!item.links.site" role="menuitem">
          {{ item.name }}
        </a>
        <a *ngIf="item.links?.site | isExternalLink" [href]="item.links.site?.href" role="menuitem">
          {{ item.name }}
        </a>
        <a *ngIf="item.links?.site | isInternalLink" [ngClass]="{ active: item.selected }" [routerLink]="page.getUrl(item.links.site?.href)" role="menuitem">
          {{ item.name }}
        </a>
      </li>
    </ul>
  </div>
</ng-template>
