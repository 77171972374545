<div class="container position-relative bg-lightgray topBanner cardGrid">
  <div class="section-inner-txt bg-lightgray">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="container-fluid position-relative">
        <div class="section-inner-txt1">
          <div class="grid section-wrp">
            <ng-container *ngIf="content && isInsightsPage">
              <div class="row">
                <div class="left-content col-xs-12 col-md-4 single-column col-sm-12">
                  <div class="grid-item single col-xs-12">
                    <div>
                      <h4 *ngIf="cardGridContent[0].preTitle">{{ cardGridContent[0].preTitle }}</h4>
                      <h3>{{ cardGridContent[0].imageCompound.title }}</h3>
                      <p class="authorDate">
                        <span
                          >{{ cardGridContent[0].articleDate | date: "MM.dd.yyyy":"UTC +5:30"
                          }}<span *ngIf="cardGridContent[0].authorName">&nbsp;-&nbsp;{{ cardGridContent[0].authorName }}</span></span
                        >
                      </p>
                      <a aria-label="Read More" class="read-more">{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</a>
                    </div>
                    <a class="grid-item-link" aria-label="article" [routerLink]="cardGridContent[0].pageUrl"></a>
                  </div>
                </div>
                <div class="right-content col-md-8 double-column col-sm-12">
                  <div class="grid-item double col-xs-12">
                    <div
                      *ngIf="cardGridContent[1].imageCompound"
                      style="background:url({{ getMobileImageUrl(cardGridContent[1]) }}) center center/cover no-repeat"
                      class="single col-xs-12 col-sm-6 white"
                    ></div>
                    <div *ngIf="!cardGridContent[1].imageCompound" style="background:url({{ noImageUrl }}) center center/cover no-repeat" class="single col-xs-12 col-sm-6 white"></div>
                    <div class="grid-item single white col-xs-12 col-sm-6">
                      <div>
                        <h4 *ngIf="cardGridContent[1].preTitle">{{ cardGridContent[1].preTitle }}</h4>
                        <h3>{{ cardGridContent[1].imageCompound.title }}</h3>
                        <p class="authorDate">
                          <span
                            >{{ cardGridContent[1].articleDate | date: "MM.dd.yyyy":"UTC +5:30"
                            }}<span *ngIf="cardGridContent[1].authorName">&nbsp;-&nbsp;{{ cardGridContent[1].authorName }}</span></span
                          >
                        </p>
                        <a aria-label="Read More" class="read-more">{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</a>
                      </div>
                    </div>
                    <a class="grid-item-link" aria-label="article" [routerLink]="cardGridContent[1].pageUrl"></a>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="left-content col-md-8 double-column col-sm-12">
                  <div class="grid-item double col-xs-12">
                    <div
                      *ngIf="cardGridContent[2].imageCompound"
                      style="background:url({{ getMobileImageUrl(cardGridContent[2]) }}) center center/cover no-repeat"
                      class="single col-xs-12 col-sm-6 white"
                    ></div>
                    <div *ngIf="!cardGridContent[2].imageCompound" style="background:url({{ noImageUrl }}) center center/cover no-repeat" class="single col-xs-12 col-sm-6 white"></div>
                    <div class="grid-item single white col-xs-12 col-sm-6">
                      <div>
                        <h4 *ngIf="cardGridContent[2].preTitle">{{ cardGridContent[2].preTitle }}</h4>
                        <h3>{{ cardGridContent[2].imageCompound.title }}</h3>
                        <p class="authorDate">
                          <span
                            >{{ cardGridContent[2].articleDate | date: "MM.dd.yyyy":"UTC +5:30"
                            }}<span *ngIf="cardGridContent[2].authorName">&nbsp;-&nbsp;{{ cardGridContent[2].authorName }}</span></span
                          >
                        </p>
                        <a aria-label="Read More" class="read-more">{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</a>
                      </div>
                    </div>
                    <a class="grid-item-link" aria-label="article" [routerLink]="cardGridContent[2].pageUrl"></a>
                  </div>
                </div>
                <div class="right-content col-xs-12 col-md-4 single-column col-sm-12">
                  <div class="grid-item single col-xs-12">
                    <div>
                      <h4 *ngIf="cardGridContent[3].preTitle">{{ cardGridContent[3].preTitle }}</h4>
                      <h3>{{ cardGridContent[3].imageCompound.title }}</h3>
                      <p class="authorDate">
                        <span
                          >{{ cardGridContent[3].articleDate | date: "MM.dd.yyyy":"UTC +5:30"
                          }}<span *ngIf="cardGridContent[3].authorName">&nbsp;-&nbsp;{{ cardGridContent[3].authorName }}</span></span
                        >
                      </p>
                      <a aria-label="Read More" class="read-more">{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</a>
                    </div>
                    <a class="grid-item-link" aria-label="article" [routerLink]="cardGridContent[3].pageUrl"></a>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h2 class="col-xs-12 pb-3 pt-3" *ngIf="isInsightsPage" style="max-width: 1030px; margin: 27px auto 10px; padding: 0">More Insights</h2>
              <ul *ngIf="content" class="articleList">
                <ng-container *ngFor="let item of articleList">
                  <li>
                    <div class="row article-inner">
                      <div class="col-xs-12 col-sm-2">
                        <img alt="image" *ngIf="item?.imagelink || item?.imageCompound" src="{{ getMobileImageUrl(item) }}" loading="lazy" />

                        <img alt="image" *ngIf="!item?.imagelink && !item?.imageCompound" src="{{ noImageUrl }}" loading="lazy" />
                      </div>
                      <div class="col-xs-12 col-sm-10">
                        <div>
                          <ng-container *ngIf="item.title">
                            <h4 *ngIf="item.preTitle">{{ item.preTitle }}</h4>
                            <h3 *ngIf="item.title">{{ item.title }}</h3>
                            <p class="authorDate">
                              <span
                                >{{ item.articleDate | date: "MM.dd.yyyy":"UTC +5:30" }}<span *ngIf="item.authorName">&nbsp;-&nbsp;{{ item.authorName }}</span></span
                              >
                            </p>
                            <div style="font-size: 14px" *ngIf="item.preTitle && item.title && item.html.value !== ''" [innerHtml]="item?.html.value"></div>
                          </ng-container>

                          <ng-container *ngIf="!item.title">
                            <div class="html-view" *ngIf="item?.html?.value !== ''" [innerHtml]="item?.html?.value"></div>
                          </ng-container>
                        </div>
                        <a aria-label="Read More" class="read-more">{{ locale === "fr-ca" ? "EN SAVOIR PLUS" : "READ MORE" }}</a>
                      </div>
                    </div>
                    <a aria-label="link" aria-hidden="true" class="link-wrapper" [routerLink]="item.pageUrl"></a>
                  </li>
                </ng-container>
              </ul>

              <div *ngIf="showButton" class="row mb-4">
                <button class="button" type="button" (click)="loadMore()">{{ locale === "fr-ca" ? "POUR EN SAVOIR PLUS" : "LOAD MORE" }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
