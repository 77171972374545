import * as $ from 'jquery';
import { ChangeDetectorRef, Component, ElementRef, Inject, Injectable, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { loadMarketoForm } from '../../../assets/js/script.js';
declare var MktoForms2: any;
import { Constant } from '../../ft-constants/constant';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
@Component({
  selector: 'ft-marketo-form',
  templateUrl: './marketo-form.component.html',
  styleUrls: ['./marketo-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarketoFormComponent implements OnInit {
  @Input() page!: Page;
  @Input() component!: BrComponent;
  channelName: any;
  contactusLink: any;
  isTaxGuideLeadPage: boolean;
  formSubmitted: boolean = false;
  constructor(@Inject(DOCUMENT) private documentRef: Document, private location: Location) {}
  ngOnInit(): void {
    const currentPath = this.location.path().split('/');
    const pathFragment = currentPath.reverse()[0];
    this.isTaxGuideLeadPage = pathFragment === Constant.isTaxGuideLead ? true : false;

    this.channelName = Constant.channelName;
    this.contactusLink = Constant.channelName + '/' + Constant.contactUsPage;
    const paramid = this.id;

    if (paramid && paramid !== '') {
      MktoForms2.loadForm(Constant.marketoFormLoadUrl, Constant.marketoFormMchinkId, paramid, function (form) {
        form?.onSuccess(function (values, followUpUrl, formId) {
          form.getFormElem().hide();
          document.getElementById('frmTitle').style.display = 'none';
          document.getElementById('frmContent').style.display = 'none';
          if (paramid === '5215') {
            document.getElementById('confirmform1').style.display = 'block';
          } else {
            document.getElementById('confirmform').style.display = 'block';
          }
          return false;
        });
      });
    }
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    return this.document?.getData();
  }

  get id(): string {
    return this.component?.getParameters()?.id;
  }
  get successTitle(): string {
    return this.component?.getParameters()?.successTitle;
  }
  get successMsg(): string {
    return this.component?.getParameters()?.successMsg;
  }
  get successUrl(): string {
    return this.component?.getParameters()?.successUrl;
  }
  get formTitle(): string {
    return this.component?.getParameters()?.Title || '';
  }
  get formText(): string {
    return this.component?.getParameters()?.Content || '';
  }
  get telephone(): string {
    return this.component?.getParameters()?.telephone || null;
  }
  get email(): string {
    return this.component?.getParameters()?.email || null;
  }
}
