<ng-template [ngIf]="menu">
  <div class="container utility-nav text-end position-relative hidden-xs">
    <ng-container [brManageMenuButton]="siteMenu"></ng-container>
    <ul class="list-inline m-0 top-nav" role="menu" [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <li class="list-inline-item" *ngFor="let item of menu" [ngClass]="{ dropdown: item.childMenuItems?.length > 0 }" (click)="displaySubMenu(item.parameters?.isClientLogin)">
        <a
          *ngIf="!item.parameters?.isClientLogin && !isCustodyPage && item.links.site.type==='internal'"
          [routerLink]="page.getUrl(item.links.site?.href)"
          [ngClass]="{ 'client-login': item.parameters?.isClientLogin }"
          aria-current="page"
          role="menuitem"
          >{{ item.name }}</a
        >
        <a
          *ngIf="!item.parameters?.isClientLogin && !isCustodyPage && item.links.site.type==='external'"
          [href]="item.links.site?.href"
          aria-current="page"
          role="menuitem"
          >{{ item.name }}</a
        >
        <a *ngIf="item.parameters?.isClientLogin" [ngClass]="{ 'client-login': item.parameters?.isClientLogin }" aria-current="page" role="menuitem">{{ item.name }}</a>
        <ng-container *ngIf="item.childMenuItems?.length">
          <ul class="dropdown-menu dropdown-menu-right dropdown-menu-color" [ngClass]="{ open: showSubmenu }">
            <li *ngFor="let childMenuItem of item.childMenuItems" class="submenu">
              <a [href]="childMenuItem.links.site?.href" aria-current="page" role="menuitem" (click)="openPopup(childMenuItem, $event)">{{ childMenuItem.name }}</a>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>
  </div>
</ng-template>
