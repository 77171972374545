import { Injectable } from '@angular/core';
import { Configuration, initialize, Page, PageModel, TYPE_CONTAINER_ITEM_UNDEFINED } from '@bloomreach/spa-sdk';
import { AppStateService } from '@services/app-state.service';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { from, Subject } from 'rxjs';
import { TopMenuComponent } from '@shared/top-menu/top-menu.component';
import { ContentComponent } from '@components/content/content.component';
import { MainMenuComponent } from '@components/shared/main-menu/main-menu.component';
import { FooterComponent } from '@components/shared/footer/footer.component';
import { RichTextComponent } from '@components/shared/rich-text/rich-text.component';
import { PageTitleComponent } from '@components/shared/page-title/page-title.component';

import { BillboardComponent } from '@components/shared/billboard/billboard.component';
import { ArticleListComponent } from '@components/shared/article-list/article-list.component';
import { AwardsListComponent } from '@components/shared/awards-list/awards-list.component';
import { ListMenuComponent } from '@components/shared/list-menu/list-menu.component';

import { TwoColumnContentComponent } from '@components/two-column-content/two-column-content.component';
import { InsightsDisclaimerComponent } from '@components/insights-disclaimer/insights-disclaimer.component';
import { TwoRowImageContentComponent } from '@components/two-row-image-content/two-row-image-content.component';
import { MarketoFormComponent } from '@components/marketo-form/marketo-form.component';
import { CardGridComponent } from '@components/card-grid/card-grid.component';
import { SocialMediaIconsComponent } from '@components/social-media-icons/social-media-icons.component';
import { FtiProfilesComponent } from '@components/fti-profiles/fti-profiles.component';
import { DisplayGridComponent } from '@components/display-grid/display-grid.component';
import { TabContentComponent } from '@components/tab-content/tab-content.component';
import { ArticleDetailComponent } from '@components/article-detail/article-detail.component';
import { CareerbottombillboardComponent } from '@components/careerbottombillboard/careerbottombillboard.component';
import { RelatedPostsComponent } from '@components/related-posts/related-posts.component';
import { SimpleGridComponent } from '@components/simple-grid/simple-grid.component';
import { ActiveViewComponent } from '@components/active-view/active-view.component';
import { ArticleGridViewComponent } from '@components/article-grid-view/article-grid-view.component';
import { InlineImagesComponent } from '@components/inline-images/inline-images.component';
import { JumpLinksComponent } from '@components/jump-links/jump-links.component';
import { JumpLinksContentComponent } from '@components/jump-links-content/jump-links-content.component';
import { VideoCardsComponent } from '@components/video-cards/video-cards.component';
import { GoogleMapComponent } from '@components/shared/google-map/google-map.component';
import { Logger } from '@utils/logger';
import { DebugPageModelComponent } from '@components/shared/debug-page-model/debug-page-model.component';

const logger = Logger.getLogger('PageContainerService');

export type PageConfig = {
  page?: Page;
  configuration: Configuration;
  mapping: Object;
};
@Injectable({
  providedIn: 'root',
})
export class PageContainerService {
  private configuration: Configuration;
  private currentUrl: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  page$ = new Subject<PageConfig>();
  httpResponse: any;
  loaded = false;

  mapping: Object = {
    Header: '',
    'Top Header Menu': TopMenuComponent,
    'fti-content-blocks': ContentComponent,
    'Header Main Menu': MainMenuComponent,
    'Footer Main Menu': FooterComponent,
    'Rich Text': RichTextComponent,
    'Page Title': PageTitleComponent,
    'Tab Component': TabContentComponent,
    'fti-profiles': FtiProfilesComponent,
    'fti-topbanner': BillboardComponent,
    'FTI Marketo Form': MarketoFormComponent,
    'fti-display-grid': DisplayGridComponent,
    'List Component': ArticleListComponent,
    'fti-article-content': ArticleDetailComponent,
    'fti-article-detail': ArticleDetailComponent,
    'Awards List Component': AwardsListComponent,
    'List Menu': ListMenuComponent,
    'fti-careers-topbanner': CareerbottombillboardComponent,
    Footer: '',
    Accordion: '',
    'fti-related-posts': RelatedPostsComponent,
    'fti-pagination-source': RelatedPostsComponent,
    'Utility Bar': '',
    'Segment Banner': '',
    'fti-two-column-block': TwoColumnContentComponent,
    'fti-two-row-image-content': TwoRowImageContentComponent,
    'fti-disclaimer-content': InsightsDisclaimerComponent,
    'fti-card-grid': CardGridComponent,
    'fti-image-icon': SocialMediaIconsComponent,
    'fti-simple-grid': SimpleGridComponent,
    'fti-active-view': ActiveViewComponent,
    'fti-article-grid': ArticleGridViewComponent,
    'fti-inline-images': InlineImagesComponent,
    'fti-jump-links': JumpLinksComponent,
    'fti-jump-links-Content': JumpLinksContentComponent,
    'fti-video-cards': VideoCardsComponent,
    'fti-google-map': GoogleMapComponent,
    profileCount: '',
    title: PageTitleComponent,
  };

  constructor(private appStateService: AppStateService, private httpClient: HttpClient) {
    this.configuration = {
      ...this.appStateService.getApiUrls(),
      httpClient: (...[{ data: body, headers, method, url }]: Parameters<Configuration['httpClient']>) => {
        if (this.loaded && this.httpResponse) {
          this.loaded = false;
          return this.httpResponse;
        }

        this.httpResponse = this.httpClient
          .request<PageModel>(method, url, {
            body,
            headers: headers as Record<string, string | string[]>,
            responseType: 'json',
          })
          .pipe(map((data) => ({ data })))
          .toPromise();
        return this.httpResponse;
      },
    };
    if (this.appStateService.isDebugPageModel()) {
      this.mapping = {
        [TYPE_CONTAINER_ITEM_UNDEFINED]: DebugPageModelComponent,
      };
    }
  }

  newPage(url: string) {
    this.loaded = false;
    this.currentUrl = url;
    const pageConfig: PageConfig = {
      configuration: {
        ...this.appStateService.getBrConfiguration(),
        // The spa sdk is going to add in elements to the path based on options and context
        // so we need to supply JUST the current path to the content.
        request: { path: url },
        debug: logger.willLogDebug(),
      },
      mapping: this.mapping,
    };

    from(initialize(pageConfig.configuration)).subscribe((page: Page) => {
      this.updateLinks(page); // need to fix links for pagemodel 1.0
      pageConfig.page = page;

      this.page$.next(pageConfig);
      this.loaded = true;
    });
  }

  // Pagemodel 1.0 doesnt return channel in links so need to add them.
  public updateLinks(page: any) {
    const baseUrl = this.appStateService.getSpaBaseUrl();
    if (baseUrl) {
      Object.values(page.model.page).forEach((val: any) => {
        if (val.type === 'menu') {
          this.fixMenu(val.data?.siteMenuItems, baseUrl);
        } else {
          this.fixLinks(val, baseUrl);
        }
      });
    }
  }

  private fixMenu(menu: any[], baseUrl): void {
    if (menu) {
      menu.forEach((item) => {
        this.fixLinks(item, baseUrl);
        this.fixMenu(item.childMenuItems, baseUrl);
      });
    }
  }

  private fixLinks(val, baseUrl): void {
    if (val.links?.site?.type === 'internal' && !val.links.site.href?.startsWith(baseUrl)) {
      val.links.site.href = baseUrl + val.links.site.href;
    }
  }
}
