import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Component as BrComponent, Page, isMenu } from '@bloomreach/spa-sdk';
import { Location } from '@angular/common';
import { LeavingSiteModalComponent } from '@shared/leaving-site-modal/leaving-site-modal.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from 'src/app/ft-constants/constant';
import { getMenus, getModelFromRef } from '@utils/pagemodel-utils';

@Component({
  selector: 'ul.br-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  siteMenu;
  showSubmenu: boolean = false;
  isCustodyPage: boolean;

  @HostBinding('class') class = 'navbar-nav col-12';
  constructor(private location: Location, public modalService: NgbModal) {}

  ngOnInit(): void {
    const currentPath = this.location.path().split('/');
    const pathFragment = currentPath.reverse()[0];
    this.isCustodyPage = pathFragment === Constant.isCustody ? true : false;
    this.siteMenu = getModelFromRef(this.page, this.component, 'menu');
  }

  get menu() {
    const siteNavItems = getMenus(this.page, this.component, 'menu').siteMenuItems;
    const menuItems = [];
    const paramKey = 'parameters';
    const childMenuKey = 'childMenuItems';
    const linksKey = 'links';
    siteNavItems?.forEach((element) => {
      const menuItem = {
        name: element.name,
        parameters: element[paramKey],
        childMenuItems: element[childMenuKey],
        links: element[linksKey],
      };
      menuItems?.push(menuItem);
    });
    return menuItems;
  }

  get isPreview() {
    return this.page?.isPreview();
  }

  displaySubMenu(isClientLogin: any) {
    if (isClientLogin) {
      this.showSubmenu = !this?.showSubmenu;
    }
  }
  openPopup(item: any, ev: any) {
    if (item.name === Constant?.isCharitableGivingSubmenu) {
      ev.preventDefault();
      const modalRef = this.modalService?.open(LeavingSiteModalComponent);
      modalRef.componentInstance.url = item.links?.site?.href;
    }
  }
}
