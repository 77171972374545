<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container *ngIf="page?.isPreview()" [brManageContentButton]="document" parameter="document" [relative]="false" root=""> </ng-container>
  <ng-container *ngIf="document">
    <ng-container *ngIf="isGridOverlay">
      <div tabindex="0" class="grid-item speakers col-xs-12 single" style="background:url({{ getImageUrl(content) }}); margin-bottom:0; background-size: cover;">
        <a aria-label="image" class="grid-item-link"></a>
      </div>
      <p *ngIf="profileTitle" class="img-caption">{{ profileTitle }}</p>
    </ng-container>
    <ng-container *ngIf="!isGridOverlay">
      <div class="grid-item speakers col-xs-12 col-sm-6 col-md-4 single" style="background:url({{ getImageUrl(content) }}); margin-bottom:0; background-size: cover;"></div>
      <div class="grid-item speakers col-xs-12 col-sm-6 col-md-4 single white col-lg-arrow-up">
        <div class="col-xs-12">
          <div *ngIf="content?.content?.value != ''" [innerHTML]="content?.content?.value"></div>
          <p *ngIf="profilePreTitle">{{ profilePreTitle }}</p>
          <h3 *ngIf="profileTitle">{{ profileTitle }}</h3>
          <p *ngIf="profileDesignation">{{ profileDesignation }}</p>
          <p *ngIf="profileDescription" [innerHTML]="profileDescription"></p>
          <p *ngIf="content?.telephone" class="telephone">
            <a href="tel:{{ content?.telephone }}">{{ content?.telephone }}</a>
          </p>
          <p *ngIf="content?.email" class="email">
            <a href="mailto:{{ content?.email }}">{{ content?.email }}</a>
          </p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
