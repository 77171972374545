import { Component, Input, OnInit } from "@angular/core";
import {
  Page,
  Component as BrComponent,
  ContainerItem,
} from "@bloomreach/spa-sdk";
import { AppStateService } from "@services/app-state.service";

@Component({
  selector: "ft-debug-page-model",
  templateUrl: "./debug-page-model.component.html",
  styleUrls: ["./debug-page-model.component.scss"],
})
export class DebugPageModelComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  models: any;
  heading: string;
  editorOptions: any = {};

  constructor(private appState: AppStateService) {
    this.appState.loadStyle(
      "/debug-page-model-style.css",
      "debug-page-model-style"
    );
  }

  ngOnInit(): void {
    this.models = this.component.getModels();
    this.heading =
      this.component.getName() +
      ": " +
      (this.component as ContainerItem).getLabel() +
      ": " +
      this.component.getId();
    Object.getOwnPropertyNames(this.models).forEach((prop) => {
      if (this.models[prop]?.$ref) {
        this.models[prop].$val = this.page.getContent(this.models[prop].$ref);
      }
    });
  }
}
