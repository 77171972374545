<div class="container position-relative">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div *ngIf="document" class="secton-wrp">
        <div class="ftci-share-box">
          <span class="share" [innerHtml]="contentText.string"></span>
          <span class="growth-content" *ngFor="let content of contentBlock; let i = index">
            <a
              [ngClass]="{ 'icon-first': i == 0, 'icon-second': i == 1 }"
              href="{{ content.linkCompound.linkCollection[0].url }}"
              target="_blank"
              aria-label="social icon"
              [style.background-image]="'url(' + content.imgUrl + ')'"
            >
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
