import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Constant } from '../../../ft-constants/constant';
import {AppStateService} from '@services/app-state.service'
@Component({
  selector: 'ft-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class GoogleMapComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  // google maps zoom level
  zoom: number = 4;

  // initial center position for the map
  lat: number = 48.271191;
  lng: number = -91.977992;
  iconUrl = "";
  markers = [];
  /* markers = [
    {
      position: {
        lat: 51.047091,
        lng: -114.06986,
      },
      title: 'Calgary, Canada',
      contactDetails: {
        name: 'Calgary, Canada',
        addressLine1: '335 Eighth Avenue SW, Suite 1940',
        addressLine2: 'Calgary, AB, T2P 1C9',
        telephone: '(800) 574-3822',
      },
    },
    {
      position: {
        lat: 43.647862,
        lng: -79.385627,
      },
      title: 'Toronto, Canada',
      contactDetails: {
        name: 'Toronto, Canada',
        addressLine1: '200 King Street West, Suite 1500',
        addressLine2: 'Toronto, ON, M5H 3T4',
        telephone: '(800) 574-3822',
        emailName: 'David Cieslowski, Private Wealth Counselor',
        email: 'dcieslowski@ftcc.ca',
      },
    },
    {
      position: {
        lat: 45.444136,
        lng: -73.54526,
      },
      title: 'Montreal, Canada',
      contactDetails: {
        name: 'Montreal, Canada',
        addressLine1: '1002 Sherbrooke Street West, Suite 1940',
        addressLine2: 'Montreal, Quebec, Canada H3A 3L6',
        telephone: '(514) 281-9105',
      },
    },
  ]; */
  Styles = [
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#444444',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#f2f2f2',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#74a5bd',
        },
        {
          visibility: 'on',
        },
      ],
    },
  ];
  basePath:string="";
  constructor(private appStateService:AppStateService) { }

  ngOnInit(): void {
    this.basePath = this.appStateService.getSpaBaseUrl().includes('fiduciary') ? this.appStateService.getSpaBaseUrl() : '';
    this.iconUrl = this.basePath + '/assets/images/google-map-marker.png';
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }
  get map() {
    return this.content?.map;
  }
  toNumber(val){
    return parseInt(val);
  }
}
