<ng-template [ngIf]="menu">
  <div class="container utility-nav wrapper">
    <div class="navbar-collapse collapse position-relative">
      <ng-container [brManageMenuButton]="siteMenu" relative="true"></ng-container>

      <div class="col-xs-12 col-md-12 responsive-section" (click)="clickEvent()">
        <header>
          <span class="title">{{ selectedTab }}</span>
          <span class="arrow-icon">
            <span class="glyphicon glyphicon-menu-down" *ngIf="!collapseIn"></span>
            <span class="glyphicon glyphicon-menu-up" *ngIf="collapseIn"></span>
          </span>
        </header>
      </div>

      <ul class="nav nav-tabs-top-level nav-justified responsive-ul offset-lg-1" [ngClass]="{ 'collapse-in': collapseIn }" role="menu">
        <li class="col-md-arrow-left" *ngFor="let item of menu">
          <span class="title hidden-sm hidden-md hidden-lg">{{ item.name }}</span>
          <span class="arrow-icon hidden-sm hidden-md hidden-lg">
            <span class="glyphicon glyphicon-menu-right"></span>
          </span>
          <a class="menu-link" *ngIf="!item.links?.site" [ngClass]="{ active: item.selected }">
            <span class="hidden-xs">{{ item.name }}</span>
          </a>
          <a class="menu-link" *ngIf="item.links?.site && item.links?.site?.type === 'external'" [href]="item.links?.site?.href">
            <span class="hidden-xs">{{ item.name }}</span>
          </a>
          <a
            class="menu-link"
            *ngIf="item.links?.site && item.links?.site?.type === 'internal'"
            [ngClass]="{ 'active arrow-down': item.selected }"
            [routerLink]="page.getUrl(item.links?.site?.href)"
          >
            <span class="hidden-xs">{{ item.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
