import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from 'src/app/ft-constants/constant';

@Component({
  selector: 'ft-two-column-content-tab',
  templateUrl: './two-column-content-tab.component.html',
  styleUrls: ['./two-column-content-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbNavConfig],
})
export class TwoColumnContentTabComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  @Input() numberOfTabs;
  @Input() layout;
  profileAndTabsLayout: string = Constant.isProfileAndTabsLayout;
  smallTabsLayout: string = Constant.isSmallTabsLayout;
  tabArray = [];
  constructor(config: NgbNavConfig) {
    config.destroyOnHide = false;
  }
  ngOnInit(): void {
    this.numberOfTabs = isNaN(this.numberOfTabs) ? 0 : parseInt(this.numberOfTabs);
    this.tabArray = Array(this.numberOfTabs).fill(this.numberOfTabs);
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  get tabData() {
    const tabDataList = [];
    for (let i = 1; i <= this.tabArray.length; i++) {
      tabDataList.push(this.getItemData(i));
    }
    return tabDataList;
  }
  getTabPreTitle(ind: any) {
    const data = this.getItemData(ind);
    if (data) {
      return data.tabTitle?.preTitle;
    }
    return;
  }
  getTabTitle(ind: any) {
    const data = this.getItemData(ind);
    if (data) {
      return data.tabTitle?.title;
    }
    return;
  }
  getItemData(ind: any) {
    let componentData;
    const document = this.page
      ?.getComponent('tab-area-' + ind)
      ?.getComponent('FTI-tab-component')
      ?.getModels()?.document;
    if (document) {
      componentData = this.page?.getContent(document)?.getData();
      return componentData;
    }
    return;
  }
  getImageUrl(elem: any) {
    let imageUrl = '';
    if (elem.$ref) {
      imageUrl = this.page?.getContent(elem)?.getData()?.links?.site?.href;
    } else if (elem.contentType === 'ftcore:ImageCompound' && elem.widenAsset) {
      imageUrl = JSON.parse(elem.widenAsset).url ? JSON.parse(elem.widenAsset).url : '';
    }
    return imageUrl;
  }
  get title() {
    return this.page?.getTitle();
  }
}
