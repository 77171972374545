import { LoggerOutput } from './logger-output';
// for appinsights
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EnvConfigService } from '@services/env-config.service';

export class AppInsightsLoggerOutput implements LoggerOutput {
  appInsights: ApplicationInsights;
  isAppInsightsInit = 0;
  localEnv = 'novalue';

  constructor(private envConfig: EnvConfigService, private channel: string) {}

  initAppInsights() {
    // Prepare environment specific info
    if (this.envConfig.getEnvConfig()) {
      this.localEnv = this.envConfig.getEnvConfig().environment;
    }
    // console.log('from init environment is :' + this.localEnv);
    if (!this.channel) {
      this.channel = 'novalue';
    }
    // console.log('channel is :' + this.channel);

    // Check if key exists and init appinsights
    if (this.envConfig.getEnvConfig()?.instrumentationKey) {
      // console.log('appinsights is being initialized..');
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.envConfig.getEnvConfig().instrumentationKey,
          samplingPercentage: 100,
          enableAutoRouteTracking: true,
          // enableRequestHeaderTracking: true,
          // enableResponseHeaderTracking: true
          // ...Other Configuration Options...
        },
      });
      this.appInsights.loadAppInsights();
      this.appInsights.addTelemetryInitializer((telemetryItem) => {
        telemetryItem.tags['ai.cloud.role'] = 'dxpSPA-custom-ftci-' + this.localEnv;
      });
      // Set the init flag
      this.isAppInsightsInit = 1;
      // console.log('appinsights is initialized..and isAppInsightsInit is set to 1');
    } else {
      // console.log('appinsights key is undefined or not available in config');
    }
  }

  private async log(level: string, ...data: any) {
    if (!this.isAppInsightsInit) {
      this.initAppInsights();
      // console.log('appinisights initialized from log method');
    }
    const message = '' + data;
    // console.log('message is '+ message);
    this.appInsights?.trackTrace({ message }, { level, environment: this.localEnv, site: this.channel });
  }

  private async logEvent(level: string, data: any) {
    if (!this.isAppInsightsInit) {
      this.initAppInsights();
      // console.log('appinisights initialized from logEvent method');
    }
    this.appInsights?.trackEvent({ name: data[0] }, { properties: data[1] });
    //  console.log('Custom Event : '+eventMessage);
  }

  private async logException(level: string, ...data: any) {
    if (!this.isAppInsightsInit) {
      this.initAppInsights();
      // console.log('appinisights initialized from logException method');
    }
    const exceptionMessage = data;
    this.appInsights?.trackException({
      exception: new Error(exceptionMessage),
    });
    // console.log("Exception Message : from " + exceptionMessage);
  }

  debug(...data: any) {
    this.log('DEBUG', data);
  }

  info(...data: any) {
    this.log('INFO', data);
  }

  warn(...data: any) {
    this.log('WARN', data);
  }

  error(...data: any) {
    this.log('ERROR', data);
  }
  // for appinsights

  exception(...data: any) {
    this.logException('EXCEPTION', data);
  }

  event(...data: any) {
    this.logEvent('EVENT', data);
  }
}
