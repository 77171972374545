<div class="container position-relative bg-lightgray topBanner">
  <div class="section-inner-txt bg-lightgray">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>

      <div class="container bg-lightgray">
        <div class="grid-locked section-wrp">
          <div class="billboard-alternative-2">
            <div class="col-xs-12">
              <div class="box-overlay">
                <div class="ftci-card-opportunities">
                  <div [innerHtml]="bannerText?.value"></div>
                  <a [href]="opportunitieslink" class="button">{{ opportunitylable }}</a>
                </div>
              </div>
              <img alt="image" [src]="bannerimage" loading="lazy" />
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
