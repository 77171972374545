import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

export interface EnvConfig {
  accountsApiUrl?: string;
  accountsDomain?: string;
  apiPort?: number;
  apiScheme?: string;
  apiUrl?: string;
  auth?: string;
  bookApptBaseUrl?: string;
  cmsBaseUrl?: string;
  commentaryContentUrl?: string;
  cookieApiUrl?: string;

  defaultPds?: string;
  environment?: string;
  fundCommentaryKey?: string;
  hypoBaseUrl?: string;
  hypoSavedPresentationsUrl?: string;
  insightsUrl?: string;
  instrumentationKey?: string;
  literatureApiUrl?: string;
  literatureBaseApiUrl?: string;
  literatureDownloadBaseUrl?: string;
  literatureDownloadFinraUrl?: string;
  literatureDownloadUrl?: string;
  literatureDownloadZipUrl?: string;
  literatureImageUrl?: string;
  literatureOrderHistoryList?: string;
  profileDomain?: string;
  salesTeamUrl?: string;
  searchStatusUrl?: string;
  searchUrl?: string;
  singleHost?: boolean;
  spabaseurl?: string;

  ftSearchUrlProd?: string;
  ftSearchUrlStatusProd?: string;
  ftSearchUrlDev?: string;
  ftSearchUrlStage?: string;
  ftSearchUrlStatusDev?: string;
  ftSearchUrlError?: string;
  ftSearchApiUrl?: string;

  searchUrlENUS?: string;
  searchUrlPreENUS?: string;
  searchUrlENCA?: string;
  searchUrlPreENCA?: string;
  searchApiUrl?: string;
}

/**
 * Environmental Config loaded from config.json
 */
@Injectable({
  providedIn: 'root',
})
export class EnvConfigService {
  private configName: string = environment.config;
  private envConfig: EnvConfig;

  constructor(private http: HttpClient) {}

  public loadEnvConfig(): Promise<any> {
    return this.http
      .get<EnvConfig>('/assets/data/' + this.configName)
      .toPromise()
      .catch((e) => {
        // Quick fix for storybook. FIX ME
        return this.http
          .get<EnvConfig>('/assets/data/' + this.configName)
          .toPromise()
          .then((data) => {
            this.initialize(data);
          });
      })
      .then((data) => {
        if (data) {
          this.initialize(data);
        }
      });
  }

  async initConfig(): Promise<any> {
    return await this.loadEnvConfig();
  }

  private initialize(data: EnvConfig) {
    this.envConfig = data;
  }
  public getEnvConfig() {
    return this.envConfig;
  }
}
