<div class="container position-relative bg-lightgray topBanner">
  <div class="section-inner-txt bg-lightgray">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
    </div>
    <div *ngIf="document">
      <div class="section">
        <div role="title" data-fti-component="ftci-grid" data-fti-module="ftci-contact-grid-overlay">
          <div class="grid">
            <div>
              <div class="grid-locked">
                <div class="table-container">
                  <table class="contact-h5">
                    <tbody *ngFor="let gridcontent of content">
                      <tr>
                        <td colspan="3">
                          <h5>
                            <strong>{{ gridcontent.titleBlock.title }}</strong>
                          </h5>
                        </td>
                      </tr>
                      <tr valign="top">
                        <span *ngFor="let grid of gridcontent.content">
                          <td [innerHtml]="grid.value"></td>
                        </span>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
