<div class="container position-relative twoColContent bg-lightgray">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-fiduciary-document"
        folderTemplateQuery="new-fiduciary-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div *ngIf="document">
        <div class="grid section-wrp">
          <div *ngFor="let content of twoColContent" class="row">
            <ng-container *ngIf="content?.sectionId != 'Careers'">
              <div class="col-md-4 col-sm-4 left-content not-careers">
                <div *ngFor="let item of content?.items">
                  <h2 *ngIf="item.type == 'TitleBlock'">{{ item.title }}</h2>
                  <div *ngIf="item.type == 'ParagraphBlock'" [innerHTML]="item.content"></div>
                  <ng-container *ngIf="isRadnor">
                    <div [innerHTML]="item.paragraphBlock.content"></div>
                    <ng-container *ngFor="let img of item.imageGridCompound.imageGrid">
                      <img [src]="getImg(img.imageUrl)" width="115" />
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-8 col-sm-8 right-content not-careers">
                <div *ngFor="let item of content?.itemsTwo">
                  <h3 *ngIf="item.type == 'TitleBlock' && item.title">{{ item.title }}</h3>
                  <div *ngIf="item.type == 'ParagraphBlock'" [innerHTML]="item.content"></div>
                  <img [src]="getImg(item)"/>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="content?.sectionId == 'Careers'">
              <h2 class="careers" *ngIf="content?.items[0].type == 'TitleBlock'">{{ content?.items[0].title }}</h2>

              <div class="col-sm-6 col-xs-12 left-content careers">
                <ng-container *ngFor="let item of content?.items">
                  <div *ngIf="item.type == 'ParagraphBlock'" [innerHTML]="item.content"></div>
                </ng-container>
              </div>
              <div class="col-sm-6 col-xs-12 right-content careers">
                <ng-container *ngFor="let item of content?.itemsTwo">
                  <h3 *ngIf="item.title">{{ item.title }}</h3>
                  <div *ngIf="item.type == 'ParagraphBlock'" [innerHTML]="item.content"></div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
