import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Constant } from '../../ft-constants/constant';
import { Location } from '@angular/common';

@Component({
  selector: 'ft-article-grid-view',
  templateUrl: './article-grid-view.component.html',
  styleUrls: ['./article-grid-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleGridViewComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  constructor() {}

  ngOnInit(): void {}
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    return this.document?.getData();
  }
  get gridList() {
    return this.content.gridViewCompound;
  }
  getImg(elem) {
    let imageUrl = '';
    if (elem.widenDocument && elem.widenDocument !== '') {
      imageUrl = JSON.parse(elem.widenDocument).url ? JSON.parse(elem.widenDocument).url : '';
    }
    return imageUrl;
  }
}
