import { LoggerOutput } from './logger-output';

const output = console;
export class ConsoleLoggerOutput implements LoggerOutput {
  debug(...data: any) {
    output.debug(...data);
  }
  info(...data: any) {
    output.info(...data);
  }
  warn(...data: any) {
    output.warn(...data);
  }
  error(...data: any) {
    output.error(...data);
  }
  exception(...data: any) {
    output.error(...data);
  }
  event(...data: any) {
    output.info(...data);
  }
}
